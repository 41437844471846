import { DEFAULT_SELECTED_DELIVERY_ZONES } from '_utils/constant'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { FaTrashAlt } from 'react-icons/fa'

const DeliveryZoneRow = ({ index, zone, onDelete, register, errors }) => (
  <div className='row mb-2'>
    <div className='col-7'>
      <div className='d-flex flex-column align-items-start'>
        <div className='d-flex align-items-end w-100'>
          <label className='form-label me-1'>{`${index + 1}.`}</label>
          <input
            type='text'
            className={`form-control ${
              errors?.selectedDeliveryZones?.[index]?.optionName ? 'is-invalid' : ''
            }`}
            defaultValue={zone.optionName}
            {...register(`selectedDeliveryZones.${index}.optionName`)}
          />
        </div>
        {errors?.selectedDeliveryZones?.[index]?.optionName && (
          <div className='invalid-feedback w-100 d-block ps-3'>
            {errors.selectedDeliveryZones[index].optionName.message}
          </div>
        )}
      </div>
    </div>
    <div className='col-5'>
      <div className='d-flex flex-column align-items-start'>
        <div className='d-flex align-items-center w-100'>
          <span>$</span>
          <input
            type='text'
            className={`form-control ms-2 ${
              errors?.selectedDeliveryZones?.[index]?.deliveryFee ? 'is-invalid' : ''
            }`}
            defaultValue={zone.deliveryFee}
            {...register(`selectedDeliveryZones.${index}.deliveryFee`)}
          />
          <button
            className='btn btn-link text-danger shadow-none p-0 w-auto ms-3'
            onClick={() => onDelete(zone.id)}
          >
            <FaTrashAlt size={20} />
          </button>
        </div>
        {errors?.selectedDeliveryZones?.[index]?.deliveryFee && (
          <div className='invalid-feedback w-100 d-block ps-3'>
            {errors.selectedDeliveryZones[index].deliveryFee.message}
          </div>
        )}
      </div>
    </div>
  </div>
)

const DeliveryFee = () => {
  const { control, reset, register, errors } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'selectedDeliveryZones',
  })

  const onAddZone = () => {
    append({ optionName: '', deliveryFee: 0 })
  }

  const onDeleteZone = (id) => {
    if (fields.length === 1) {
      reset({ selectedDeliveryZones: DEFAULT_SELECTED_DELIVERY_ZONES })
    } else {
      const index = fields.findIndex((zone) => zone.id === id)
      remove(index)
    }
  }

  return (
    <div className='mb-3'>
      <label className='form-label'>Edit delivery:</label>
      <div className='delivery-fee-wrapper p-3 rounded'>
        {fields.length > 0 ? (
          <>
            {fields.map((zone, index) => (
              <DeliveryZoneRow
                key={zone.id}
                index={index}
                zone={zone}
                onDelete={onDeleteZone}
                register={register}
                errors={errors}
              />
            ))}
          </>
        ) : null}
        <button
          className='btn btn-link shadow-none w-auto rounded-0 p-0 text-primary'
          onClick={onAddZone}
        >
          Add more
        </button>
      </div>
    </div>
  )
}

export default DeliveryFee
