/* eslint-disable no-prototype-builtins */
import orderEventApi from '_api/orderEvent'
import NotFoundDataComponent from '_components/NotFoundDataComponent'
import TableHeaderWithSort from '_components/TableHeaderWithSort'
import { OrderRecordContext } from '_contexts/OrderRecordContext'
import { getEventInShop } from '_redux/modules/event'
import {
  getEventAndOrder,
  getListCombinedPayment,
  updateOrderCombinedPayment,
  updateOrderEventStatus,
} from '_redux/modules/orderEvent'
import {
  BUYER_ORDER_PAID,
  BUYER_ORDER_REFUNDED,
  BUYER_ORDER_UNPAID,
  HOST_ORDER_CANCELED,
  HOST_ORDER_UNPAID,
} from '_utils/constant'
import { handleDataOrderEvent } from '_utils/functions/handler'
import { isEmpty } from 'lodash'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { headers } from '../headerTable'
import ModalDetailOrder from './ModalDetailOrder'
import ModalEventPaymentScreen from './ModalEventPaymentScreen'
import ModalOrderComment from './ModalOrderComment'
import ModalWarning from './ModalWarning'
import OrderEventRecordItem from './OrderEventRecordItem'
import './style.scss'

export default function ListOrderEventRecord() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { setSorting, listOrdersEventFiltered } = useContext(OrderRecordContext)
  const { selectedShop } = useSelector((state) => state.shop)
  const { listCombinedPayments, eventAndOrderById } = useSelector((state) => state.orderEvent)

  const [dataModalPaymentScreen, setDataModalPaymentScreen] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(null)
  const [isOpenModalPaymentScreen, setIsOpenModalPaymentScreen] = useState(false)
  const [openOrderComment, setOpenOrderComment] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [dataModal, setDataModal] = useState({})
  const [dataModalDetailOrder, setDataModalDetailOrder] = useState({})
  const [modalDetailOrder, setModalDetailOrder] = useState(false)
  const [modalWarning, setModalWarning] = useState(false)

  const eidParam = new URLSearchParams(window.location.search).get('eid')

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      setIsLoading(true)

      try {
        await Promise.all([
          dispatch(getEventInShop(selectedShop?.id)),
          dispatch(getEventAndOrder(eidParam)),
        ])
      } finally {
        if (isMounted) {
          setIsLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [selectedShop?.id, eidParam])

  const toggleDetailOrder = () => setModalDetailOrder(!modalDetailOrder)
  const toggleWarning = () => setModalWarning(!modalWarning)

  const handleOpenWarning = (e, order) => {
    e.preventDefault()
    setDataModal(order)
    toggleWarning()
  }

  const initListCombinedPayment = async (_data) => {
    try {
      const listCombinedPaymentRef = [
        ...new Set(_data.map(({ combinedPaymentRef = '' }) => combinedPaymentRef).filter(Boolean)),
      ]

      if (listCombinedPaymentRef.length) {
        await dispatch(getListCombinedPayment(listCombinedPaymentRef))
      } else {
        dispatch({
          type: 'SET_LOADING_LIST_COMBINED_PAYMENT',
          payload: { loadingListCombinedPayment: false },
        })
      }
    } catch (error) {
      addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (
      listOrdersEventFiltered &&
      Array.isArray(listOrdersEventFiltered) &&
      listOrdersEventFiltered.length
    ) {
      initListCombinedPayment(listOrdersEventFiltered)
    } else {
      dispatch({
        type: 'SET_LOADING_LIST_COMBINED_PAYMENT',
        payload: { loadingListCombinedPayment: false },
      })
    }
  }, [JSON.stringify(listOrdersEventFiltered)])

  const toggleTooltip = (itemRef) => setTooltipOpen(tooltipOpen === itemRef ? null : itemRef)

  const toggleModalPaymentScreen = () => setIsOpenModalPaymentScreen(!isOpenModalPaymentScreen)

  const toggleOpenModalComment = () => setOpenOrderComment(!openOrderComment)

  const handleUpdateStatusCombinedPayment = async (combinedPaymentRef, postStatus) => {
    try {
      dispatch(
        updateOrderCombinedPayment(combinedPaymentRef, postStatus.status, postStatus.buyerStatus)
      )
      await orderEventApi.updateInvoicesStatus({ ...postStatus, combinedPaymentRef })
      addToast('Update successfully!', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleUpdateStatusPayment = async (ref, postStatus, eid) => {
    try {
      dispatch(updateOrderEventStatus(eid, ref, postStatus.status, postStatus.buyerStatus))
      await orderEventApi.updateOrderEventStatus(ref, postStatus)
      addToast('Update successfully!', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleInvalidPayment = async ({ ref, status, combinedPaymentRef, eid }) => {
    const r = confirm('Are you sure this is invalid payment?')
    if (r === true) {
      try {
        const postStatus = {
          status,
          buyerStatus: BUYER_ORDER_UNPAID,
        }

        if (!combinedPaymentRef) {
          postStatus.status = HOST_ORDER_UNPAID
          await handleUpdateStatusPayment(ref, postStatus, eid)
        } else {
          await handleUpdateStatusCombinedPayment(combinedPaymentRef, postStatus)
        }
      } catch (error) {
        addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
      } finally {
        toggleModalPaymentScreen()
      }
    }
  }

  const openPaymentScreen = (e, order) => {
    e.preventDefault()

    const { combinedPaymentRef = '' } = order
    let listCombinedOrder = []
    let listCombinedDelivery = []

    if (combinedPaymentRef && eventAndOrderById.length && listCombinedPayments.length) {
      const exitCombinedPayment = listCombinedPayments.findIndex(
        (cpItem) => cpItem.combinedPaymentRef === combinedPaymentRef
      )

      if (exitCombinedPayment !== -1) {
        const { orderCombinedPayment, deliveryCombinedPayment } =
          listCombinedPayments[exitCombinedPayment]

        orderCombinedPayment
          // exclude current order
          .forEach((element) => {
            const { eid, ref: elementRef } = element
            const { orders, adminCost, discount, deliveryCost, productIdList } =
              eventAndOrderById.find((eoItem) => eoItem.id === eid) || {}
            const { listOrderEventHandled } = handleDataOrderEvent(
              orders,
              adminCost,
              discount,
              deliveryCost,
              productIdList
            )
            const otherOrder = listOrderEventHandled.find((ooItem) => ooItem.ref === elementRef)
            listCombinedOrder = [...listCombinedOrder, otherOrder]
          })
        listCombinedDelivery = deliveryCombinedPayment ? [...deliveryCombinedPayment] : []
      }
    }

    setDataModalPaymentScreen({ ...order, listCombinedOrder, listCombinedDelivery })
    toggleModalPaymentScreen()
  }

  const onChangeStatusSelect = async (e, ref, _combinedPaymentRef, eid) => {
    const { value } = e.target
    const statusSelected = {
      status: Number(value),
      buyerStatus: Number(value),
    }

    // Number(value) === HOST_ORDER_CANCELED &&
    if (_combinedPaymentRef) {
      await handleUpdateStatusCombinedPayment(_combinedPaymentRef, statusSelected)
      return
    }

    await handleUpdateStatusPayment(ref, statusSelected, eid)
  }

  const handleCancelOrder = async (e, ref, _status, _buyerStatus, _combinedPaymentRef) => {
    e.preventDefault()
    try {
      if (_status !== HOST_ORDER_CANCELED && _buyerStatus > BUYER_ORDER_PAID) {
        if (_combinedPaymentRef) {
          const _data = {
            status: HOST_ORDER_CANCELED,
            placerStatus: BUYER_ORDER_REFUNDED,
          }
          await handleUpdateStatusCombinedPayment(_combinedPaymentRef, _data)

          toggleDetailOrder()
          toggleWarning()
        } else {
          const _data = { status: HOST_ORDER_CANCELED, buyerStatus: BUYER_ORDER_REFUNDED }
          await handleUpdateStatusPayment(ref, _data, eidParam)

          toggleDetailOrder()
          toggleWarning()
        }
      } else {
        const _data = { status: HOST_ORDER_CANCELED, _buyerStatus }
        await handleUpdateStatusPayment(ref, _data, eidParam)

        toggleDetailOrder()
        toggleWarning()
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      toggleDetailOrder()
      toggleWarning()
    }
  }

  const handleShowDetail = (e, order) => {
    e.preventDefault()
    setDataModalDetailOrder(order)
    toggleDetailOrder()
  }

  const onOpenComment = (order) => {
    setSelectedOrder(order)
    toggleOpenModalComment()
  }

  if (isLoading) {
    return <div className='text-center text-info fw-bold f-16 my-5'>Loading...</div>
  }

  if (!isLoading && !listOrdersEventFiltered.length) {
    return <NotFoundDataComponent text='No Orders Found' />
  }

  return (
    <div className='event-list-order'>
      {!isEmpty(dataModalPaymentScreen) && (
        <>
          <ModalEventPaymentScreen
            data={dataModalPaymentScreen}
            modalPaymentScreen={isOpenModalPaymentScreen}
            togglePaymentScreen={toggleModalPaymentScreen}
            handleInvalidPayment={handleInvalidPayment}
          />
        </>
      )}
      {!isLoading && (
        <TableHeaderWithSort
          headers={headers}
          defaultSortingField='shopOrderNo'
          defaultSortingOrder='asc'
          onSorting={(field, order, type) => setSorting({ field, order, type })}
          className='row p-3 bg-light table-header fw-bold cursor-pointer'
          style={{ fontSize: '0.9vw', color: '#079d9a' }}
        />
      )}
      {!isLoading &&
        listOrdersEventFiltered.map((item, index) => (
          <Fragment key={`${item.id}-${index}`}>
            <OrderEventRecordItem
              item={item}
              index={index}
              tooltipOpen={tooltipOpen}
              toggleTooltip={toggleTooltip}
              toggleModalPaymentScreen={toggleModalPaymentScreen}
              setDataModalPaymentScreen={setDataModalPaymentScreen}
              setSelectedOrder={setSelectedOrder}
              toggleOpenModalComment={toggleOpenModalComment}
              openPaymentScreen={openPaymentScreen}
              onChangeStatusSelect={onChangeStatusSelect}
              onOpenComment={onOpenComment}
              handleShowDetail={handleShowDetail}
            />
          </Fragment>
        ))}

      <ModalOrderComment
        openOrderComment={openOrderComment}
        toggleOpenModalComment={toggleOpenModalComment}
        selectedOrder={selectedOrder}
      />

      <ModalWarning
        data={dataModal}
        modalWarning={modalWarning}
        toggleWarning={toggleWarning}
        handleCancel={handleCancelOrder}
      />

      <ModalDetailOrder
        id={eidParam}
        order={dataModalDetailOrder}
        modalDetailOrder={modalDetailOrder}
        toggleDetailOrder={toggleDetailOrder}
        handleOpenWarning={handleOpenWarning}
        toggleWarning={toggleWarning}
      />
    </div>
  )
}
