import OverlayPrompt from '_components/OverlayPrompt'
import { ManageEventProvider } from '_contexts/EventContext'
import { useEvent } from '_hooks/useEvent'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import CreateEvent from './Create'
import EventList from './components/EventList'
import './style.scss'

const styleTitleOverlay = { marginTop: 100, color: '#5b5b5b', fontWeight: 500, fontSize: 24 }

export default function Events() {
  const { path } = useRouteMatch()

  const { selectedShop } = useSelector((state) => state.shop)

  const {
    view,
    handleViewChange,
    currentEvents,
    currentPage,
    setCurrentPage,
    listEventByShopFiltered,
    openBroadcastModal,
    setOpenBroadcastModal,
    message,
    onChangeMessage,
    onCreateNotification,
    search,
    setSearch,
    toggleBroadcastModal,
    PageSize,
    currentPackagePro,
    onReopenEvent,
    loading,
    listEventByShop,
    onDirectCreateEvent,
    onDeleteEvent,
    listOrderEventHandled,
  } = useEvent()

  return (
    <ManageEventProvider
      value={{
        view,
        handleViewChange,
        currentEvents,
        currentPage,
        setCurrentPage,
        listEventByShopFiltered,
        openBroadcastModal,
        setOpenBroadcastModal,
        message,
        onChangeMessage,
        onCreateNotification,
        search,
        setSearch,
        toggleBroadcastModal,
        PageSize,
        currentPackagePro,
        onReopenEvent,
        loading,
        listEventByShop,
        onDirectCreateEvent,
        onDeleteEvent,
        listOrderEventHandled,
      }}
    >
      <div className='page-events px-3 f-16 h-100 position-relative'>
        <p className='text-uppercase text-orange fw-bold f-24'>Events</p>

        {currentPackagePro && (
          <div className='alert alert-warning' role='alert'>
            <p className='mb-0'>
              <b>Note:</b> Features below will not apply for event
            </p>
            <ul className='ps-3 mb-0'>
              <li>Gift recipient</li>
              <li>Privacy mode</li>
              <li>Cash on Delivery/Pickup</li>
              <li>Add transaction fees</li>
            </ul>
          </div>
        )}

        <div
          className={`d-flex align-items-center ${
            currentPackagePro ? '' : 'opacity-50 pointer-events-none'
          }`}
        >
          <button
            type='button'
            onClick={onDirectCreateEvent}
            className='bg-transparent border-0 text-decoration-underline text-orange p-0'
          >
            Create New Events
          </button>
          <span className='text-warning ms-2'>
            * You need to create a group before creating an event
          </span>
        </div>

        {loading && currentPackagePro && <div className='loader' style={{ marginTop: 50 }}></div>}

        {!currentPackagePro && <OverlayPrompt shopSlug={selectedShop?.shopSlug} />}

        {!loading && currentPackagePro && !listEventByShop.length ? (
          <div className='text-secondary h-100 d-flex flex-column align-items-center'>
            <p style={styleTitleOverlay}>There is currently no events organized</p>
            <small>
              Events are for the purpose of group-buy in Co-bee which should provide savings for
              members in Co-bee.
            </small>
          </div>
        ) : null}

        {!loading && <EventList />}

        <Switch>
          <Route exact path={`${path}/create`}>
            <CreateEvent />
          </Route>
        </Switch>
      </div>
    </ManageEventProvider>
  )
}
