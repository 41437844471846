/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { GoArrowLeft } from 'react-icons/go'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Form } from 'reactstrap'
import * as XLSX from 'xlsx'
import * as Yup from 'yup'

import eventApi from '_api/event'
import serviceApi from '_api/service'
import uploadApi from '_api/upload'
import useQuery from '_hooks/useQuery'
import { DEFAULT_PRODUCT_EVENT, LIST_TIME, PAYMENT_SERVICE_SCHEDULE_PRICE } from '_utils/constant'
import { readFileToCreateEvent } from '_utils/function'
import { convertToCurrentGMT } from '_utils/functions/converter'
import {
  getCreateEventData,
  getUserInfo,
  removeCreateEventData,
  setCreateEventData,
} from '_utils/localData'
// import ModalPaymentService from '_components/ModalPaymentService'
import LoadingOverlay from '_components/LoadingOverlay'
import ModalGeneralPayment from '_components/ModalGeneralPayment'
import { getListDeliveryEventByHost } from '_redux/modules/deliveryEvent'
import Swal from 'sweetalert2'
import DatePicker from '../components/DatePicker'
import EventDescription from '../components/EventDescription'
import EventPickupLocation from '../components/EventPickupLocation'
import EventTitle from '../components/EventTitle'
import PaymentOption from '../components/PaymentOption'

import './style.scss'

const validationSchema = Yup.object().shape({
  title: Yup.string('Invalid title').required('Invalid title').max(255, 'Max length is 255'),
  description: Yup.string('Invalid description').nullable().default(''),
  pickupAddress: Yup.string('Invalid pickup address').nullable().default(''),
  adminCost: Yup.number().default(0),
  deliveryOption: Yup.object().shape({
    deliveryZones: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        price: Yup.number(),
      })
    ),
    deliveryTime: Yup.string(),
    deliveryHourStart: Yup.string(),
    deliveryHourEnd: Yup.string(),
  }),
  addDelivery: Yup.bool(),
})

const CreateEvent = () => {
  const location = useLocation()
  const history = useHistory()
  const params = useQuery()
  const dispatch = useDispatch()

  const userInfo = getUserInfo() || null
  const { id: userId } = userInfo

  const { addToast } = useToasts()
  const { selectedShop } = useSelector((state) => state.shop)
  const { listDeliveryEventByHost } = useSelector((state) => state.deliveryEvent)
  const { currentPackage } = useSelector((state) => state.subscription)

  let { listDeliveryCreated = null } =
    listDeliveryEventByHost.find((item) => item.hostId === userId) || {}

  listDeliveryCreated = listDeliveryCreated
    ? listDeliveryCreated.map((item) => ({
        ...item,
        deliveryTime: convertToCurrentGMT(item.deliveryTime),
      }))
    : null

  const fileInputRef = useRef()

  const [eventData, setEventData] = useState(getCreateEventData())

  const eid = params.get('id') || null

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
    watch,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: eventData || {},
  })

  const [hiddenNote, setHiddenNote] = useState(!!getValues()?.termConds?.length)
  const [hiddenExternalURL, setHiddenExternalURL] = useState(!!eventData?.extraFields?.[0])
  const toggleExternalURL = () => setHiddenExternalURL(!hiddenExternalURL)
  const [hiddenDelivery, setHiddenDelivery] = useState(eventData?.addDelivery)
  const [paynow, setPaynow] = useState(() => {
    if (eventData?.paynow === 0 || eventData?.paynow === true) return true
    return false
  })
  const [payTogether, setPayTogether] = useState(() => {
    if (eventData?.payTogether === 0 || eventData?.payTogether === false) return false
    return true
  })
  const [closingDate, setClosingDate] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  )
  const [collectionDate, setCollectionDate] = useState(
    new Date(new Date().getTime() + 24 * 7 * 60 * 60 * 1000)
  )
  const [collectionTimeStart, setCollectionTimeStart] = useState('10AM')
  const [collectionTimeEnd, setCollectionTimeEnd] = useState('5PM')
  const [isLoading, setIsLoading] = useState(false)
  const [externalUrl, setExternalUrl] = useState(eventData?.extraFields?.[0] || null)
  const [isShowModal, setIsShowModal] = useState(false)
  const [paymentFileUpload, setPaymentFileUpload] = useState('')
  const [paymentFileUrl, setPaymentFileUrl] = useState('')
  const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)
  // const [registerScheduleEvent, setRegisterScheduleEvent] = useState(false)
  const idParam = params.get('id')
  const idReopen = localStorage.getItem('EVENT_ID_REOPEN')

  const toggle = () => {
    setIsShowModal(!isShowModal)
  }

  const onSubmit = (data) => {
    delete data.collectionDate
    delete data.collectionTimeEnd
    delete data.collectionTimeStart

    if (data?.termConds?.length === 0) {
      data.termConds = []
    }
    const collectionStartIndex = LIST_TIME.findIndex((item) => item.text === collectionTimeStart)
    const collectionEndIndex = LIST_TIME.findIndex((item) => item.text === collectionTimeEnd)

    const { deliveryOption, addDelivery } = data

    const { deliveryTime = '', deliveryHourStart = '', deliveryHourEnd = '' } = deliveryOption

    let postData = {
      photoUrls: [],
      ...data,
      closingTime: `${moment(closingDate).format('DD-MM-YYYY')} 23:59`,
      pickupTime: `${moment(collectionDate).format('DD-MM-YYYY')} ${
        LIST_TIME[collectionStartIndex].value
      }:00`,
      pickupDuration:
        (LIST_TIME[collectionEndIndex]?.value - LIST_TIME[collectionStartIndex]?.value) * 3600,
      extraFields: externalUrl ? [externalUrl] : [],
      paynow,
      payable: paynow,
      payTogether,
    }

    if (addDelivery) {
      const deliveryStartIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourStart)
      const deliveryEndIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourEnd)
      const timeStart = LIST_TIME[deliveryStartIndex].value
      const timeEnd = LIST_TIME[deliveryEndIndex].value
      const timeStartString = timeStart >= 10 ? timeStart : `0${timeStart}`
      const deliveryDuration = (timeEnd - timeStart) * 3600
      if (deliveryDuration <= 0) {
        addToast('Invalid delivery time duration', { appearance: 'error', autoDismiss: true })
        return
      }
      postData = {
        ...postData,
        deliveryTime: `${deliveryTime} ${timeStartString}:00`,
        deliveryDuration,
      }
    }

    const closingTimestamp = moment(postData.closingTime, 'DD-MM-YYYY HH:mm').unix()
    const pickupTimestamp = moment(postData.pickupTime, 'DD-MM-YYYY HH:mm').unix()
    const now = moment().unix()

    if (pickupTimestamp < now) {
      addToast('Invalid collection date', { appearance: 'error', autoDismiss: true })
      return
    }

    if (LIST_TIME[collectionStartIndex].value >= LIST_TIME[collectionEndIndex].value) {
      addToast('Invalid collection time', { appearance: 'error', autoDismiss: true })
      return
    }

    if (addDelivery) {
      const deliveryTimestamp = moment(postData.deliveryTime, 'DD-MM-YYYY HH:mm').unix()
      if (deliveryTimestamp <= closingTimestamp) {
        addToast('Delivery date must after closing date', {
          appearance: 'error',
          autoDismiss: true,
        })
        return
      }
      if (listDeliveryCreated && listDeliveryCreated.length) {
        const exitDeliveryTime = listDeliveryCreated.findIndex(
          (item) => item.deliveryTime === postData.deliveryTime
        )

        if (exitDeliveryTime !== -1) {
          addToast('Delivery exited', { appearance: 'error', autoDismiss: true })
          return
        }
      }
    }

    if (postData.id) {
      setCreateEventData(postData)
      history.push(`/admin-console/${selectedShop?.shopSlug}/event/update-items?id=${postData.id}`)
    } else {
      if (closingTimestamp < now) {
        addToast('Invalid closing date', { appearance: 'error', autoDismiss: true })
        return
      }
      if (pickupTimestamp < closingTimestamp) {
        addToast('Invalid collection date', { appearance: 'error', autoDismiss: true })
        return
      }
      let products = postData?.products
      if (products?.length === 0 || !products) {
        products = [{ ...DEFAULT_PRODUCT_EVENT }]
      }
      setCreateEventData({ ...postData, products })

      if (idParam) {
        history.push(`/admin-console/${selectedShop?.shopSlug}/event/update-items?id=${idParam}`)
      } else {
        history.push(`/admin-console/${selectedShop?.shopSlug}/event/create-items`)
      }
    }
  }

  const onClearData = () => {
    removeCreateEventData()
    reset()
    setPaynow(false)
    setClosingDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
    setCollectionDate(new Date(new Date().getTime() + 24 * 7 * 60 * 60 * 1000))
    setExternalUrl(null)
  }

  const onCancel = () => {
    const id = params.get('id')

    if (id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You are editing an event, do you want to discard the data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          onClearData()
          history.push(`/admin-console/${selectedShop?.shopSlug}/event`)
        }
      })
    } else {
      onClearData()
      setExternalUrl(null)
    }
  }

  const onChangeClosingDate = (date) => {
    const subscriptionExpireDate = moment
      .unix(currentPackage?.expireTimestamp, 'DD-MM-YYYY')
      .toDate()

    if (moment(date).isSameOrAfter(subscriptionExpireDate, 'day')) {
      addToast('Closing date must be before subscription expire date', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    setClosingDate(date)
  }

  const setValues = (objData) => {
    const keys = Object.keys(objData)
    keys.map((key) => setValue(key, objData[key]))
  }

  const onFileChanged = () => {
    const file = fileInputRef.current.files[0]
    const reader = new FileReader()

    reader.onload = (e) => {
      const data = e.target.result
      const workbook = XLSX.read(data, { type: 'binary' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]

      if (!_.isEmpty(sheet)) {
        try {
          const newEvent = readFileToCreateEvent(sheet)
          const closingTimestamp = moment(newEvent.closingTime, 'DD-MM-YYYY HH:mm').unix()
          const pickupTimestamp = moment(newEvent.pickupTime, 'DD-MM-YYYY HH:mm').unix()
          let { closingTime } = newEvent
          let { pickupTime } = newEvent
          const now = moment().unix()
          const nowCheck = `${moment(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)).format(
            'DD-MM-YYYY'
          )} 23:59`

          if (pickupTimestamp < now) {
            pickupTime = `${nowCheck}`
          }
          if (closingTimestamp > pickupTimestamp || closingTimestamp < now) {
            closingTime = `${nowCheck}`
            pickupTime = moment(closingTime, 'DD-MM-YYYY HH:mm')
              .add(1, 'days')
              .format('DD-MM-YYYY HH:mm')
          } else {
            pickupTime = moment(closingTime, 'DD-MM-YYYY HH:mm')
              .add(1, 'days')
              .format('DD-MM-YYYY HH:mm')
          }

          setCreateEventData({ ...newEvent, closingTime, pickupTime })
          setEventData({ ...newEvent, closingTime, pickupTime })
        } catch (error) {
          addToast('Please check the file', { appearance: 'error', autoDismiss: true })
        }
      }
    }

    reader.onerror = (e) => {
      addToast(`Failed to read file ${e}`, { appearance: 'error', autoDismiss: true })
    }

    reader.readAsBinaryString(file)
  }

  useEffect(() => {
    // setEventData(getCreateEventData())
    const getListDeliveryByHost = async () => {
      try {
        await dispatch(getListDeliveryEventByHost({ hostId: userId }))
      } catch (error) {
        addToast(error?.message, { appearance: 'error', autoDismiss: true })
      }
    }

    if (selectedShop?.id) {
      const getEventDetail = async (_eid) => {
        setIsLoading(true)
        try {
          const { msgResp: event } = await eventApi.getEventById(_eid)
          if (event) {
            const ePickupTimeCurrentGMT = convertToCurrentGMT(event.pickupTime)
            for (let i = 0; i < event.products.length; i++) {
              event.products[i].pid = event.products[i].id
            }
            setEventData({ ...event, pickupTime: ePickupTimeCurrentGMT, shopId: selectedShop.id })
            setCreateEventData({
              ...event,
              pickupTime: ePickupTimeCurrentGMT,
              shopId: selectedShop.id,
            })
          }
        } catch (error) {
          addToast(error.message || error?.msgResp, { appearance: 'error', autoDismiss: true })
        } finally {
          setIsLoading(false)
        }
      }

      if ((idParam || idReopen) && (!eventData || Object.entries(eventData).length === 0)) {
        getEventDetail(idParam || idReopen)
      } else {
        getListDeliveryByHost()
      }

      if (!idParam) {
        history.push(`/admin-console/${selectedShop?.shopSlug}/event/create`)
      }

      setEventData({ ...eventData, shopId: selectedShop.id })
    }
  }, [selectedShop?.id, idParam, idReopen])

  // useEffect(() => {
  //   setRegisterScheduleEvent(false)
  //   if (userInfo) {
  //     const { scheduleFeatures = [] } = userInfo
  //     if (scheduleFeatures.length) {
  //       const scheduleFeaturePaid = scheduleFeatures.filter(
  //         (item) =>
  //           item.schedulePaymentStatus >= PAYMENT_STATUS_PAID &&
  //           item.scheduleStatus >= STATUS_PENDING
  //       )
  //       if (scheduleFeaturePaid.length) {
  //         setRegisterScheduleEvent(false)
  //       } else {
  //         setRegisterScheduleEvent(true)
  //       }
  //     } else {
  //       setRegisterScheduleEvent(true)
  //     }
  //   }
  // }, [userInfo])

  useEffect(() => {
    if (eventData) {
      setValues(eventData)
      if (eventData?.closingTime && eventData?.pickupTime) {
        const today = new Date()
        const [closingYear, closingMonth, closingDay] = eventData.closingTime
          .split(' ')[0]
          .split('-')
          .reverse()
        const [pickupYear, pickupMonth, pickupDay] = eventData.pickupTime
          .split(' ')[0]
          .split('-')
          .reverse()

        const getClosingDate = new Date(closingYear, closingMonth - 1, closingDay)
        const getCollectionDate = new Date(pickupYear, pickupMonth - 1, pickupDay)

        const closingDateCalculated = getClosingDate < today ? today : getClosingDate
        const collectionDateCalculated = getCollectionDate < today ? today : getCollectionDate

        setCollectionDate(collectionDateCalculated)
        setClosingDate(closingDateCalculated)

        setCreateEventData({
          ...eventData,
          collectionDate: collectionDateCalculated,
          closingDate: closingDateCalculated,
          closingTime: moment(closingDateCalculated).format('DD-MM-YYYY HH:mm'),
          pickupTime: moment(collectionDateCalculated).format('DD-MM-YYYY HH:mm'),
        })
      }
      if (eventData?.pickupTime && eventData?.pickupDuration) {
        const { pickupTime } = eventData
        const pickupHour = Number(pickupTime.split(' ')[1].split(':')[0])
        const pickupDuration = eventData?.pickupDuration || 0

        const getIndex = (hour) => LIST_TIME.findIndex((item) => item.id === hour)

        const indexStart = getIndex(pickupHour)
        const indexEnd = getIndex((pickupHour + pickupDuration / 3600) % 24)
        setCollectionTimeStart(LIST_TIME[indexStart]?.text)
        setCollectionTimeEnd(LIST_TIME[indexEnd]?.text)
      }
    }
  }, [eventData])

  useEffect(() => {
    setHiddenNote(!!getValues()?.termConds?.length)
    setHiddenDelivery(!!getValues()?.addDelivery)
  }, [getValues()?.termConds, getValues()?.addDelivery])

  useEffect(() => {
    setValue('collectionDate', collectionDate)
    setValue('collectionTimeStart', collectionTimeStart)
    setValue('collectionTimeEnd', collectionTimeEnd)
  }, [collectionDate, collectionTimeStart, collectionTimeEnd])

  const onUserImageUploaded = (_event) => {
    const { files } = _event.target
    if (files) {
      const img = files[0]
      setPaymentFileUrl(URL.createObjectURL(img))
      setPaymentFileUpload(files)
    }
  }

  const confirmPayment = async (e) => {
    e.preventDefault()
    setIsSubmittedPayment(true)
    let url = paymentFileUrl
    if (paymentFileUpload) {
      try {
        const { msgResp: res } = await uploadApi.uploadPhoto(paymentFileUpload)
        // eslint-disable-next-line prefer-destructuring
        url = res.url
      } catch (error) {
        addToast(error.message || error?.msgResp, { appearance: 'error', autoDismiss: true })
      }
    }
    if (url) {
      try {
        await serviceApi.purchasePaymentScheduleEvent(userId, {
          schedulePaymentScreenshot: url,
          schedulePrice: PAYMENT_SERVICE_SCHEDULE_PRICE,
        })

        // userApi
        //   .getUser()
        //   .then(({ msgResp }) => {
        //     const { scheduleFeatures = [] } = msgResp
        //     if (scheduleFeatures.length) {
        //       const scheduleFeaturePaid = scheduleFeatures.filter(
        //         (item) =>
        //           item.schedulePaymentStatus >= PAYMENT_STATUS_PAID &&
        //           item.scheduleStatus >= STATUS_PENDING
        //       )
        //       if (scheduleFeaturePaid.length) {
        //         setRegisterScheduleEvent(false)
        //       } else {
        //         setRegisterScheduleEvent(true)
        //       }
        //     } else {
        //       setRegisterScheduleEvent(true)
        //     }
        //     setUserInfo(msgResp)
        //   })
        //   .catch(({ msgResp }) => {
        //     addToast(msgResp, { appearance: 'error', autoDismiss: true })
        //   })

        setIsSubmittedPayment(false)
        toggle()
      } catch (error) {
        setIsSubmittedPayment(false)
        addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
      }
    } else {
      setIsSubmittedPayment(false)
      alert('Please upload payment screenshot')
    }
  }

  const showAlertAndNavigate = (onConfirm) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are editing an event, do you want to discard the data?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes, leave',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm()
      }
    })
  }

  const handleNavigation = (e, itemPath) => {
    const urlParams = new URLSearchParams(location.search)
    if (urlParams.has('id')) {
      e.preventDefault()
      showAlertAndNavigate(() => {
        removeCreateEventData()
        urlParams.delete('id')
        history.push(itemPath)
      })
    }
  }

  useEffect(() => {
    const unblock = history.block((tx) => {
      if (history.action === 'POP') {
        const urlParams = new URLSearchParams(location.search)

        if (urlParams.has('id')) {
          showAlertAndNavigate(() => {
            removeCreateEventData()
            urlParams.delete('id')
            unblock()
            history.push(tx.pathname)
          })
          return false
        }
      }
      return true
    })

    return () => {
      unblock()
    }
  }, [location, history])

  if (isLoading) {
    return <LoadingOverlay />
  }

  return (
    <>
      <div className='px-3'>
        <p className='text-uppercase text-orange fw-bold f-24 mb-0'>Events</p>
        <Link
          className='text-orange d-flex align-items-center f-16 w-fit-content'
          to={`/admin-console/${selectedShop?.shopSlug}/event`}
          onClick={(e) => handleNavigation(e, `/admin-console/${selectedShop?.shopSlug}/event`)}
        >
          <GoArrowLeft />
          <span className='text-link'>Back</span>
        </Link>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} className='container'>
        <div className='row-top create-event-page'>
          <div className='c-form c-form--line'>
            <div className='form-group d-none'>
              <div className='upload-excel-group'>
                <label className='upload-excel'>
                  Upload excel (<a href='/misc/sample.xlsx'>sample</a>)
                  <input
                    type='file'
                    accept='.xlsx'
                    onChange={onFileChanged}
                    ref={fileInputRef}
                    className='d-none'
                  />
                </label>
                {/* {registerScheduleEvent
                ? <label className='register-schedule-event'
                  data-toggle='tooltip'
                  data-placement='right'
                  title='This feature allows the host to set a time
                   to be able to publish the event.
                   Before the public, the event will not
                   be seen by the buyer.'
                  onClick={toggle}
                >
                  The schedule-event feature is available, register to use it?
                </label> : null} */}
              </div>
            </div>
            <EventTitle register={register('title')} error={errors?.title?.message} />
            <EventDescription
              register={register('description')}
              error={errors?.description?.message}
            />
            <EventPickupLocation
              register={register('pickupAddress')}
              error={errors?.pickupAddress?.message}
            />
            <div className='row mt-3'>
              <div className='col-6'>
                <div className='form-group'>
                  <label>
                    Closing Date<span className='sys'>*</span>
                  </label>
                  <div className='have-icon'>
                    <DatePicker
                      onChange={onChangeClosingDate}
                      value={closingDate}
                      minDate={new Date()}
                      maxDate={moment.unix(currentPackage?.expireTimestamp).toDate()}
                      dataTestId='event-closing-date'
                    />
                  </div>
                </div>
              </div>
              <div className='col-6 col-6-date'>
                <div className='form-group'>
                  <label>
                    Collection Date<span className='sys'>*</span>
                  </label>
                  <div className='have-icon'>
                    <DatePicker
                      onChange={setCollectionDate}
                      value={collectionDate}
                      dataTestId='event-collection-date'
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label>Collection Time</label>
                  <div className='form-group-time'>
                    <div className='form-group-time__item'>
                      <select
                        data-testid='event-collection-time-start'
                        className='form-control'
                        value={collectionTimeStart}
                        onChange={(e) => setCollectionTimeStart(e.target.value)}
                      >
                        {LIST_TIME.map((item, index) => (
                          <option key={index}>{item.text}</option>
                        ))}
                      </select>
                    </div>
                    <span>-</span>
                    <div className='form-group-time__item'>
                      <select
                        data-testid='event-collection-time-end'
                        className='form-control'
                        value={collectionTimeEnd}
                        onChange={(e) => setCollectionTimeEnd(e.target.value)}
                      >
                        {LIST_TIME.map((item, index) => (
                          <option key={index}>{item.text}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PaymentOption
            register={register}
            toggleExternalURL={toggleExternalURL}
            hiddenNote={hiddenNote}
            hiddenExternalURL={hiddenExternalURL}
            externalUrl={externalUrl}
            setExternalUrl={setExternalUrl}
            setValue={setValue}
            paynow={paynow}
            setPaynow={setPaynow}
            payTogether={payTogether}
            setPayTogether={setPayTogether}
            watch={watch}
            control={control}
            getValues={getValues}
            hiddenDelivery={hiddenDelivery}
            setHiddenDelivery={setHiddenDelivery}
            eid={eid}
          />
        </div>
        <div className='row row-bot mt-4' style={{ transform: 'translateY(-55px)' }}>
          <div className='col-6'>
            <button type='button' className='btn btn--solid btn--solid03' onClick={onCancel}>
              CANCEL
            </button>
          </div>
          <div className='col-6'>
            <button className='btn btn--main' type='submit' data-testid='btn-next'>
              NEXT
            </button>
          </div>
        </div>
        {isShowModal && (
          <ModalGeneralPayment
            isShow={isShowModal}
            toggle={toggle}
            isSubmittedPayment={isSubmittedPayment}
            service={{ type: 'Schedule event', price: PAYMENT_SERVICE_SCHEDULE_PRICE }}
            paymentFileUrl={paymentFileUrl}
            confirmPayment={confirmPayment}
            onUserImageUploaded={onUserImageUploaded}
          />
        )}
      </Form>
    </>
  )
}

export default CreateEvent
