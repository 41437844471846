import {
  PACKAGE_SUBSCRIPTION_BASIC,
  PAYMENT_HITPAY,
  SUBSCRIPTION_STATUS_PENDING,
  SUBSCRIPTION_STATUS_REFUNDED,
  SUBSCRIPTION_STATUS_UNPAID,
  TRANSFER_BANKING,
} from '_utils/constant'
import { isNull } from 'lodash'
import moment from 'moment'
import React from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { MdPayment } from 'react-icons/md'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import RenderStatus from './RenderStatus'

const paymentTypeInfo = {
  [PAYMENT_HITPAY]: {
    color: '#ff6903',
    label: 'Hitpay',
  },
  [TRANSFER_BANKING]: {
    color: '#15cdca',
    label: 'Banking',
  },
  DEFAULT: {
    color: '#6c757d',
    label: 'Free',
  },
}

const getPaymentInfo = (paymentType) => paymentTypeInfo[paymentType] || paymentTypeInfo.DEFAULT

const ListInvoiceSubscription = ({
  loading,
  currentInvoices,
  togglePopover,
  popoverRef,
  popoverOpen,
  checkEligibilityForHitpayPayment,
  onRemove,
  tableHeaders,
}) => (
  <>
    {!loading && currentInvoices.length
      ? currentInvoices.map((item, index) => (
          <tr key={item.id}>
            <th scope='row'>{index + 1}</th>
            <td>
              {item.name} {item.price ? `($${item.price})` : null}
            </td>
            <td>
              <RenderStatus item={item} type='subscription' />
            </td>
            <td>
              <p className='mb-0'>
                <strong style={{ color: getPaymentInfo(item.paymentType).color }}>
                  {getPaymentInfo(item.paymentType).label}
                </strong>
              </p>
            </td>
            <td>
              <button
                className='bg-transparent border-0 shadow-none rounded-circle'
                style={{ fontSize: 16, height: 30, width: 30, lineHeight: '18px' }}
                id={`popover-${item.id}`}
                onClick={() => togglePopover(item.id)}
              >
                <BsInfoCircle />
              </button>
              {item.id && (
                <Popover
                  innerRef={popoverRef}
                  placement={'top'}
                  isOpen={popoverOpen[item.id]}
                  target={`popover-${item.id}`}
                  toggle={() => togglePopover(item)}
                >
                  <PopoverHeader>Info Subscription</PopoverHeader>
                  <PopoverBody>
                    <p className='mb-0'>
                      Id: <strong>{item?.id}</strong>
                    </p>
                    <p className='mb-0'>
                      Created Date:
                      <strong>
                        {!isNull(item?.createdTimestamp || item?.createdDateTimestamp)
                          ? moment
                              .unix(item.createdTimestamp || item.createdDateTimestamp)
                              .format('DD/MM/YYYY')
                          : null}
                      </strong>
                    </p>
                    {!isNull(item?.activeDateTimestamp) && (
                      <p className='mb-0'>
                        Approved Date:{' '}
                        <strong>
                          {moment.unix(item?.activeDateTimestamp).format('DD/MM/YYYY')}
                        </strong>
                      </p>
                    )}
                    {item?.packageId &&
                      !isNull(item?.expireTimestamp) &&
                      (item.subscriptionPackageType !== PACKAGE_SUBSCRIPTION_BASIC ||
                      moment.unix(item.expireTimestamp).diff(moment(), 'years') < 100 ? (
                        <p className='mb-0'>
                          Expired Date:{' '}
                          <strong>{moment.unix(item.expireTimestamp).format('DD/MM/YYYY')}</strong>
                        </p>
                      ) : null)}
                    {item?.status === SUBSCRIPTION_STATUS_REFUNDED && (
                      <p className='mb-0'>
                        Refunded Date:{' '}
                        <strong>{moment.unix(item?.lastModifiedAt).format('DD/MM/YYYY')}</strong>
                      </p>
                    )}
                  </PopoverBody>
                </Popover>
              )}
            </td>
            <td>
              <div className='d-flex align-items-center justify-content-between w-100'>
                {item.paymentType === PAYMENT_HITPAY &&
                (item.status === SUBSCRIPTION_STATUS_PENDING ||
                  item.status === SUBSCRIPTION_STATUS_UNPAID) &&
                !item.name.toLowerCase().includes('basic') ? (
                  <>
                    <button
                      className='btn-payment mx-2 rounded d-flex align-items-center justify-content-center'
                      onClick={() => checkEligibilityForHitpayPayment(item)}
                      style={{ fontSize: 12 }}
                    >
                      <MdPayment size={16} />
                      Confirm
                    </button>

                    <button
                      className='btn btn-secondary rounded shadow-none h-auto mt-0'
                      style={{ padding: 5, fontSize: 12 }}
                      onClick={() => onRemove(item)}
                    >
                      Remove
                    </button>
                  </>
                ) : (
                  <span className='fw-bold text-dark-emphasis'>No Actions</span>
                )}
              </div>
            </td>
          </tr>
        ))
      : null}

    {!loading && !currentInvoices.length ? (
      <tr>
        <td colSpan={tableHeaders.length} style={{ textAlign: 'center' }}>
          No data
        </td>
      </tr>
    ) : null}
  </>
)

export default ListInvoiceSubscription
