import React from 'react'
import { AiFillCheckCircle, AiFillShopping } from 'react-icons/ai'
import { IoLogInOutline } from 'react-icons/io5'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

function ModalNotifySuccess({ isModalOpen, handleWhenShowModalPaymentSuccess, isCOD }) {
  return (
    <Modal isOpen={isModalOpen} className='modal-payment py-0'>
      <ModalBody
        style={{
          fontSize: 16,
        }}
      >
        <div className='text-center shadow-none border-0'>
          <h4 className='mb-3'>
            Buy Product{' '}
            <AiFillShopping
              style={{
                transform: 'translateY(-4px)',
              }}
            />
          </h4>
          <p
            className='mb-2 fw-semibold'
            style={{
              color: 'green',
            }}
          >
            You have success {isCOD ? 'ordered' : 'paid'}!
            <AiFillCheckCircle
              color='green'
              size={24}
              className='ms-1'
              style={{
                transform: 'translateY(-2px)',
              }}
            />
          </p>
          <p className='f-16 mb-0'>Do you want to continue shopping at this shop?</p>
        </div>
      </ModalBody>
      <ModalFooter className='border-top-0 border-0'>
        <div className='w-75 mx-auto row justify-content-between'>
          <Button
            color='secondary'
            className='rounded-pill btn-cancel col-5'
            onClick={() => handleWhenShowModalPaymentSuccess('exit')}
            style={{
              background: 'lightslategray',
              color: 'white',
            }}
          >
            Exit
          </Button>
          <Button
            color='primary'
            className='rounded-pill btn-submit w-auto mt-0 col-5'
            onClick={() => handleWhenShowModalPaymentSuccess('continue')}
          >
            Continue <IoLogInOutline size={24} className='ms-1' />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ModalNotifySuccess
