import React from 'react'
import { useSelector } from 'react-redux'

const disabledStyle = {
  pointerEvents: 'none',
  opacity: 0.5,
  filter: 'grayscale(100%)',
}

const OrderActions = ({ toggleOrder, exportOrders, isOpenOrder }) => {
  const { loadingCurrentDeliveryAndBooking } = useSelector((state) => state.deliveryEvent)

  return (
    <div className='pb-3 order-actions'>
      <p className='mb-2'>Order actions:</p>
      <div className='d-flex justify-content-between gap-3'>
        <button className='btn bg-white' onClick={toggleOrder} type='button'>
          {isOpenOrder ? 'Close Delivery' : 'Re-open Delivery'}
        </button>
        <button
          type='button'
          className='btn bg-white mt-0'
          onClick={exportOrders}
          disabled={loadingCurrentDeliveryAndBooking}
          style={loadingCurrentDeliveryAndBooking ? disabledStyle : {}}
        >
          Export Orders
        </button>
      </div>
    </div>
  )
}

export default OrderActions
