import { CREATE_SHOP_DATA } from '_utils/constant'

const useLogout = () => {
  for (const key in localStorage) {
    if (![CREATE_SHOP_DATA, 'URL_BEFORE_LOGOUT'].includes(key)) {
      localStorage.removeItem(key)
    }
  }
  window.location.assign('/virtual-shop/sign-in')
}

export default useLogout
