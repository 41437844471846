import subscriptionApi from '_api/subscription'
import uploadApi from '_api/upload'
import userApi from '_api/user'
import virtualShopApi from '_api/virtualShop'
import {
  HITPAY_PAYMENT_RECURRING_BILLING,
  PACKAGE_LAZADA,
  PAYMENT_HITPAY,
  SUBSCRIPTION_STATUS_PENDING,
  SUBSCRIPTION_STATUS_REFUND,
  TRANSFER_BANKING,
} from '_utils/constant'

const UPLOAD_IMAGE_PAYMENT = 'UPLOAD_IMAGE_PAYMENT'
const UPLOAD_IMAGE_PAYMENT_ERROR = 'UPLOAD_IMAGE_PAYMENT_ERROR'
const CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST'
const SELECT_SUBSCRIPTION_PACKAGE = 'SELECT_SUBSCRIPTION_PACKAGE'
const CLEAR_SUBSCRIPTION_PACKAGE = 'CLEAR_SUBSCRIPTION_PACKAGE'
const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
const REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION'
const SELECT_LAZADA_PACKAGE = 'SELECT_LAZADA_PACKAGE'
const CLEAR_LAZADA_PACKAGE = 'CLEAR_LAZADA_PACKAGE'
const FETCH_INVOICE_SUBSCRIPTION = 'FETCH_INVOICE_SUBSCRIPTION'
const SET_CURRENT_PACKAGE = 'SET_CURRENT_PACKAGE'
const CLEAR_LIST_INVOICE_SUBSCRIPTION = 'CLEAR_LIST_INVOICE_SUBSCRIPTION'

const initialState = {
  paymentMethod: HITPAY_PAYMENT_RECURRING_BILLING,
  paymentFileUrl: '',
  errorUploadPayment: '',
  isSubmittedPayment: false,
  subscriptionPackageSelected: null,
  listInvoiceSubscription: [],
  currentPackage: null,
}

export const clearListInvoiceSubscription = () => (dispatch) => {
  dispatch({
    type: CLEAR_LIST_INVOICE_SUBSCRIPTION,
    payload: { listInvoiceSubscription: [] },
  })
}

export const setCurrentPackage = (data) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_PACKAGE,
    payload: {
      currentPackage: data,
    },
  })
}

export const fetchListInvoiceSubscription = () => async (dispatch) => {
  try {
    const { msgResp } = await userApi.getListInvoice()

    // const mockInvoices = [
    //   {
    //     id: 'm0VSi4b5dbQbQInGapfb',
    //     createdDateTimestamp: 1723535942,
    //     createdUserId: 'tgDjfUGcnF3WXTIlBw1x',
    //     createdUserEmail: 'testaccount1208@gmail.com',
    //     paymentRequestId: '9cc132c3-55a8-46a4-83a3-c530c60664ed',
    //     recurringBillingId: '9cc132c3-55a8-46a4-83a3-c530c60664ed',
    //     packageId: 'Xt6N10Bq3gVKk5GAiIYt',
    //     paymentType: 2,
    //     variationId: '1a6t6qj8vwi9440541076',
    //     hitpaySubscriptionPlanId: '9abaedc5-df63-47b7-80ed-f9b62651a030',
    //     price: 25,
    //     name: 'Professional',
    //     isSendEmailBeforeExpire: false,
    //     subscriptionPackageType: 2,
    //     isRecurringBilling: true,
    //     lastModifiedAt: 1723535963,
    //     expireTimestamp: 2102227163,
    //     invoices: [
    //       {
    //         payment_id: '9cc132db-cc61-46ab-b1c7-cb11b26b1568',
    //         recurring_billing_id: '9cc132c3-55a8-46a4-83a3-c530c60664ed',
    //         hmac: '1bfc79c94bb452f447de1eb3380aa7fa222ce1d3b36fe6df42b8dcae4d23b4e5',
    //         paymentStatus: 'completed',
    //         paymentType: 'hit-pay Recurring Billing',
    //         paymentTimestamp: 1723535963,
    //       },
    //     ],
    //     currentUsing: false,
    //     activeDateTimestamp: 1723535963,
    //     paymentStatus: 3,
    //     status: 0,
    //   },
    //   {
    //     id: 'm0VSi4b5dbQbQInGapfc',
    //     createdDateTimestamp: 1723535943,
    //     createdUserId: 'tgDjfUGcnF3WXTIlBw1y',
    //     createdUserEmail: 'testaccount1209@gmail.com',
    //     paymentRequestId: '9cc132c3-55a8-46a4-83a3-c530c60664ee',
    //     recurringBillingId: '9cc132c3-55a8-46a4-83a3-c530c60664ee',
    //     packageId: 'Xt6N10Bq3gVKk5GAiIYu',
    //     paymentType: 2,
    //     variationId: '1a6t6qj8vwi9440541077',
    //     hitpaySubscriptionPlanId: '9abaedc5-df63-47b7-80ed-f9b62651a031',
    //     price: 30,
    //     name: 'Basic',
    //     isSendEmailBeforeExpire: false,
    //     subscriptionPackageType: 1,
    //     isRecurringBilling: true,
    //     lastModifiedAt: 1723535964,
    //     expireTimestamp: 2102227164,
    //     invoices: [
    //       {
    //         payment_id: '9cc132db-cc61-46ab-b1c7-cb11b26b1569',
    //         recurring_billing_id: '9cc132c3-55a8-46a4-83a3-c530c60664ee',
    //         hmac: '1bfc79c94bb452f447de1eb3380aa7fa222ce1d3b36fe6df42b8dcae4d23b4e6',
    //         paymentStatus: 'completed',
    //         paymentType: 'hit-pay Recurring Billing',
    //         paymentTimestamp: 1723535964,
    //       },
    //     ],
    //     currentUsing: true,
    //     activeDateTimestamp: 1723535964,
    //     paymentStatus: 3,
    //     status: 2,
    //   },
    // ]

    const currentPackage = msgResp?.invoicesSubscriptionSort.find((item) => {
      if (item.paymentStatus === SUBSCRIPTION_STATUS_PENDING) {
        return true
      }

      return item.currentUsing
    })

    dispatch({
      type: FETCH_INVOICE_SUBSCRIPTION,
      payload: {
        listInvoiceSubscription: msgResp?.invoicesSubscriptionSort || [],
        currentPackage,
      },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const handleRemoveSubscription = (subId) => async (dispatch, getState) => {
  const { subscription } = getState()
  const { listInvoiceSubscription } = subscription

  const newCurrentInvoices = listInvoiceSubscription.filter((item) => item?.id !== subId)

  try {
    dispatch({
      type: REMOVE_SUBSCRIPTION,
      payload: {
        listInvoiceSubscription: newCurrentInvoices,
        isSubmittedPayment: false, // re-open button registration for subscription
      },
    })
    await subscriptionApi.removeSubscription(subId)
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const handleCancelSubscription = (subId) => async (dispatch, getState) => {
  const { subscription } = getState()
  const { listInvoiceSubscription } = subscription
  const newCurrentInvoices = [...listInvoiceSubscription]
  const findIndex = newCurrentInvoices.findIndex((item) => item?.id === subId)

  newCurrentInvoices[findIndex].status = SUBSCRIPTION_STATUS_REFUND

  try {
    dispatch({
      type: CANCEL_SUBSCRIPTION,
      payload: {
        listInvoiceSubscription: newCurrentInvoices,
        isSubmittedPayment: false, // re-open button registration for subscription
      },
    })
    await subscriptionApi.cancelSubscription(subId)
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const clearSubscriptionPackage = () => (dispatch) => {
  dispatch({
    type: CLEAR_SUBSCRIPTION_PACKAGE,
    payload: {
      subscriptionPackageSelected: null,
    },
  })
}

export const selectSubscriptionPackage = (value) => (dispatch) => {
  dispatch({
    type: SELECT_SUBSCRIPTION_PACKAGE,
    payload: {
      subscriptionPackageSelected: value,
    },
  })
}

export const submitRequestPayment = (value) => (dispatch) => {
  dispatch({
    type: CREATE_PAYMENT_REQUEST,
    payload: {
      isSubmittedPayment: value,
    },
  })
}

export const uploadImagePayment = (event) => async (dispatch) => {
  const { files } = event.target
  if (files) {
    const img = files[0]

    if (img) {
      dispatch({
        type: UPLOAD_IMAGE_PAYMENT,
        payload: {
          paymentFileUrl: URL.createObjectURL(img),
        },
      })
      try {
        const { msgResp } = await uploadApi.uploadPhoto(files)
        dispatch({
          type: UPLOAD_IMAGE_PAYMENT,
          payload: {
            paymentFileUrl: msgResp.url,
          },
        })
      } catch (error) {
        dispatch({
          type: UPLOAD_IMAGE_PAYMENT_ERROR,
          payload: {
            errorUploadPayment: error.message,
          },
        })
      }
    }
  }
}

export const handlePaymentHitpay = (shopId, paymentUrl) => async (dispatch, getState) => {
  const { lazadaPackageSelected } = getState()

  try {
    const { msgResp } = await virtualShopApi.upgradeLazadaFeature(shopId, {
      lazadaFeaturePaymentScreenshot: paymentUrl,
      lazadaFeaturePrice: lazadaPackageSelected?.price, // HARDCODE
      lazadaFeaturePaymentType: PAYMENT_HITPAY,
      lazadaFeaturePackageId: lazadaPackageSelected?.id,
      packageType: PACKAGE_LAZADA,
      paymentType: PAYMENT_HITPAY,
    })

    dispatch({
      type: PAYMENT_HITPAY,
      payload: {},
    })

    if (msgResp?.lazadaFeaturePaymentLink) {
      window.open(msgResp?.lazadaFeaturePaymentLink, '_blank')
    }
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const handlePaymentTransfer = (shopId, paymentUrl) => async (dispatch, getState) => {
  const { lazadaPackageSelected } = getState()
  try {
    await virtualShopApi.upgradeLazadaFeature(shopId, {
      lazadaFeaturePaymentScreenshot: paymentUrl,
      lazadaFeaturePrice: lazadaPackageSelected?.price,
      lazadaFeaturePaymentType: TRANSFER_BANKING,
      lazadaFeaturePackageId: lazadaPackageSelected?.id,
      packageType: PACKAGE_LAZADA,
      paymentType: TRANSFER_BANKING,
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_LIST_INVOICE_SUBSCRIPTION:
    case SET_CURRENT_PACKAGE:
    case FETCH_INVOICE_SUBSCRIPTION:
    case CLEAR_LAZADA_PACKAGE:
    case SELECT_LAZADA_PACKAGE:
    case REMOVE_SUBSCRIPTION:
    case CANCEL_SUBSCRIPTION:
    case CLEAR_SUBSCRIPTION_PACKAGE:
    case SELECT_SUBSCRIPTION_PACKAGE:
    case CREATE_PAYMENT_REQUEST:
    case UPLOAD_IMAGE_PAYMENT_ERROR:
    case UPLOAD_IMAGE_PAYMENT:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default subscriptionReducer
