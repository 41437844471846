import { yupResolver } from '@hookform/resolvers/yup'
import deliveryEventApi from '_api/deliveryEvent'
import {
  createDeliveryZones,
  deleteDeliveryZone,
  getListDeliveryAndDeliveryBooking,
  updateDeliveryZones,
} from '_redux/modules/deliveryEvent'
import {
  DEFAULT_SELECTED_DELIVERY_ZONES,
  DELIVERY_BUYER_CANCELLED,
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_CLOSED,
  DELIVERY_HOST_CANCELLED,
  DELIVERY_HOST_PAID,
  DELIVERY_HOST_UNPAID,
  DELIVERY_OPENED,
} from '_utils/constant'
import { exportToCsv, normalizeName } from '_utils/function'
import {
  convertTimestampToCurrentGMT,
  convertTimeStringWithDuration,
  convertToGMT0,
} from '_utils/functions/converter'
import { getUserInfo } from '_utils/localData'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'
import * as yup from 'yup'

const schema = yup.object().shape({
  selectedDeliveryZones: yup.array().of(
    yup.object().shape({
      optionName: yup.string().required('Option name is required'),
      deliveryFee: yup
        .number()
        .typeError('Delivery fee must be a number')
        .min(0, 'Delivery fee must be greater than or equal to 0')
        .required('Delivery fee is required'),
    })
  ),
})

export const useDeliveryZonesLogic = (isOpen, dataModal, setDataModal, toggle) => {
  const mappedDeliveryZones = dataModal
    ? dataModal.deliveryZones.map((zone) => ({
        optionName: zone.name || zone.optionName,
        deliveryFee: zone.price || zone.deliveryFee,
      }))
    : null

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    register,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      startDate: moment().toDate(),
      time: { start: 8, end: 17 }, // follow format 24h
      selectedDeliveryZones: DEFAULT_SELECTED_DELIVERY_ZONES,
    },
    resolver: yupResolver(schema),
  })

  const history = useHistory()
  const userInfo = getUserInfo()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { selectedShop } = useSelector((state) => state.shop)
  const { currentDeliveryAndDeliveryBooking } = useSelector((state) => state.deliveryEvent)
  const { shopEventDeliveryBookings = [] } = currentDeliveryAndDeliveryBooking

  const [isOpenOrder, setIsOpenOrder] = useState(false)
  const [listOrderDeliveryEvent, setListOrderDeliveryEvent] = useState([])
  const [deliveryTime, setDeliveryTime] = useState(null)
  const [isLoadingDeliveryZones, setIsLoadingDeliveryZones] = useState(true)

  const selectedDeliveryZones = watch('selectedDeliveryZones')
  const time = watch('time')

  useEffect(() => {
    if (!isOpen) {
      reset()
      if (typeof setDataModal === 'function') setDataModal(null)
    }

    const fetchDeliveryZones = async () => {
      try {
        const { msgResp } = await deliveryEventApi.getRememberedDelivery()
        setValue(
          'selectedDeliveryZones',
          msgResp.map((zone) => ({ optionName: zone.name, deliveryFee: zone.price }))
        )
      } catch (error) {
        addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
      } finally {
        setIsLoadingDeliveryZones(false)
      }
    }

    fetchDeliveryZones()
  }, [isOpen, setDataModal])

  useEffect(() => {
    if (dataModal) {
      const deliveryTimeObject = moment(dataModal.deliveryTime, 'DD-MM-YYYY HH:mm').toDate()
      const timestampDelivery = moment(deliveryTimeObject).unix()
      const currentTimestampDelivery = convertTimestampToCurrentGMT(timestampDelivery)
      const timeStart = moment.unix(currentTimestampDelivery).hour()
      const timeEnd = timeStart + dataModal.deliveryDuration / 3600

      setValue('startDate', deliveryTimeObject)
      setValue('time', { start: timeStart, end: timeEnd })

      if (dataModal.deliveryStatus) {
        setIsOpenOrder(true)
      } else {
        setIsOpenOrder(false)
      }

      setDeliveryTime(dataModal.deliveryTime)

      dispatch(getListDeliveryAndDeliveryBooking(dataModal.id))
    }
  }, [dataModal])

  useEffect(() => {
    if (mappedDeliveryZones) {
      setValue('selectedDeliveryZones', mappedDeliveryZones)
    }
  }, [JSON.stringify(mappedDeliveryZones)])

  useEffect(() => {
    if (shopEventDeliveryBookings) {
      setListOrderDeliveryEvent(shopEventDeliveryBookings)
    }
  }, [JSON.stringify(shopEventDeliveryBookings)])

  const onChangeDate = (date) => {
    setValue('startDate', date)
  }

  const onChangeTime = (e) => {
    const { id, value } = e.target
    setValue('time', {
      ...time,
      [id]: parseInt(value, 10),
    })
  }

  const onSelectDeliveryZone = (selectedDelivery, isChecked) => {
    if (isChecked) {
      const getDeliveryZones = getValues('selectedDeliveryZones')
      if (getDeliveryZones.length === 1 && getDeliveryZones[0].optionName === '') {
        const INDEX = 0
        const DELETE_COUNT = 1
        setValue('selectedDeliveryZones', getDeliveryZones.splice(INDEX, DELETE_COUNT))
      }
      setValue('selectedDeliveryZones', [
        ...selectedDeliveryZones,
        {
          optionName: selectedDelivery.optionName,
          deliveryFee: selectedDelivery.deliveryFee,
        },
      ])
    } else {
      const newSelectedDeliveryZones = selectedDeliveryZones.filter(
        (zone) => zone.optionName !== selectedDelivery.optionName
      )
      setValue('selectedDeliveryZones', newSelectedDeliveryZones)
    }
  }

  const onSubmit = async (data) => {
    try {
      const deliveryDurationData = (data.time.end - data.time.start) * 3600

      if (deliveryDurationData < 0) {
        return addToast('Invalid delivery time duration', {
          appearance: 'error',
          autoDismiss: true,
        })
      }

      const deliveryTimeLocal = `${moment(data.startDate).format('DD-MM-YYYY')} ${
        data.time.start
      }:00`
      const deliveryTimeGMT0 = convertToGMT0(deliveryTimeLocal)

      const postData = {
        deliveryDuration: dataModal ? dataModal.deliveryDuration : deliveryDurationData,
        deliveryTime: deliveryTimeGMT0,
        deliveryZones: data.selectedDeliveryZones,
        shopId: selectedShop.id,
      }

      const postMappedDeliveryZones = data.selectedDeliveryZones.map((zone) => ({
        name: zone.optionName,
        price: zone.deliveryFee,
      }))

      if (!dataModal) {
        await dispatch(createDeliveryZones(postData))
      } else {
        await dispatch(updateDeliveryZones(postData, dataModal.id))
      }

      await deliveryEventApi.updateRememberedDelivery(userInfo.id, {
        shopEventRememberedDeliveryZones: postMappedDeliveryZones,
      })

      const message = dataModal
        ? 'Delivery zones updated successfully'
        : 'Delivery zones created successfully'
      addToast(message, { appearance: 'success', autoDismiss: true })

      if (typeof setDataModal === 'function') {
        setDataModal(null)
      }
      toggle()

      history.push(`/admin-console/${selectedShop?.shopSlug}/manage-delivery-zones`)
    } catch (error) {
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
      reset({}, { keepValues: true, keepIsSubmitted: false, keepIsSubmitting: false })
    }
  }

  const onDeleteDeliveryZone = async () => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this delivery zone!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        reverseButtons: true,
      })

      if (result.isConfirmed) {
        dispatch(deleteDeliveryZone(dataModal.id))
        addToast('Delivery zone deleted success', { appearance: 'success', autoDismiss: true })
        setDataModal(null)
        toggle()
      }
    } catch (error) {
      addToast(error.msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const onUpdateStatusDelivery = async () => {
    try {
      const r = confirm('Are you sure?')
      if (r === true) {
        const data = { deliveryStatus: isOpenOrder ? DELIVERY_CLOSED : DELIVERY_OPENED }
        await dispatch(updateDeliveryZones(data, dataModal.id))
        setIsOpenOrder(!isOpenOrder)

        const message = isOpenOrder
          ? 'Delivery zone closed successfully'
          : 'Delivery zone opened successfully'

        addToast(message, { appearance: 'success', autoDismiss: true })
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const STATUS_MAP = {
    [DELIVERY_HOST_CANCELLED]: 'Cancelled',
    [DELIVERY_HOST_UNPAID]: 'Not paid',
    [DELIVERY_HOST_PAID]: 'Paid',
  }

  const BOOKER_STATUS_MAP = {
    [DELIVERY_BUYER_CANCELLED]: 'Cancelled',
    [DELIVERY_BUYER_REFUND]: 'Refund',
    [DELIVERY_BUYER_UNPAID]: 'Not paid',
    [DELIVERY_BUYER_PAID]: 'Paid',
  }

  const HEADERS = [
    'Zone name',
    'Zone price',
    'Address',
    'Buyer name',
    'Buyer phone',
    'Buyer email',
    'Status',
    'Booker Status',
    'Comment',
  ]

  const getFileName = (_deliveryTime, _deliveryDuration) =>
    normalizeName(
      `Delivery on ${convertTimeStringWithDuration(_deliveryTime, _deliveryDuration)}`
    ).replace(/-+/g, '-')

  const onExportOrder = () => {
    if (!listOrderDeliveryEvent || listOrderDeliveryEvent.length === 0) {
      addToast('No data to export', { appearance: 'warning', autoDismiss: true })
      return
    }

    const rowsOfData = listOrderDeliveryEvent.map(
      ({ dzName, dzPrice, address, uName, uPhone, uEmail, status, comment, bookerStatus }) => [
        dzName,
        `$${dzPrice}`,
        address,
        uName,
        uPhone,
        uEmail,
        STATUS_MAP[status] || '',
        BOOKER_STATUS_MAP[bookerStatus] || '',
        comment,
      ]
    )

    const rows = [HEADERS, ...rowsOfData]
    const fileName = getFileName(deliveryTime, dataModal.deliveryDuration)

    exportToCsv(`${fileName}.csv`, rows)
  }

  return {
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    register,
    getValues,
    errors,
    isSubmitting,
    selectedDeliveryZones,
    time,
    onChangeDate,
    onChangeTime,
    onSelectDeliveryZone,
    onSubmit,
    onDeleteDeliveryZone,
    onUpdateStatusDelivery,
    onExportOrder,
    isOpenOrder,
    isLoadingDeliveryZones,
  }
}
