import deliveryApi from '_api/delivery'

const GET_LIST_DELIVERY = 'GET_LIST_DELIVERY'
const GET_LIST_DELIVERY_SUCCESS = 'GET_LIST_DELIVERY_SUCCESS'
const GET_LIST_DELIVERY_FAILURE = 'GET_LIST_DELIVERY_FAILURE'
const UPDATE_STATUS_DELIVERY = 'UPDATE_STATUS_DELIVERY'
const UPDATE_STATUS_DELIVERY_SUCCESS = 'UPDATE_STATUS_DELIVERY_SUCCESS'
const UPDATE_STATUS_DELIVERY_FAILURE = 'UPDATE_STATUS_DELIVERY_FAILURE'
const GET_LIST_PICK_UP_OPTIONS_REQUEST = 'GET_LIST_PICK_UP_OPTIONS_REQUEST'
const GET_LIST_PICK_UP_OPTIONS_SUCCESS = 'GET_LIST_PICK_UP_OPTIONS_SUCCESS'
const GET_LIST_PICK_UP_OPTIONS_FAILURE = 'GET_LIST_PICK_UP_OPTIONS_FAILURE'

const initialState = {
  listDelivery: [],
  errorGetDelivery: null,
  deliveryLoading: false,
  errorUpdateStatus: null,
  updateStatusLoading: false,
  listPickUpOptions: [],
}


export const getListDelivery = async (dispatch) => {
  dispatch({
    type: GET_LIST_DELIVERY,
    payload: { deliveryLoading: true },
  })
  try {
    const { msgResp } = await deliveryApi.getListShopDeliveryByHost()

    dispatch({
      type: GET_LIST_DELIVERY_SUCCESS,
      payload: { listDelivery: msgResp, deliveryLoading: false },
    })
  } catch (error) {
    dispatch({
      type: GET_LIST_DELIVERY_FAILURE,
      payload: { errorGetDelivery: error, deliveryLoading: false },
    })
  }
}

export const updateStatusDelivery = (ref, status, bookerStatus) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_STATUS_DELIVERY,
    payload: { updateStatusLoading: true },
  })
  try {
    const { deliveryShop } = getState()
    const { listDelivery } = deliveryShop
    const index = listDelivery.findIndex((item) => item.ref === ref)

    if (index !== -1) {
      listDelivery[index].status = status
      listDelivery[index].bookerStatus = bookerStatus
    }

    dispatch({
      type: UPDATE_STATUS_DELIVERY_SUCCESS,
      payload: { updateStatusLoading: false },
    })
  } catch (error) {
    dispatch({
      type: UPDATE_STATUS_DELIVERY_FAILURE,
      payload: { errorUpdateStatus: error.message, updateStatusLoading: false },
    })
  }
}

export const getListPickUpOptions = (shopId) => async (dispatch) => {
  dispatch({
    type: GET_LIST_PICK_UP_OPTIONS_REQUEST,
    payload: { deliveryLoading: true },
  })
  try {
    const { msgResp } = await deliveryApi.getListPickUpOptions(shopId)

    dispatch({
      type: GET_LIST_PICK_UP_OPTIONS_SUCCESS,
      payload: { listPickUpOptions: msgResp, deliveryLoading: false },
    })
  } catch (error) {
    dispatch({
      type: GET_LIST_PICK_UP_OPTIONS_FAILURE,
      payload: { errorGetDelivery: error, deliveryLoading: false },
    })
  }
}

const delivery = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_DELIVERY:
      return {
        ...state,
        ...action.payload,
      }
    case GET_LIST_DELIVERY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case GET_LIST_DELIVERY_FAILURE:
      return {
        ...state,
        ...action.payload,
      }
    case UPDATE_STATUS_DELIVERY:
      return {
        ...state,
        ...action.payload,
      }
    case UPDATE_STATUS_DELIVERY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case UPDATE_STATUS_DELIVERY_FAILURE:
      return {
        ...state,
        ...action.payload,
      }
    case GET_LIST_PICK_UP_OPTIONS_REQUEST:
      return {
        ...state,
        ...action.payload,
      }
    case GET_LIST_PICK_UP_OPTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case GET_LIST_PICK_UP_OPTIONS_FAILURE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default delivery
