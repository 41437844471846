import moment from 'moment'

const DeliveryDateRange = ({ rangeDate }) => (
  <p className='mb-0'>
    <span className='me-1'>Selecting date from</span>
    <strong className='me-1'>{moment(rangeDate[0]?.startDate).format('DD/MM/YYYY')}</strong>
    <span className='me-1'>to</span>
    <strong>{moment(rangeDate[0]?.endDate).format('DD/MM/YYYY')}</strong>
  </p>
)

export default DeliveryDateRange
