import React from 'react'

import { DeliveryProvider } from '_contexts/DeliveryContext'
import useDelivery from '_hooks/useDelivery'
import DeliveryActions from './components/DeliveryActions'
import DeliveryExport from './components/DeliveryExport'
import DeliveryHeader from './components/DeliveryHeader'
import DeliveryList from './components/DeliveryList'
import DeliveryPickup from './components/DeliveryPickup'
import DeliveryDateRange from './components/DeliveryRangeDate'
import ModalFulfillmentDate from './components/ModalFulfillmentDate'
import './style.scss'

function Delivery() {
  const {
    filteredOrdersFulfillment,
    handleSelectDeliveryEvent,
    toggleModal,
    handleSearch,
    getRangeFulfillmentDate,
    handleClear,
    handleExport,
    updateStatusFulfillment,
    swapUpFulfillment,
    swapDownFulfillment,
    loading,
    isModalOpen,
    selectedDeliveryEventName,
    tabOrderDeliveryEvent,
    setTabOrderDeliveryEvent,
    setSelectedDeliveryEventName,
    setIsModalOpen,
    setLoading,
    setCurrentPage,
    setSearch,
    setRangeDate,
    setListDeliveryAndPickupShop,
    setCheckedIncludePickup,
    groupedDeliveryEvents,
    currentPage,
    search,
    rangeDate,
    listDeliveryAndPickupShop,
    checkedIncludePickup,
    deliveryIdParam,
    dataModal,
    modalPaymentScreen,
    togglePaymentScreen,
    openPaymentScreenDeliveryEvent,
    handleInvalidPaymentDeliveryEvent,
  } = useDelivery()

  return (
    <DeliveryProvider
      value={{
        filteredOrdersFulfillment,
        handleSelectDeliveryEvent,
        toggleModal,
        handleSearch,
        getRangeFulfillmentDate,
        handleClear,
        handleExport,
        updateStatusFulfillment,
        swapUpFulfillment,
        swapDownFulfillment,
        loading,
        isModalOpen,
        selectedDeliveryEventName,
        tabOrderDeliveryEvent,
        setTabOrderDeliveryEvent,
        setSelectedDeliveryEventName,
        setIsModalOpen,
        setLoading,
        setCurrentPage,
        setSearch,
        setRangeDate,
        setListDeliveryAndPickupShop,
        setCheckedIncludePickup,
        groupedDeliveryEvents,
        currentPage,
        search,
        rangeDate,
        listDeliveryAndPickupShop,
        checkedIncludePickup,
        deliveryIdParam,
        dataModal,
        modalPaymentScreen,
        togglePaymentScreen,
        openPaymentScreenDeliveryEvent,
        handleInvalidPaymentDeliveryEvent,
      }}
    >
      <div className='delivery p-3'>
        <DeliveryHeader title='Delivery & Fulfillment' />

        <DeliveryActions />

        <div className='d-flex align-items-center mt-3'>
          <DeliveryDateRange rangeDate={rangeDate} />
          <DeliveryExport />
        </div>

        {!deliveryIdParam && <DeliveryPickup />}

        <DeliveryList />

        <ModalFulfillmentDate />
      </div>
    </DeliveryProvider>
  )
}

export default Delivery
