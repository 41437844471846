import virtualShopApi from '_api/virtualShop'
import cancelImageIcon from '_images/cancelImageIcon.png'
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const privacyModeActive = 1

function ModalShowBlackList({ modal, toggle }) {
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const { selectedShop } = useSelector((state) => state.shop)
  const [blackList, setBlackList] = useState()

  useEffect(() => {
    setBlackList(selectedShop?.allowedAccount)
  }, [modal])

  const handleOnClick = (data) => {
    const newBlackList = blackList.filter((item) => item !== data)
    setBlackList(newBlackList)
  }

  const handleWhenSaveBlackList = async () => {
    const countBlackList = selectedShop?.allowedAccount?.length
    if (blackList?.length !== countBlackList) {
      try {
        const data = {
          privacyMode: privacyModeActive,
          allowedAccount: blackList,
        }
        await virtualShopApi.updateShop(data, selectedShop?.id)
        dispatch(
          selectShopAndSelectShopDetail({
            ...selectedShop,
            allowedAccount: blackList,
          })
        )

        toggle()
        addToast('Update successfully!', { appearance: 'success', autoDismiss: true })
      } catch (e) {
        addToast(`${e.msgResp}`, { appearance: 'error', autoDismiss: true })
      }
    } else {
      toggle()
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>White List</ModalHeader>
      <ModalBody>
        <div className='container black__list'>
          <div className='black__list-box'>
            {blackList?.map((item, i) => (
              <div key={i} className='black__list-item' onClick={() => handleOnClick(item)}>
                <span>{item}</span>
                <img src={cancelImageIcon} alt='' className='black__list-item-btn-clear' />
              </div>
            ))}
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col-6 m-auto'>
            <button className='btn btn--org btn--org02' onClick={handleWhenSaveBlackList}>
              Save
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalShowBlackList
