/* eslint-disable no-useless-escape */
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import { getDataShopDetail } from '_utils/localData'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody } from 'reactstrap'

function validURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return !!pattern.test(str)
}

function isLazadaURL(url) {
  return url.includes('lazada.vn') || url.includes('lazada.com')
}


const ExternalLinkModal = (props) => {
  const { modal, toggle, value, setValue } = props
  const shop = getDataShopDetail()
  const { addToast } = useToasts()
  const [nameExternalLink, setNameExternalLink] = useState('')
  const [externalLink, setExternalLink] = useState('')
  const [linkError, setLinkError] = useState('')
  const dispatch = useDispatch()

  const handleAddLinkExternal = () => {
    if (nameExternalLink !== '' && externalLink !== '') {
      if (!isLazadaURL(externalLink)) {
        setLinkError('The URL must be a Lazada URL')
        return
      }
      const externalLinkObj = {
        name: nameExternalLink,
        value: externalLink,
      }
      value.push(externalLinkObj)
      setValue(value)

      dispatch(selectShopAndSelectShopDetail({ ...shop, externalLink: value }))
      toggle(!modal)
    } else {
      addToast('Invalid name and link url', { appearance: 'error', autoDismiss: true })
    }
  }

  const handleOnChange = (e) => {
    setExternalLink(e.target.value)
    if (validURL(e.target.value)) {
      setLinkError('')
    } else if (e.target.value === '') {
      setLinkError('')
    } else {
      setLinkError('Invalid external link')
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle} centered className='add-external-link-modal'>
      <ModalBody>
        <div className='container p-0'>
          <h5 className='text-center text-orange'>Add external Link</h5>
          <div className='form-group row'>
            <p className='c-txt-org p-0 mb-1'>Field Name:</p>
            <input
              className='form-control'
              value={nameExternalLink}
              onChange={(e) => setNameExternalLink(e.target.value)}
            />
          </div>
          <div className='form-group row'>
            <p className='c-txt-org p-0 mb-1'>External Link:</p>
            <input
              className='form-control'
              value={externalLink}
              onChange={handleOnChange}
            />
            <small className='errorMessage text-danger ps-0'>{linkError}</small>
          </div>
          <div className='row mt-3'>
            <div className='col-12 m-auto'>
              <button
                className='btn btn-submit mx-0 w-100'
                onClick={handleAddLinkExternal}
                disabled={!!linkError}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ExternalLinkModal
