import subscriptionApi from '_api/subscription'
import LoadingOverlay from '_components/LoadingOverlay'
import ModalNotifySuccessSubscription from '_components/ModalNotifySuccessSubscription'
import Pagination from '_components/Pagination'
import { usePagination } from '_hooks/usePagination'
import {
  fetchListInvoiceLazada,
  handleCancelLazada,
  handleRemoveLazada,
} from '_redux/modules/lazada'
import { setIsSubmittedPaymentRequest } from '_redux/modules/package'
import {
  fetchListInvoiceSubscription,
  handleRemoveSubscription,
  setCurrentPackage,
} from '_redux/modules/subscription'
import { PACKAGE_LAZADA, PACKAGE_SUBSCRIPTION } from '_utils/constant'
import { clearParamUrl, scrollToTop } from '_utils/function'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'
import ListInvoiceLazada from './components/ListInvoiceLazada'
import ListInvoiceSubscription from './components/ListInvoiceSubscription'
import SelectTabInvoice from './components/SelectTabInvoice'

import './style.scss'

const PageSize = 5

const tableHeaders = ['#', 'Name', 'Status', 'Payment Method', 'Info', 'Action']

const PaymentHistory = () => {
  const dispatch = useDispatch()
  const popoverRef = useRef(null)
  const { addToast } = useToasts()

  const [currentPage, setCurrentPage] = useState(1)
  const [popoverOpen, setPopoverOpen] = useState({})
  const [loading, setLoading] = useState(true)
  const [tabInvoice, setTabInvoice] = useState(PACKAGE_SUBSCRIPTION)
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false)

  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const reference = params.get('reference')
  const status = params.get('status')

  const { listInvoiceLazada } = useSelector((state) => state.lazada)
  const { listInvoiceSubscription } = useSelector((state) => state.subscription)

  const paginationRange = usePagination({
    currentPage,
    totalCount: listInvoiceSubscription.length,
    siblingCount: 1,
    pageSize: PageSize,
  })

  useEffect(() => {
    const fetchList = async () => {
      await Promise.all([
        dispatch(fetchListInvoiceLazada()),
        dispatch(fetchListInvoiceSubscription()),
      ])

      setLoading(false)
    }
    fetchList()
    scrollToTop()
  }, [])

  useEffect(() => {
    const listStatusValid = ['completed', 'active']
    if (reference && listStatusValid.includes(status) && listInvoiceSubscription?.length) {
      setIsOpenModalSuccess(true)
    } else {
      setIsOpenModalSuccess(false)
    }
  }, [reference, status, JSON.stringify(listInvoiceSubscription)])

  const toggleModalSuccess = () => {
    setIsOpenModalSuccess(!isOpenModalSuccess)
  }

  const handleClickOutside = useCallback(
    (event) => {
      const { current: popover } = popoverRef
      const isPopoverButton = Object.values(popoverOpen).some(
        (item) => event.target.id === `popover-${item.id}`
      )

      if (popover && !popover.contains(event.target) && !isPopoverButton) {
        setPopoverOpen((prevState) => {
          const updatedState = { ...prevState }
          Object.keys(updatedState).forEach((key) => {
            updatedState[key] = false
          })
          return updatedState
        })
      }
    },
    [popoverRef, popoverOpen]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const togglePopover = (id) => {
    setPopoverOpen((prevState) => ({
      ...Object.keys(prevState).reduce((result, key) => {
        result[key] = false
        return result
      }, {}),
      [id]: !prevState[id],
    }))
  }

  const currentSubscriptionInvoices = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize

    return listInvoiceSubscription?.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, JSON.stringify(listInvoiceSubscription)])

  const currentLazadaInvoices = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize

    return listInvoiceLazada?.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, JSON.stringify(listInvoiceLazada)])

  const onPaymentHistory = async (item) => {
    try {
      const { msgResp } = await subscriptionApi.checkBeforePaymentWithHitpay(item.id)
      if (msgResp) {
        setTimeout(() => {
          window.open(msgResp, '_blank')
        })
      }
    } catch (err) {
      addToast(err.message || err.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const onRemoveSubscription = (item) => {
    Swal.fire({
      title: 'Are you sure to remove?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await dispatch(handleRemoveSubscription(item.id))
          dispatch(setIsSubmittedPaymentRequest(false))
          dispatch(setCurrentPackage(null))
          Swal.fire('Removed!', 'Your package has been removed.', 'success')
        } catch (error) {
          Swal.fire('Error!', error.message || error.msgResp, 'error')
        }
      }
    })
  }

  const onCancelLazada = (item) => {
    Swal.fire({
      title: 'Are you sure to cancel?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true)

          await dispatch(handleCancelLazada(item.id))
          Swal.fire('Canceled!', 'Your package has been canceled.', 'success')
        } catch (error) {
          Swal.fire('Error!', error.message || error.msgResp, 'error')
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const onRemoveLazada = (item) => {
    Swal.fire({
      title: 'Are you sure to remove?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await dispatch(handleRemoveLazada(item.id))
          Swal.fire('Removed!', 'Your package has been removed.', 'success')
        } catch (error) {
          Swal.fire('Error!', error.message || error.msgResp, 'error')
        }
      }
    })
  }

  const checkEligibilityForHitpayPayment = async (item) => {
    try {
      const { msgResp } = await subscriptionApi.checkBeforePaymentWithHitpay(item.id)
      if (msgResp) {
        setTimeout(() => {
          window.open(msgResp, '_blank')
        })
      }
    } catch (err) {
      addToast(err.message || err.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const onToggleModal = () => {
    clearParamUrl({ history, location })
    toggleModalSuccess()
  }

  return (
    <div className={'container payment-history'} style={{ maxWidth: 720, marginTop: 0 }}>
      <h3 className='mb-3 text-center' style={{ marginTop: 50 }}>
        Payment History
      </h3>

      {loading ? (
        <LoadingOverlay style={{ marginTop: 100 }} />
      ) : (
        <div className={'border'}>
          <div className='p-3'>
            <SelectTabInvoice tabInvoice={tabInvoice} setTabInvoice={setTabInvoice} />
          </div>
          <div className='w-100'></div>
          <table className={`table table-striped f-14 ${paginationRange.length < 2 && 'mb-0'}`}>
            <thead>
              <tr>
                {tableHeaders.map((item, index) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody style={{ fontSize: 12 }}>
              {currentSubscriptionInvoices?.length && tabInvoice === PACKAGE_SUBSCRIPTION ? (
                <ListInvoiceSubscription
                  currentInvoices={currentSubscriptionInvoices}
                  onRemove={onRemoveSubscription}
                  checkEligibilityForHitpayPayment={checkEligibilityForHitpayPayment}
                  {...{
                    loading,
                    togglePopover,
                    popoverRef,
                    popoverOpen,
                    onPaymentHistory,
                    tableHeaders,
                  }}
                />
              ) : null}

              {currentLazadaInvoices?.length && tabInvoice === PACKAGE_LAZADA ? (
                <ListInvoiceLazada
                  currentInvoices={currentLazadaInvoices}
                  onRemove={onRemoveLazada}
                  onCancel={onCancelLazada}
                  {...{
                    loading,
                    togglePopover,
                    popoverRef,
                    popoverOpen,
                    onPaymentHistory,
                    tableHeaders,
                  }}
                />
              ) : null}
            </tbody>
          </table>
          {paginationRange.length >= 2 && <div className='w-100'></div>}
          <Pagination
            className='pagination-bar mx-auto'
            currentPage={currentPage}
            totalCount={listInvoiceSubscription.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}

      <ModalNotifySuccessSubscription
        isOpenModalSuccess={isOpenModalSuccess}
        toggleModalSuccess={onToggleModal}
      />

      <Pagination
        className='pagination-bar mx-auto'
        currentPage={currentPage}
        totalCount={listInvoiceSubscription.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  )
}

export default PaymentHistory
