import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

export default function ModalOrderComment({
  openOrderComment,
  toggleOpenModalComment,
  selectedOrder,
  ...args
}) {
  return (
    <Modal
      isOpen={!!openOrderComment}
      toggle={toggleOpenModalComment}
      className='modal-order-comment'
      centered
      {...args}
    >
      <ModalHeader className='border-0'>{selectedOrder?.uName}</ModalHeader>
      <ModalBody className='d-flex flex-column align-items-center pt-0'>
        <p>{selectedOrder?.comment}</p>
        <button className='btn-submit' type='button' onClick={toggleOpenModalComment}>
          OK
        </button>
      </ModalBody>
    </Modal>
  )
}
