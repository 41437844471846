import React from 'react'
import { MdFormatListBulletedAdd } from 'react-icons/md'

export default function ButtonAddDeliveryZones({ onClick, ...props }) {
  return (
    <button
      className='btn btn-orange-outline w-auto rounded d-flex align-items-center'
      onClick={onClick}
      {...props}
    >
      <MdFormatListBulletedAdd size={20} className='me-1' />
      <span>Add zones to Co-Bee</span>
    </button>
  )
}
