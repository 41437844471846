/* eslint-disable no-confusing-arrow */
import ButtonWhatsApp from '_components/ButtonWhatsApp'
import LoadingOverlay from '_components/LoadingOverlay'
import { CollapseContext } from '_contexts/CollapseMenuContext'
import { getEventInShop } from '_redux/modules/event'
import { fetchListInvoiceLazada, getCurrentLazadaPackage } from '_redux/modules/lazada'
import {
  clearMyShops,
  fetchMyShops,
  selectShopAndSelectShopDetail,
  setListShopActive,
  setListShopInactive,
} from '_redux/modules/shop'
import { fetchListInvoiceSubscription } from '_redux/modules/subscription'
import { fetchUserInfo, getNotifications } from '_redux/modules/user'
import {
  PACKAGE_SUBSCRIPTION_FOUNDER,
  SUBSCRIPTION_STATUS_APPROVED,
  TURN_OFF,
  TURN_ON,
} from '_utils/constant'
import { getDataShopDetail, getEventsByShop, getUserInfo } from '_utils/localData'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, useRouteMatch } from 'react-router-dom'
import { Route, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Sidebar from './components/Sidebar'
import { genderRoutes } from './routes'

function MainDigistallDesktop() {
  const userInfo = getUserInfo()
  const dispatch = useDispatch()
  const history = useHistory()

  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const { isCollapse } = useContext(CollapseContext)

  const { currentPackage } = useSelector((state) => state.subscription)
  const { myShops, listShopActive, listShopInactive, selectedShop } = useSelector(
    (state) => state.shop
  )

  const [loading, setLoading] = useState(false)
  const [listRoute, setListRoute] = useState([])

  const eventsByShop = getEventsByShop()

  const shopSlugUrl = pathname
    .split('/')
    .find(
      (item) =>
        item &&
        ![
          'admin-console',
          'setting',
          'general',
          'my-shops',
          'account',
          'change-password',
          'update',
          'profile',
        ].includes(item)
    )

  const isMatchShopSlug = userInfo?.shopLinks?.some((link) => link.includes(shopSlugUrl))
  const pathAccount = pathname.includes('account')

  useEffect(() => {
    if (!isMatchShopSlug && !pathAccount) {
      history.push('/virtual-shop/list')
    }
  }, [isMatchShopSlug, history])

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      setLoading(true)

      await Promise.all([
        dispatch(fetchUserInfo()), // trick pass switch between shops when changing shopName, improve later
        dispatch(fetchMyShops()),
        dispatch(fetchListInvoiceLazada()),
        dispatch(fetchListInvoiceSubscription()),
        dispatch(getNotifications),
        dispatch(getCurrentLazadaPackage()),
      ])

      if (isMounted) {
        setLoading(false)
      }
    }

    if (userInfo?.id) {
      fetchData()
    }

    return () => {
      isMounted = false
      dispatch(clearMyShops())
    }
  }, [JSON.stringify(userInfo)])

  useEffect(() => {
    const shopDetail = getDataShopDetail()
    const shopSlugLocalStorage = shopDetail?.shopSlug
    const hasShops = myShops.length > 0

    function findShopBySlug(slug) {
      return myShops.find((shop) => shop.shopSlug === slug)
    }

    function handleSelectShop(shop) {
      if (!shop) return

      dispatch(selectShopAndSelectShopDetail(shop))
    }

    function updateShopsList() {
      const newListShopActive = myShops.filter((shop) => shop.active === TURN_ON)
      dispatch(setListShopInactive(myShops.filter((shop) => shop.active === TURN_OFF)))
      dispatch(setListShopActive(newListShopActive))
    }

    if (!shopSlugUrl && shopSlugLocalStorage && hasShops) {
      const shopSelectedBySlug = findShopBySlug(shopSlugLocalStorage)
      handleSelectShop(shopSelectedBySlug)
    } else if (shopSlugUrl && !shopSlugLocalStorage && hasShops) {
      const shopSelectedBySlug = findShopBySlug(shopSlugUrl)
      handleSelectShop(shopSelectedBySlug)
    } else if (!shopSlugUrl && !shopSlugLocalStorage && hasShops) {
      const shopSelectedBySlug = myShops.find((shop) => shop.active === TURN_ON)
      handleSelectShop(shopSelectedBySlug)
    } else if (shopSlugUrl && shopSlugLocalStorage && hasShops) {
      const shopSelectedBySlug = findShopBySlug(shopSlugUrl)
      handleSelectShop(shopSelectedBySlug)
    }

    updateShopsList()
  }, [JSON.stringify(myShops), shopSlugUrl, getDataShopDetail()?.shopSlug, dispatch])

  useEffect(() => {
    if (selectedShop?.shopSlug) {
      setListRoute(genderRoutes(path, selectedShop.shopSlug))
    }
  }, [JSON.stringify(genderRoutes(path, selectedShop?.shopSlug)), dispatch])

  useEffect(() => {
    if (selectedShop?.id && !eventsByShop) {
      dispatch(getEventInShop(selectedShop?.id))
    }
  }, [selectedShop?.id, JSON.stringify(eventsByShop)])

  return (
    <div className='container-fluid' style={{ minWidth: '1200px' }}>
      <div className='row'>
        <div className={`${isCollapse ? 'col-1' : 'col-2'} position-relative border-end pe-0`}>
          <Sidebar
            listShopActive={listShopActive}
            listShopInactive={listShopInactive}
            loading={loading}
          />
        </div>
        <div className={`${isCollapse ? 'col-11' : 'col-10'} mx-auto px-0`}>
          {loading ? (
            <LoadingOverlay />
          ) : (
            <Switch>
              {listRoute.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                />
              ))}
            </Switch>
          )}
          {currentPackage?.subscriptionPackageType === PACKAGE_SUBSCRIPTION_FOUNDER &&
            currentPackage?.status === SUBSCRIPTION_STATUS_APPROVED && (
              <ButtonWhatsApp
                className={'btn-whatsapp'}
                style={{
                  position: 'fixed',
                  bottom: '1rem',
                  right: '1rem',
                  zIndex: 1000,
                }}
              />
            )}
        </div>
      </div>
    </div>
  )
}

export default MainDigistallDesktop
