import ButtonAdminConsole from '_components/ButtonAdminConsole'
import ProtectedRoute from '_components/ProtectedRoute'
import UserPage from '_pages/User'
import VirtualShop from '_pages/VirtualShop'
import ShopDetail from '_pages/VirtualShop/ShopDetail'
import { getUserInfo, getUserToken } from '_utils/localData'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import { ButtonToMyPurchase } from '_components/ButtonToMyPurchase'
import useLogout from '_hooks/useLogout'
import { useWindowSize } from '_hooks/useWindowSize'
import { getCurrentLazadaPackage } from '_redux/modules/lazada'
import { readNotification } from '_redux/modules/notification'
import { clearCart, clearOrderDetailsByRef } from '_redux/modules/order'
import { fetchMyShops } from '_redux/modules/shop'
import { AiFillSetting, AiFillShop, AiOutlineHistory } from 'react-icons/ai'
import { BiLogOut, BiSolidPurchaseTag } from 'react-icons/bi'
import { MdAccountCircle } from 'react-icons/md'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'

function Main() {
  const dispatch = useDispatch()
  const userToken = getUserToken()
  const userInfo = getUserInfo()
  const history = useHistory()
  const location = useLocation()

  const { addToast } = useToasts()
  const { width } = useWindowSize()
  const { cart, orderDetailsByRef } = useSelector((state) => state.order)
  const { selectedShop, myShops } = useSelector((state) => state.shop)
  const { listNotification } = useSelector((state) => state.notification)

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const selectedTabProduct = localStorage.getItem('selectedTabProduct')

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([dispatch(fetchMyShops()), dispatch(getCurrentLazadaPackage())])
    }

    if (userToken) {
      fetchData()
    }
  }, [userToken])

  useEffect(() => {
    if (selectedTabProduct) {
      localStorage.removeItem('selectedTabProduct')
    }
  }, [selectedTabProduct])

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const isShopSlug = (url) => {
    const predefinedPaths = ['/user', '/virtual-shop'] // Add other predefined paths here
    const isPredefinedPath = predefinedPaths.some((path) => url.startsWith(path))
    return !url.includes('/', 1) && !isPredefinedPath
  }

  async function handleReadNotification() {
    if (listNotification.some((item) => item.new)) {
      await dispatch(readNotification())
    }
  }

  const onClearCart = () => {
    dispatch(clearOrderDetailsByRef())
    dispatch(clearCart())
  }

  function onConfirmExitPageHasOrderDetailsByRef(path) {
    Swal.fire({
      title: 'Are you sure you want to leave this page?',
      text: 'Currently, you have an order in progress. Do you want to remove the order information?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        onClearCart()
        if (path === '/user/profile') {
          await handleReadNotification()
        }
        Swal.fire('Removed!', 'Your order has been removed.', 'success').then(() => {
          history.push(path)
        })
      }
    })
  }

  const onRedirectPage = (path) => {
    const fromMyPurchasePath = location.state?.from === 'my-purchase'
    const orderSuccess = localStorage.getItem('ORDER_SUCCESS') === 'true'

    if (fromMyPurchasePath) {
      onClearCart()
      if (path === '/user/profile') {
        handleReadNotification()
      }
      history.push(path)
    } else if (
      !fromMyPurchasePath &&
      !orderSuccess &&
      (cart?.orderList?.length > 0 || orderDetailsByRef?.ref)
    ) {
      onConfirmExitPageHasOrderDetailsByRef(path)
    } else {
      history.push(path)
    }

    localStorage.removeItem('ORDER_SUCCESS')
  }

  const redirectAdminConsole = () => {
    if (width < 768) {
      addToast('This feature only use on desktop', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    const userMatchShop = userInfo?.shopLinks?.some((shop) => {
      const arrShopLink = shop?.split('/')
      const shopSlugItem = arrShopLink[arrShopLink?.length - 1]

      return shopSlugItem === selectedShop?.shopSlug
    })

    const myFirstShop = myShops.length > 0 ? myShops[0] : null

    const shopToUse = userMatchShop ? selectedShop : myFirstShop
    const result = `/admin-console/${shopToUse?.shopSlug}/setting/general`

    if (cart?.orderList?.length > 0 || orderDetailsByRef?.ref) {
      Swal.fire({
        title: 'Are you sure you want to leave this page?',
        text: 'Currently, you have an order in progress. Do you want to remove the order information?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15cdca',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (_result) => {
        if (_result.isConfirmed) {
          onClearCart()
          Swal.fire('Removed!', 'Your order has been removed.', 'success').then(() => {
            history.push(result)
          })
        }
      })
    }

    localStorage.setItem('isAdminConsole', true)
    return result
  }

  const preDefinedPaths = [
    '/virtual-shop/sign-in',
    '/virtual-shop/sign-up',
    '/virtual-shop/create',
    '/virtual-shop/add-items',
    '/virtual-shop/confirm-shop',
    '/virtual-shop/checkout',
  ]

  const shouldShowAdminConsole =
    myShops.length > 0 &&
    userInfo?.shopLinks?.length > 0 &&
    !isShopSlug(window.location.pathname) &&
    !preDefinedPaths.includes(window.location.pathname)
  const shouldShowMyPurchase =
    !preDefinedPaths.includes(window.location.pathname) && !isShopSlug(window.location.pathname)
  // const shouldShowCart =
  //   cart?.orderList?.length > 0 && !preDefinedPaths.includes(window.location.pathname)

  const myPurchaseTransform = shouldShowAdminConsole ? 'translateY(-50px)' : 'translateY(25px)'

  return (
    <React.Fragment>
      {/* Header */}
      {userToken ? (
        <div className='container d-flex align-items-center justify-content-end fw-bold f-14'>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle
              caret
              className='bg-transparent border-0 shadow-none d-inline-block pe-0'
              style={{ background: 'transparent', height: 55 }}
            >
              <div className='position-relative'>
                <div className='avatar-top__img'>
                  <img
                    id='userAvatar'
                    src={userInfo?.photoUrl || process.env.REACT_APP_AVATAR_URL + userInfo?.name}
                    alt=''
                  />
                </div>
                {listNotification.some((item) => item.new) && (
                  <span
                    className='position-absolute start-100 bg-danger border border-light rounded-circle'
                    style={{
                      width: '15px',
                      height: '15px',
                      top: 3,
                      transform: 'translate(-80%, -50%)',
                    }}
                  >
                    <span className='visually-hidden'>New alerts</span>
                  </span>
                )}
              </div>
            </DropdownToggle>
            <DropdownMenu className='dropdown-menu-avatar'>
              <Link
                to='#'
                onClick={() => {
                  onRedirectPage('/user/profile')
                }}
                className='text-secondary w-100 h-100 d-inline-block'
              >
                <DropdownItem className='px-3 py-2'>
                  <MdAccountCircle className='me-2' />
                  <span>Account</span>
                </DropdownItem>
              </Link>
              <Link to='#' onClick={() => onRedirectPage('/virtual-shop/list')}>
                <DropdownItem className='px-3 py-2'>
                  <AiFillShop className='me-2' />
                  <span>Shop List</span>
                </DropdownItem>
              </Link>
              <Link to='#' onClick={() => onRedirectPage('/virtual-shop/my-purchase')}>
                <DropdownItem className='px-3 py-2'>
                  <BiSolidPurchaseTag className='me-2' />
                  <span>My Purchase</span>
                </DropdownItem>
              </Link>
              <Link to='#' onClick={() => onRedirectPage('/virtual-shop/payment-history')}>
                <DropdownItem className='px-3 py-2'>
                  <AiOutlineHistory className='me-2' />
                  <span>Payment History</span>
                </DropdownItem>
              </Link>
              {userInfo?.shopLinks?.length > 0 ? (
                <Link to='#' onClick={() => onRedirectPage(redirectAdminConsole())}>
                  <DropdownItem className='px-3 py-2'>
                    <AiFillSetting className='me-2' />
                    <span>Admin Console</span>
                  </DropdownItem>
                </Link>
              ) : null}
              <DropdownItem divider className='my-0' />
              <div onClick={useLogout}>
                <DropdownItem className='px-3 py-2'>
                  <BiLogOut className='me-2' />
                  <span>Log out</span>
                </DropdownItem>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      ) : null}

      <section className='section-content--top main-screen' id='checkHeight'>
        <Switch>
          <ProtectedRoute path='/user/:info' exact component={UserPage} />
          <Route path='/:shopSlug' exact component={ShopDetail} />
          <Route path='/virtual-shop' component={VirtualShop} />
        </Switch>
      </section>

      {shouldShowAdminConsole ? <ButtonAdminConsole /> : null}

      {/* {shouldShowCart ? <ButtonCart /> : null} */}

      {shouldShowMyPurchase ? (
        <ButtonToMyPurchase
          style={{
            backgroundColor: '#2ecc71',
            transform: myPurchaseTransform,
          }}
        />
      ) : null}
    </React.Fragment>
  )
}

export default Main
