import LazadaLogo from '_images/lazada-logo.png'
import {
  exportProductToLazada,
  selectAndToggleProductLazadaModal,
  selectTab,
} from '_redux/modules/product'
import { PLATFORM_LAZADA } from '_utils/constant'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { ButtonGroup, Modal, ModalBody, ModalHeader } from 'reactstrap'

function ModalShare({ isOpenModalShare, toggleOpenModalShare, ...args }) {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [rSelected, setRSelected] = useState(PLATFORM_LAZADA)
  const { productLazadaSelected } = useSelector((state) => state.product)

  const renderTextPlatform = () => 'Lazada'

  const getImageSize = (url) =>
    new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        resolve({ width: img.width, height: img.height })
      }
      img.onerror = (error) => {
        reject(error)
      }
      img.src = url
    })

  const onExportProductToLazada = async (product) => {
    if (!product?.photoUrls || product?.photoUrls.length === 0) {
      toggleOpenModalShare()
      return addToast('Please upload product image', { appearance: 'error', autoDismiss: true })
    }

    const imageUrl = Array.isArray(product.photoUrls) ? product.photoUrls[0] : product.photoUrls

    try {
      const size = await getImageSize(imageUrl)
      if (size.width < 330 || size.height < 330) {
        toggleOpenModalShare()
        return addToast('Product image must be at least 330x330 pixels', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    } catch (error) {
      toggleOpenModalShare()
      return addToast('Invalid image URL', { appearance: 'error', autoDismiss: true })
    }

    try {
      dispatch(selectAndToggleProductLazadaModal(product, 'name'))
      dispatch(exportProductToLazada(product))
      dispatch(selectTab(PLATFORM_LAZADA))
    } catch (error) {
      return addToast(error.message, { appearance: 'error', autoDismiss: true })
    } finally {
      toggleOpenModalShare()
    }
  }

  return (
    <Modal
      isOpen={isOpenModalShare}
      toggle={toggleOpenModalShare}
      className='modal-share-product'
      {...args}
    >
      <ModalHeader toggle={toggleOpenModalShare}>Share to platform</ModalHeader>
      <ModalBody>
        <p>Chosen: {renderTextPlatform()}</p>
        <ButtonGroup className='column-gap-2'>
          <button
            className={`icon-platform ${rSelected === PLATFORM_LAZADA ? 'active' : ''}`}
            onClick={() => setRSelected(PLATFORM_LAZADA)}
          >
            <img src={LazadaLogo} alt='lazada-logo' />
          </button>
          {/* <button
            className={`icon-platform ${rSelected === PLATFORM_WIX ? 'active' : ''}`}
            onClick={() => setRSelected(PLATFORM_WIX)}
          >
            <img src={WixLogo} alt='wix-logo' />
          </button> */}
        </ButtonGroup>

        <button
          className='btn btn-submit shadow-none w-auto rounded-pill mt-3 ms-auto'
          onClick={() => onExportProductToLazada(productLazadaSelected)}
        >
          Ok
        </button>
      </ModalBody>
    </Modal>
  )
}

export default ModalShare
