import classNames from 'classnames'
import React from 'react'

function OpeningHours({ datesOfWeek, handleCheckHoliday, index, checkedHoliday, handleClickDate }) {
  return (
    <div className='row opening-hours'>
      <div className='col-12 d-flex align-items-center mb-1'>
        <span>Opening Hours</span>
        <span className='date-open ms-auto position-relative'></span>
        <span className='date-close position-relative'></span>
      </div>
      <div className='col-12 d-flex align-items-center'>
        {datesOfWeek.map((date, i) => (
          <label
            htmlFor={`date-pickup-${index}-${i}`}
            key={i}
            className={classNames(
              'date-of-week cursor-pointer flex-fill d-flex align-items-center justify-content-center',
              i !== 0 && 'border-left-0'
            )}
            title={date.name?.name}
            style={date.isClose ? { backgroundColor: 'gray' } : { backgroundColor: 'lightblue' }}
          >
            <input
              type='checkbox'
              id={`date-pickup-${index}-${i}`}
              checked={date.isClose}
              onChange={(e) => handleClickDate(e, i)}
              hidden
            />
            <span>{date.title[0]}</span>
          </label>
        ))}
      </div>
      <div className='col-12'>
        <label
          htmlFor={`holiday-pickup-${index}`}
          className='float-end d-flex align-items-center cursor-pointer'
        >
          <input
            type='checkbox'
            id={`holiday-pickup-${index}`}
            checked={checkedHoliday}
            className='custom-checkbox-shop'
            onChange={(e) => handleCheckHoliday(e)}
          />
          <span>We are close during Public Holiday</span>
        </label>
      </div>
    </div>
  )
}

export default OpeningHours
