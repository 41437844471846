/* eslint-disable no-confusing-arrow */
import eventApi from '_api/event'
import orderEventApi from '_api/orderEvent'

const FETCH_ORDER_EVENT_BY_ID = 'FETCH_ORDER_EVENT_BY_ID'
const UPDATE_ORDER_EVENT_STATUS = 'UPDATE_ORDER_EVENT_STATUS'
const CLEAR_ORDER_EVENT_DETAIL = 'CLEAR_ORDER_EVENT_DETAIL'
const UPDATE_STATUS_ORDER_COMBINED_PAYMENT = 'UPDATE_STATUS_ORDER_COMBINED_PAYMENT'
const CLEAR_MY_ORDER_EVENT = 'CLEAR_MY_ORDER_EVENT'
const CLEAR_EVENT_ORDER = 'CLEAR_EVENT_ORDER'
const GET_COMBINED_PAYMENT = 'GET_COMBINED_PAYMENT'
const GET_EVENT_AND_ORDER_BUY_EID = 'GET_EVENT_AND_ORDER_BUY_EID'
const SET_LOADING_EVENT_AND_ORDER_BY_ID = 'SET_LOADING_EVENT_AND_ORDER_BY_ID'
const GET_EVENT_AND_ORDER_BUY_EID_ERROR = 'GET_EVENT_AND_ORDER_BUY_EID_ERROR'
const SET_LOADING_LIST_COMBINED_PAYMENT = 'SET_LOADING_LIST_COMBINED_PAYMENT'
const GET_COMBINED_PAYMENT_ERROR = 'GET_COMBINED_PAYMENT_ERROR'
const CLEAR_ORDER_DETAIL = 'CLEAR_ORDER_DETAIL'

const initialState = {
  listOrderEvent: [],
  orderEventDetail: [],
  eventAndOrderById: [],
  listCombinedPayments: [],
  loadingEventAndOrderById: true,
  errorEventAndOrderById: null,
  loadingListCombinedPayment: true,
  errorListCombinedPayment: null,
  callNewAPIListOrder: false,
  errorOrderEventDetail: null,
}

export const clearDataPurchaseDetail = () => (dispatch) => {
  dispatch({
    type: CLEAR_ORDER_DETAIL,
    payload: { orderEventDetail: [] },
  })
}

export const getListEventOrder = (listEid) => async (dispatch, getState) => {
  const { orderEvent } = getState()
  const { eventAndOrderById = [] } = orderEvent
  const listEventAndOrder = []

  try {
    for (const _eid of listEid) {
      const exitEventOrder = eventAndOrderById.findIndex((item) => item.id === _eid)
      const exitListEO = listEventAndOrder.findIndex((item) => item.id === _eid)

      if (exitEventOrder === -1 && exitListEO === -1) {
        const { msgResp: _event } = await eventApi.getEventById(_eid)
        const { msgResp: _orderByEid } = await orderEventApi.getListOrdersByEventId(_eid)

        const newEventAndOrderById = {
          ..._event,
          orders: _orderByEid,
        }
        listEventAndOrder.push(newEventAndOrderById)
      }
    }

    if (listEventAndOrder.length) {
      dispatch({
        type: GET_EVENT_AND_ORDER_BUY_EID,
        payload: {
          eventAndOrderById: [...eventAndOrderById, ...listEventAndOrder],
          callNewAPIListOrder: false,
          errorOrderEventDetail: null,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: GET_EVENT_AND_ORDER_BUY_EID_ERROR,
      payload: {
        errorEventAndOrderById: error,
      },
    })
  } finally {
    dispatch({
      type: SET_LOADING_EVENT_AND_ORDER_BY_ID,
      payload: { loadingEventAndOrderById: false },
    })
  }
}

export const getListCombinedPayment = (listCombinedPaymentRef) => async (dispatch, getState) => {
  const { orderEvent } = getState()
  const { listCombinedPayments = [] } = orderEvent

  const newListCombinedPayment = []
  const listEid = []

  try {
    for (const combinedRef of listCombinedPaymentRef) {
      let orderCombinedPayment = []
      const exitCombinedPayment = listCombinedPayments.findIndex(
        (item) => item.combinedPaymentRef === combinedRef
      )

      const exitList = newListCombinedPayment.findIndex(
        (item) => item.combinedPaymentRef === combinedRef
      )

      if (exitCombinedPayment === -1 && exitList === -1) {
        const { msgResp } = await orderEventApi.listInvoicesCombinedPayment(combinedRef)
        const { listShopEventOrder, listShopEventDeliveryBooking } = msgResp

        orderCombinedPayment = listShopEventOrder.map(({ ref, eid, eTitle }) => ({
          ref,
          eid,
          eTitle,
        }))

        const uniqueOrderCombinedPayment = []

        orderCombinedPayment.map((x) =>
          uniqueOrderCombinedPayment.filter((a) => a.ref === x.ref && a.eid === x.eid).length > 0
            ? null
            : uniqueOrderCombinedPayment.push(x)
        )

        orderCombinedPayment.map((x) =>
          listEid.filter((a) => a === x.eid).length > 0 ? null : listEid.push(x.eid)
        )

        newListCombinedPayment.push({
          combinedPaymentRef: combinedRef,
          orderCombinedPayment: uniqueOrderCombinedPayment,
          deliveryCombinedPayment: listShopEventDeliveryBooking,
        })
      }
    }

    if (newListCombinedPayment.length) {
      dispatch({
        type: GET_COMBINED_PAYMENT,
        payload: {
          listCombinedPayments: [...listCombinedPayments, ...newListCombinedPayment],
        },
      })
      await dispatch(getListEventOrder(listEid))
    }
  } catch (error) {
    dispatch({
      type: GET_COMBINED_PAYMENT_ERROR,
      payload: {
        errorListCombinedPayment: error,
      },
    })
  } finally {
    dispatch({
      type: SET_LOADING_LIST_COMBINED_PAYMENT,
      payload: { loadingListCombinedPayment: false },
    })
  }
}

export const clearEventAndOrder = () => async (dispatch) => {
  dispatch({
    type: CLEAR_EVENT_ORDER,
    payload: { eventAndOrderById: [] },
  })
}

export const clearMyOrderEvent = () => (dispatch) => {
  dispatch({
    type: CLEAR_MY_ORDER_EVENT,
    payload: { myOrdersEvent: [] },
  })
}

export const clearDataPurchaseDetailByEid = (eid) => async (dispatch, getState) => {
  try {
    const { orderEvent } = getState()
    const { orderEventDetail } = orderEvent
    const newOrderEventDetail = orderEventDetail.filter((item) => item.eid !== eid)
    dispatch({
      type: CLEAR_ORDER_EVENT_DETAIL,
      payload: { orderEventDetail: newOrderEventDetail },
    })
  } catch (error) {}
}

export const updateOrderCombinedPayment =
  (combinedPaymentRef, status, buyerStatus) => async (dispatch, getState) => {
    try {
      const {
        orderEvent: { listCombinedPayments = [], eventAndOrderById = [] },
      } = getState()

      const combinedPaymentIndex = listCombinedPayments.findIndex(
        (item) => item.combinedPaymentRef === combinedPaymentRef
      )

      if (combinedPaymentIndex !== -1) {
        const { orderCombinedPayment } = listCombinedPayments[combinedPaymentIndex]

        const updatedEventAndOrderById = eventAndOrderById.map((eventOrder) => {
          const updatedOrders = eventOrder.orders.map((order) => {
            const orderToUpdate = orderCombinedPayment.find(
              (element) => element.eid === eventOrder.id && element.ref === order.ref
            )

            if (orderToUpdate) {
              return {
                ...order,
                status,
                buyerStatus,
              }
            }

            return order
          })
          return {
            ...eventOrder,
            orders: updatedOrders,
          }
        })

        dispatch(clearDataPurchaseDetail())
        dispatch(clearMyOrderEvent())
        // dispatch(clearDeliveryDetail());
        // dispatch(clearPastPurchasedMember());
        dispatch({
          type: UPDATE_STATUS_ORDER_COMBINED_PAYMENT,
          payload: {
            eventAndOrderById: updatedEventAndOrderById,
            callNewAPIListOrder: true,
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

// const combinedPaymentRefFunc = (listOrdersEvent) => {
//   const listCombinedPaymentRef = []
//   listOrdersEvent.forEach((element) => {
//     const { combinedPaymentRef = '' } = element
//     if (!listCombinedPaymentRef.includes(combinedPaymentRef)) {
//       if (combinedPaymentRef) {
//         listCombinedPaymentRef.push(combinedPaymentRef)
//       }
//     }
//   })

//   return listCombinedPaymentRef
// }

export const getEventAndOrder = (eventId) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { eventAndOrderById = [], callNewAPIListOrder = false } = event
    const indexOrder = eventAndOrderById.findIndex((item) => item.id === eventId)
    if (indexOrder !== -1) {
      dispatch({
        type: GET_EVENT_AND_ORDER_BUY_EID,
        payload: {
          eventAndOrderById,
          errorGetEventAndOrder: null,
        },
      })
      if (!callNewAPIListOrder) {
        return
      }
    }
    let newEventAndOrderById = {}
    let eventAndOrderByIdReturn
    const { msgResp: _event } = await eventApi.getEventDetail({
      eid: eventId,
      isGetProducts: true,
      isGetBuyerNames: false,
      isGetDeliveryZones: true,
    })
    const { msgResp: _orderByEid } = await orderEventApi.getListOrdersByEventId(eventId)

    newEventAndOrderById = {
      ..._event,
      orders: _orderByEid,
    }

    const orderNo = new Set()
    const orderRef = new Set()
    if (_orderByEid && _orderByEid.length > 0) {
      for (let index = 0; index < _orderByEid.length; index++) {
        orderNo.add(_orderByEid[index].eOrderNo)
        orderRef.add(_orderByEid[index].ref)
      }
      if (orderNo.size !== orderRef.size || Math.max(...orderNo) !== orderRef.size) {
        orderEventApi.updateOrderNo(eventId).then((res) => {
          const { msgCode } = res
          if (msgCode && Number(msgCode) % 100 === 0) {
            orderEventApi.getListOrdersByEventId(eventId).then((res1) => {
              const { msgResp } = res1
              newEventAndOrderById = {
                ..._event,
                orders: msgResp,
              }
            })
          }
        })
      }
    }
    if (callNewAPIListOrder) {
      eventAndOrderById[indexOrder] = newEventAndOrderById
      eventAndOrderByIdReturn = eventAndOrderById
    } else {
      eventAndOrderByIdReturn = [...eventAndOrderById, newEventAndOrderById]
    }

    dispatch({
      type: GET_EVENT_AND_ORDER_BUY_EID,
      payload: {
        eventAndOrderById: eventAndOrderByIdReturn,
        callNewAPIListOrder: false,
        errorGetEventAndOrder: null,
      },
    })
  } catch (e) {
    dispatch({
      type: GET_EVENT_AND_ORDER_BUY_EID,
      payload: { errorGetEventAndOrder: e },
    })
  } finally {
    dispatch({
      type: SET_LOADING_EVENT_AND_ORDER_BY_ID,
      payload: { loadingEventAndOrderById: false },
    })
  }
}

export const updateOrderEventStatus =
  (eventId, ref, status, buyerStatus = null) =>
  async (dispatch, getState) => {
    try {
      const { orderEvent } = getState()
      const { eventAndOrderById } = orderEvent
      const newEventAndOrderById = eventAndOrderById
      const index = newEventAndOrderById.findIndex((item) => item.id === eventId)
      if (index !== -1) {
        const newOrder = newEventAndOrderById[index].orders

        for (let indexOrder = 0; indexOrder < newOrder.length; indexOrder++) {
          if (newOrder[indexOrder].ref === ref) {
            newOrder[indexOrder].status = status
            newOrder[indexOrder].buyerStatus = buyerStatus
          }
        }
        newEventAndOrderById[index] = {
          ...newEventAndOrderById[index],
          orders: newOrder,
        }
      }
      dispatch(clearDataPurchaseDetailByEid(eventId))
      dispatch(clearMyOrderEvent())
      // dispatch(clearPastPurchasedMember())
      dispatch({
        type: UPDATE_ORDER_EVENT_STATUS,
        payload: {
          eventAndOrderById: newEventAndOrderById,
          callNewAPIListOrder: true,
        },
      })
    } catch (error) {
      dispatch({
        type: UPDATE_ORDER_EVENT_STATUS,
        payload: {
          errorOrderEventDetail: error,
        },
      })
    }
  }

export const callNewListOrder = () => async (dispatch) => {
  dispatch({
    type: GET_EVENT_AND_ORDER_BUY_EID,
    payload: { callNewAPIListOrder: true },
  })
}

export default function orderEventReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMBINED_PAYMENT_ERROR:
    case SET_LOADING_LIST_COMBINED_PAYMENT:
    case GET_EVENT_AND_ORDER_BUY_EID_ERROR:
    case SET_LOADING_EVENT_AND_ORDER_BY_ID:
    case GET_EVENT_AND_ORDER_BUY_EID:
    case GET_COMBINED_PAYMENT:
    case CLEAR_EVENT_ORDER:
    case CLEAR_MY_ORDER_EVENT:
    case CLEAR_ORDER_DETAIL:
    case UPDATE_STATUS_ORDER_COMBINED_PAYMENT:
    case UPDATE_ORDER_EVENT_STATUS:
    case FETCH_ORDER_EVENT_BY_ID:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
