import axiosClient from './axiosClient'

const orderApi = {
  getListOrdersByHost: (sid, rangeDate) => {
    const url = '/list-shop-orders-by-host'
    return axiosClient.get(url, {
      params: {
        shopId: sid,
        start: rangeDate.startDate,
        end: rangeDate.endDate,
      },
    })
  },
  getListOrderByBuyer: () => {
    const url = '/list-shop-orders-by-buyer'
    return axiosClient.get(url)
  },
  updateBuyerStatus: (ref, data) => {
    const url = `/update-shop-order-buyer-status?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  updateOrderStatus: (ref, data) => {
    const url = `/update-shop-order-status?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  createDeliveryForOrder: (data) => {
    const url = '/create-shop-order-fulfillment'
    return axiosClient.post(url, data)
  },
  createOrderWithGiftRecipient: (data) => {
    const url = '/create-shop-order'
    return axiosClient.post(url, data)
  },
  updateShopOrderVisible: (ref, data) => {
    const url = `/update-shop-order-visible?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  getOrderDetailByRef: (ref) => {
    const url = `/get-shop-order-detail-by-ref?ref=${ref}`
    return axiosClient.get(url)
  },
  getListLazadaOrder: (shopId, startDate, endDate) => {
    if (!shopId || !startDate || !endDate) return new Error('Missing or invalid params')
    const url = `/get-list-lazada-order?shopId=${shopId}&startDate=${startDate}&endDate=${endDate}`
    return axiosClient.get(url)
  },
  deleteOrderByBuyerLogin: (ref) => {
    const url = `/delete-order-by-buyer?ref=${ref}`
    return axiosClient.post(url)
  },
  getListOrderByBuyerNoLogin: ({ uEmail = '', ref = '' }) => {
    const url = '/get-list-order-by-buyer-without-login'
    const params = {}

    if (uEmail) {
      params.uEmail = uEmail
    }

    if (ref) {
      params.ref = ref
    }

    return axiosClient.get(url, { params })
  },
  updateStatusOrderByBuyer: ({ ref, status, paymentScreenshot }) => {
    const url = '/update-status-order-by-buyer'
    return axiosClient.patch(url, {
      ref,
      status,
      paymentScreenshot,
    })
  },
  updateStatusOrderByBuyerNoLogin: ({ ref, status, uEmail, paymentScreenshot }) => {
    const url = '/update-status-order-by-buyer-without-login'
    return axiosClient.patch(url, {
      ref,
      status,
      uEmail,
      paymentScreenshot,
    })
  },
  getOrderDetailsByRefNoLogin: ({ ref, uEmail }) => {
    const url = '/get-order-detail-by-buyer-without-login'
    return axiosClient.get(url, {
      params: {
        ref,
        uEmail,
      },
    })
  },
  deleteOrderByBuyerNoLogin: ({ ref, uEmail }) => {
    const url = `/delete-order-by-buyer-without-login?ref=${ref}&uEmail=${uEmail}`
    return axiosClient.post(url)
  },
  updateShopOrderWithFulfillment: (data) => {
    const url = '/update-shop-order-with-fulfillment'
    return axiosClient.patch(url, data)
  },
  updatePaymentScreenshot: (data) => {
    const url = '/update-payment-screenshot'
    return axiosClient.patch(url, data)
  },
}

export default orderApi
