/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
import { DEFAULT_OPTION_SUB_ITEM, DEFAULT_SUB_ITEM } from '_utils/constant'
import { formatNumberToCurrencyHasDot } from '_utils/functions/formatter'
import React from 'react'
import { useFieldArray } from 'react-hook-form'
import { IoIosAdd } from 'react-icons/io'
import { useToasts } from 'react-toast-notifications'
import InputProduct from './InputProduct'

const SubItem = (props) => {
  const {
    control,
    register,
    nestIndex,
    productIndex,
    removeSubItem,
    insertSubItem,
    subItem,
    appendSubItem,
    watch,
    errors,
    setValue,
  } = props
  const {
    fields,
    remove: removeOption,
    append: appendOption,
  } = useFieldArray({
    control,
    name: `products.${productIndex}.subItems.${nestIndex}.list`,
  })
  const { addToast } = useToasts()

  const watchFieldArray = watch(`products.${productIndex}.subItems.${nestIndex}.list`)
  const controlledFields =
    watchFieldArray?.length > 0
      ? fields.map((field, _index) => ({
          ...field,
          ...watchFieldArray[_index],
        }))
      : []

  const watchSubItems = watch(`products.${productIndex}.subItems`)

  const handleChangeOptionPrice = (e, _productIndex, _nestIndex, _index) => {
    const inputText = e.target.value
    const formattedInput = formatNumberToCurrencyHasDot(inputText)

    setValue(
      `products.${_productIndex}.subItems.${_nestIndex}.list.${_index}.price`,
      formattedInput
    )
  }

  const onAddSubItem = () => {
    if (watchSubItems?.length === 2) {
      addToast('You can only add 2 sub items', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      appendSubItem(JSON.parse(JSON.stringify({ ...DEFAULT_SUB_ITEM })))
    }
  }

  const onCopySubItem = () => {
    if (watchSubItems?.length === 2) {
      addToast('You can only add 2 sub items', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      insertSubItem(parseInt(nestIndex + 1, 10), JSON.parse(JSON.stringify({ ...subItem })))
    }
  }

  return (
    <div
      className='sub-item c-form is-active mb-3'
      style={{
        marginLeft: '-0.75rem',
        marginRight: '-0.75rem',
      }}
    >
      <div className='sub-item-div'>
        <div className='row'>
          <div className='col-3 align-self-center col-index'>Sub item {nestIndex + 1}</div>
          <div className='col-9'>
            <InputProduct
              type='text'
              className={`form-control form-control-bg  ${
                errors?.products !== undefined &&
                Object.entries(errors?.products).length !== 0 &&
                errors?.products?.[productIndex]?.subItems?.[nestIndex]?.type &&
                'is-error-sub-item'
              }`}
              placeholder='Add Category'
              register={register(`products.${productIndex}.subItems.${nestIndex}.type`)}
              error={errors?.products?.[productIndex]?.subItems?.[nestIndex]?.type?.message}
            />
          </div>
        </div>
        {controlledFields?.map((_item, index) => (
          <div
            className={
              'row flex-nowrap sub-item-option align-items-end justify-content-between position-relative'
            }
            key={index}
          >
            <div className='col-auto'>
              <div className='mt-2 d-block'>{index + 1}.</div>
            </div>
            <div className='sub-item-info col-7'>
              <InputProduct
                type='text'
                className={`form-control mt-0 form-control-line pb-0 ${
                  errors?.products !== undefined &&
                  Object.entries(errors?.products).length !== 0 &&
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list !== undefined &&
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list[index]?.name &&
                  'is-error-sub-item'
                }`}
                placeholder='Name...'
                register={register(
                  `products.${productIndex}.subItems.${nestIndex}.list.${index}.name`
                )}
                error={
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list?.[index]?.name
                    ?.message
                }
              />
            </div>
            <div className='col-auto'>
              <span className='me-1 mt-2 d-block'>$</span>
            </div>
            <div className={'sub-item-info col-2'}>
              <InputProduct
                className={`form-control mt-0 form-control-line pb-0 ${
                  errors?.products !== undefined &&
                  Object.entries(errors?.products).length !== 0 &&
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list !== undefined &&
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list[index]?.price &&
                  'is-error-sub-item'
                }`}
                placeholder='Price...'
                type='text'
                step='0.01'
                register={register(
                  `products.${productIndex}.subItems.${nestIndex}.list.${index}.price`,
                  {
                    onChange: (e) => handleChangeOptionPrice(e, productIndex, nestIndex, index),
                  }
                )}
                error={
                  errors?.products?.[productIndex]?.subItems?.[nestIndex]?.list?.[index]?.price
                    ?.message
                }
              />
            </div>
            <div
              className='col-auto'
              style={{
                WebkitFilter: 'grayscale(100%)',
                filter: 'grayscale(100%)',
              }}
            >
              <a className='btn-remove mt-2' onClick={() => removeOption(index)} />
            </div>
          </div>
        ))}
        <div className='row mb-3 mt-4'>
          <div className='sub-item-info col-12'>
            <a
              className='btn btn-primary text-white w-auto fw-bold shadow-none float-end'
              onClick={() => appendOption({ ...DEFAULT_OPTION_SUB_ITEM })}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
              }}
            >
              <IoIosAdd size={18} /> Add Option
            </a>
          </div>
        </div>
      </div>
      <div className='sub-item-div sub-item-div-last border-top-0 pt-0 mt-0'>
        <div className='row mt-0'>
          <div className='col-6'>
            {/* <div className='slider-toogle d-flex align-items-center'>
              <label className='mb-0 mr-1 required-label'>Required</label>
              <label className='switch'>
                <input
                  type='checkbox'
                  {...register(`products.${productIndex}.subItems.${nestIndex}.required`)}
                />
                <span className='slider round'></span>
              </label>
            </div> */}
          </div>
          <div className='col-6'>
            <div className='btn-action-01'>
              <a className='btn-add-01' onClick={onAddSubItem} />
              <a className='btn-copy mx-2' onClick={onCopySubItem} />
              <a className='btn-remove me-0' onClick={() => removeSubItem(nestIndex)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubItem
