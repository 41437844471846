/* eslint-disable no-confusing-arrow */
import virtualShopApi from '_api/virtualShop'
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import ColorPicker from './components/ColorPicker'
import ModalShowBlackList from './components/ModalShowBlackList'
import './style.scss'

function Advance() {
  const dispatch = useDispatch()
  const { selectedShop } = useSelector((state) => state.shop)
  const { addToast } = useToasts()
  const [isOpenModalShowBlackList, setIsOpenModalShowBlackList] = useState(false)
  const [limitedAccount, setLimitedAccount] = useState('')
  const [advanceSetting, setAdvanceSetting] = useState({
    privacyMode: 0,
    giftRecipient: 0,
    additionalInstruction: 0,
    dateSelection: 0,
  })

  useEffect(() => {
    if (!isEmpty(selectedShop)) {
      setAdvanceSetting({
        privacyMode: selectedShop?.privacyMode,
        giftRecipient: selectedShop?.giftRecipient,
        additionalInstruction: selectedShop?.additionalInstruction,
        dateSelection: selectedShop?.dateSelection,
      })
    }
  }, [selectedShop?.shopSlug])

  const toggleShowModalBlackList = () => {
    setIsOpenModalShowBlackList(!isOpenModalShowBlackList)
  }

  const updatePrivacyMode = async (isCheck) => {
    if (!selectedShop?.id) return null
    try {
      setAdvanceSetting((prev) => ({
        ...prev,
        privacyMode: isCheck,
      }))
      const postData = {
        privacyMode: isCheck,
      }
      await virtualShopApi.updateShop(postData, selectedShop?.id)
      dispatch(
        selectShopAndSelectShopDetail({
          ...selectedShop,
          privacyMode: isCheck,
        })
      )
      addToast('Update successfully!', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(`${error.msgResp || error.message}`, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleUpdateGiftRecipient = async (isCheck) => {
    if (!selectedShop?.id) return null

    if (!selectedShop?.fulfillmentStatus && isCheck) {
      return addToast('Please enable fulfillment status', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    setAdvanceSetting({
      ...advanceSetting,
      giftRecipient: isCheck,
    })

    const data = {
      giftRecipient: isCheck,
    }

    try {
      await virtualShopApi.updateGiftRecipient({
        shopId: selectedShop?.id,
        data,
      })
      dispatch(
        selectShopAndSelectShopDetail({
          ...selectedShop,
          giftRecipient: isCheck,
        })
      )
      addToast('Update successfully!', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(`${error.msgResp}`, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleAdditionInstruction = async (isCheck) => {
    if (!selectedShop?.id) return null
    try {
      await virtualShopApi.updateShop({ additionalInstruction: isCheck }, selectedShop?.id)
      dispatch(
        selectShopAndSelectShopDetail({
          ...selectedShop,
          additionalInstruction: isCheck,
        })
      )
      addToast('Update successfully!', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(`${error.msgResp}`, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleUpdateDateSelection = async (isCheck) => {
    if (!selectedShop?.id) return null

    setAdvanceSetting({
      ...advanceSetting,
      dateSelection: isCheck,
    })

    const data = {
      dateSelection: isCheck,
    }

    try {
      await virtualShopApi.updateGiftRecipient({
        shopId: selectedShop?.id,
        data,
      })
      dispatch(
        selectShopAndSelectShopDetail({
          ...selectedShop,
          dateSelection: isCheck,
        })
      )
      addToast('Update successfully!', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(`${error.msgResp}`, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleOnChange = async (e, filedName = '') => {
    const isCheck = e.target.checked ? 1 : 0
    switch (filedName) {
      case 'privacyMode':
        await updatePrivacyMode(isCheck)
        break
      case 'giftRecipient':
        await handleUpdateGiftRecipient(isCheck)
        break
      case 'additionalInstruction':
        await handleAdditionInstruction(isCheck)
        break
      case 'dateSelection':
        await handleUpdateDateSelection(isCheck)
        break
      default:
        break
    }
  }

  const handleUpdateBlacklist = async () => {
    if (!selectedShop?.id) return null

    try {
      const listAllowedAccount = selectedShop?.allowedAccount || []
      const checkPhoneAndEmailOfUser = listAllowedAccount.some((item) => item === limitedAccount)
      if (!checkPhoneAndEmailOfUser) {
        listAllowedAccount.push(limitedAccount)
        const data = {
          privacyMode: advanceSetting?.privacyMode,
          allowedAccount: listAllowedAccount,
        }
        await virtualShopApi.updateShop(data, selectedShop?.id)
        dispatch(
          selectShopAndSelectShopDetail({
            ...selectedShop,
            allowedAccount: listAllowedAccount,
          })
        )
        setLimitedAccount('')
        addToast('Update successfully!', { appearance: 'success', autoDismiss: true })
      } else {
        addToast('This Phone or Email is exist !', { appearance: 'error', autoDismiss: true })
      }
    } catch (e) {
      addToast(`${e.msgResp}`, { appearance: 'error', autoDismiss: true })
    }
  }

  return (
    <div className='advance bg-white pt-3 pb-5 px-5 position-relative'>
      <div className='row mb-3 align-items-center'>
        <div className='col-10'>
          <p className='text-black fw-bold mb-0'>Date Selection</p>
          <p>
            Customers will order for a specific date. You can set your availability and order limits
            for each date.
          </p>
        </div>
        <div className='col-2'>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              disabled
              checked={!!advanceSetting?.dateSelection}
              onChange={(e) => handleOnChange(e, 'dateSelection')}
            />
          </div>
        </div>
      </div>
      <div className='row mb-3 align-items-center'>
        <div className='col-10'>
          <p className='text-black fw-bold mb-0'>Gift recipient</p>
          <p className='mb-0'>
            Customers can address their order to someone else. We will ask for the recipient&apos;s
            name and contact.
          </p>
          <small className='text-orange'>
            Need has delivery zone when gift recipient is enabled
          </small>
        </div>
        <div className='col-2'>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              checked={!!advanceSetting?.giftRecipient}
              onChange={(e) => handleOnChange(e, 'giftRecipient')}
            />
          </div>
        </div>
      </div>
      <div className='row mb-3 align-items-center'>
        <div className='col-10'>
          <p className='text-black fw-bold mb-0'>Additional Instructions</p>
          <p>
            Include instructions for the customer. Will be shown during checkout and in their
            receipt.
          </p>
        </div>
        <div className='col-2'>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              disabled
              checked={!!advanceSetting?.additionalInstruction}
              onChange={(e) => handleOnChange(e, 'additionalInstruction')}
            />
          </div>
        </div>
      </div>
      <div className='row mb-3 align-items-center'>
        <div className='col-10'>
          <p className='text-black fw-bold mb-0'>Privacy Mode</p>
          <p>Limit access to your shop with a private link.</p>
        </div>
        <div className='col-2'>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              checked={!!advanceSetting?.privacyMode}
              onChange={(e) => handleOnChange(e, 'privacyMode')}
            />
          </div>
        </div>
        {advanceSetting.privacyMode ? (
          <div className='col-10 d-flex align-items-center'>
            <input
              type='text'
              className='form-control-sm border me-3 w-50'
              value={limitedAccount}
              onChange={(e) => setLimitedAccount(e.target.value)}
              placeholder='Enter phone or email'
            />
            <button
              className='btn btn-primary rounded shadow-none w-auto'
              onClick={handleUpdateBlacklist}
            >
              Save
            </button>
            <span
              className='text-decoration-underline cursor-pointer ml-3'
              onClick={toggleShowModalBlackList}
              style={{ color: '#ff6903', fontSize: 16 }}
            >
              White list
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='row mb-3 align-items-center'>
        <div className='col-10'>
          <p className='text-black fw-bold mb-0'>Custom Questions</p>
          <p>
            Add questions at the end of the form to collect more information from your customers.
          </p>
        </div>
        <div className='col-2'>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              disabled
              checked={false}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='colors-scheme col-12'>
          <label htmlFor='colors' className='fw-bold text-black'>
            Color Scheme
          </label>
          <ColorPicker />
        </div>
      </div>
      <ModalShowBlackList modal={isOpenModalShowBlackList} toggle={toggleShowModalBlackList} />
    </div>
  )
}

export default Advance
