// DeliveryOptions.jsx
import { truncate } from 'lodash'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Tooltip } from 'reactstrap'

const DeliveryOptions = ({ control, onSelectDeliveryZone }) => {
  const { listDelivery } = useSelector((state) => state.fulfillment)
  const [tooltipOpen, setTooltipOpen] = useState({})

  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  const groupedZones = []
  for (let i = 0; i < listDelivery.length; i += 3) {
    groupedZones.push(listDelivery.slice(i, i + 3))
  }


  if (groupedZones.length === 0 || groupedZones[0][0].id.includes('FAKE_ID')) return null

  return (
    <div className='delivery-options-container'>
      <p className='mb-2'>Choose delivery:</p>
      <div className='grid-container'>
        {groupedZones.map((group, index) => (
          <div key={index} className='grid-item'>
            {group.map((zone) => (
              <div key={zone.id} className='form-check ps-0'>
                <Controller
                  name={'selectedDeliveryZones'}
                  control={control}
                  defaultValue={false}
                  render={({ field }) => {
                    const isChecked = field.value.some(
                      (selectedZone) => selectedZone.optionName === zone.optionName
                    )
                    return (
                      <input
                        type='checkbox'
                        className='custom-checkbox-shop'
                        id={zone.id}
                        checked={isChecked}
                        onChange={(e) => {
                          onSelectDeliveryZone(zone, e.target.checked)
                        }}
                      />
                    )
                  }}
                />
                <label
                  className='form-check-label cursor-pointer'
                  htmlFor={zone.id}
                  id={`tooltip-${zone.id}`}
                >
                  {truncate(zone.optionName, { length: 20 })}
                  <span className='ms-1 text-black fw-semibold'>{`($${zone.deliveryFee})`}</span>
                </label>

                {zone.optionName.length > 20 && (
                  <Tooltip
                    placement='top'
                    isOpen={tooltipOpen[zone.id]}
                    target={`tooltip-${zone.id}`}
                    toggle={() => toggleTooltip(zone.id)}
                    className='tooltip-event'
                    autohide={false}
                  >
                    {zone.optionName}
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default DeliveryOptions
