import React from 'react'
import { CiSearch } from 'react-icons/ci'

export default function EventSearch({ search, setSearch }) {
  return (
    <div
      className='event-search position-relative p-0'
      style={{
        width: '100%',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <input
        type='text'
        className='form-control'
        placeholder='Search event name'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ paddingRight: '40px' }}
      />
      <CiSearch
        size={20}
        style={{
          position: 'absolute',
          right: '20px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      />
    </div>
  )
}
