import React from 'react'
import { BiSolidPurchaseTag } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Swal from 'sweetalert2'
import { clearCart, clearOrderDetailsByRef } from '../redux/modules/order'
import { removeCartCheckout, removeCartOrderDetailsByRef } from '../utils/localData'

export function ButtonToMyPurchase({ style }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { cart, orderDetailsByRef } = useSelector((state) => state.order)

  const fromMyPurchasePath = location.state?.from === 'my-purchase'

  const onClearCart = () => {
    dispatch(clearOrderDetailsByRef())
    removeCartOrderDetailsByRef()
    removeCartCheckout()
    dispatch(clearCart())
  }

  const onRedirect = () => {
    const orderSuccess = localStorage.getItem('ORDER_SUCCESS') === 'true'

    if (fromMyPurchasePath) {
      onClearCart()
      history.push('/virtual-shop/my-purchase')
    } else if (
      !fromMyPurchasePath &&
      !orderSuccess &&
      (cart?.orderList?.length > 0 || orderDetailsByRef?.ref)
    ) {
      Swal.fire({
        title: 'Are you sure you want to leave this page?',
        text: 'Currently, you have an order in progress. Do you want to remove the order information?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15cdca',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          onClearCart()
          setTimeout(() => {
            history.push('/virtual-shop/my-purchase')
          }, 500)
        }
      })
    } else {
      history.push('/virtual-shop/my-purchase')
    }

    localStorage.removeItem('ORDER_SUCCESS')
  }

  return (
    <button
      className='btn float-button w-auto rounded shadow-sm'
      style={style}
      onClick={onRedirect}
    >
      <BiSolidPurchaseTag size={18} className='me-1' />
      <span>My Purchase</span>
    </button>
  )
}
