import { getUserInfo } from '_utils/localData'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import DeliveryAvatar from './DeliveryAvatar'
import DeliveryCardBody from './DeliveryCardBody'
import DeliveryStatus from './DeliveryStatus'
import DeliveryUserInfo from './DeliveryUserInfo'
import './style.scss'

const disabledStyle = {
  pointerEvents: 'none',
  opacity: 0.5,
  filter: 'grayscale(1)',
}

const DeliveryCard = ({ zone, onEditDeliveryZones }) => {
  const userInfo = getUserInfo()

  const { loadingCreateDeliveryZones } = useSelector((state) => state.deliveryEvent)

  const nowTimestamp = moment().unix()

  const isClosed = zone.deliveryTimestamp < nowTimestamp

  const handleEditDeliveryZones = () => {
    if (loadingCreateDeliveryZones) return

    onEditDeliveryZones(zone)
  }

  return (
    <div
      className={'cardContainer cursor-pointer'}
      onClick={handleEditDeliveryZones}
      style={loadingCreateDeliveryZones || isClosed ? disabledStyle : {}}
    >
      <div className={'card-header'}>
        <div className='d-flex align-items-start gap-2'>
          <DeliveryAvatar photoUrl={userInfo.photoUrl} />
          <DeliveryUserInfo name={userInfo.name} shopName={zone.shopName} />
        </div>
        <DeliveryStatus deliveryTime={zone.deliveryTime} />
      </div>
      <DeliveryCardBody deliveryTime={zone.deliveryTime} deliveryDuration={zone.deliveryDuration} />
    </div>
  )
}

export default DeliveryCard
