/* eslint-disable multiline-ternary */
import { yupResolver } from '@hookform/resolvers/yup'
import authApi from '_api/auth'
import reportApi from '_api/report'
import userApi from '_api/user'
import imgTop from '_images/img_top.svg'
import logoLeft from '_images/logo-co-hoot.jpg'
import { POSTAL_CODE_MAX_VALUE } from '_utils/constant'
import { generateSecretCode } from '_utils/functions/generator'
import {
  getCartCheckout,
  getCheckPaymentSubscription,
  getCreateShopData,
  getUserToken,
  setUserInfo,
  setUserToken,
} from '_utils/localData'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'
import CreateUserProfile from '../CreateUserProfile'
import FormConnect from './FormConnect'
import SignUpOption from './SignUpOption'
import './style.scss'

const validationSchema = Yup.object().shape({
  firstName: Yup.string('Invalid first name').required('Invalid first name'),
  lastName: Yup.string('Invalid last name').required('Invalid last name'),
  email: Yup.string().email('Invalid email').required('Invalid email'),
  phone: Yup.string('Invalid phone')
    .typeError('Phone must be a number')
    .min(8, 'Phone number at least 8 digits')
    .max(16, 'Phone number with at most 16 digits')
    .required('Invalid phone'),
  postalCode: Yup.string()
    .max(POSTAL_CODE_MAX_VALUE, 'Invalid postal code')
    .nullable()
    .notRequired(),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string().required('Confirm password is required'),
})

const SignUpPage = () => {
  const history = useHistory()
  const userToken = getUserToken()
  const { addToast } = useToasts()
  const [isSignUpWithPassword, setIsSignUpWithPassword] = useState(false)

  const [isShowFormConnect, setIsShowFormConnect] = useState(false)
  const [avatarFileUpload, setAvatarFileUpload] = useState('')
  const [avatarFileUrl, setAvatarFileUrl] = useState('')
  const location = useLocation()
  const [isValidChecklist, setIsValidChecklist] = useState(false)

  const secretCode = generateSecretCode()
  const urlBeforeLogout = localStorage.getItem('URL_BEFORE_LOGOUT')

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const watchAllFields = watch()

  useEffect(() => {
    if (userToken) history.push('/virtual-shop/list')
  }, [userToken])

  const onUserImageUploaded = (event) => {
    const { files } = event.target
    if (files) {
      const img = files[0]
      setAvatarFileUrl(URL.createObjectURL(img))
      setAvatarFileUpload(files)
    }
  }

  const registerNewAccount = async (data = {}) => {
    try {
      const res = await authApi.register(data)

      return res.msgResp.token
    } catch (error) {
      addToast(error?.msgResp || error?.message, {
        autoDismiss: true,
        appearance: 'error',
      })
    }
  }

  const uploadPhoto = async (photo, token) => {
    try {
      const formData = new FormData()
      for (let i = 0; i < photo.length; i++) {
        formData.append('type', 1)
        formData.append('file', photo[i])
      }
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/upload-photo`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Keep-AIive': secretCode,
        },
      })
      return res.data.msgResp.url
    } catch (error) {
      reportApi.report({ message: `${error.msgCode} - ${error.msgResp}` })
    }
  }

  const updateProfile = async (userInfo, token) => {
    try {
      const res = await axios.patch(`${process.env.REACT_APP_API_URL}/update-user`, userInfo, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Keep-AIive': secretCode,
        },
      })
      setUserInfo(res.data.msgResp)
    } catch (error) {
      reportApi.report({ message: `${error.msgCode} - ${error.msgResp}` })
    }
  }

  const fetchUserInfo = async () => {
    try {
      const { msgResp } = await userApi.getUser()
      setUserInfo(msgResp)
    } catch (error) {
      if (error?.msgCode) {
        reportApi.report({ message: `[Main] - ${error?.msgCode} - ${error?.msgResp}` })
      } else {
        reportApi.report({ message: `[Main - client] - ${error?.message}` })
      }
    }
  }

  const onSubmit = async (data) => {
    const { firstName, lastName, email, phone, postalCode, password, confirmPassword } = data || {}
    let name = ''
    if (firstName && lastName) {
      name = `${firstName.trim()} ${lastName.trim()}`
    }
    const newData = {
      name,
      phone,
      postalCode,
      password,
      confirmPassword,
      email: email.toLowerCase().trim(),
    }

    if (isValidChecklist) {
      const userInfo = { ...newData }
      const token = await registerNewAccount(userInfo)

      if (token === undefined) return

      if (avatarFileUpload) {
        userInfo.photoUrl = await uploadPhoto(avatarFileUpload, userInfo)
      }

      delete userInfo.email
      delete userInfo.password
      await updateProfile(userInfo, token)
      await setUserToken(token)

      if (getCartCheckout()) {
        history.push('/virtual-shop/checkout')
      } else if (getCheckPaymentSubscription()) {
        await fetchUserInfo()
        const linkPaymentSubscription = getCheckPaymentSubscription()
        history.push(linkPaymentSubscription)
      } else if (getCreateShopData()) {
        await fetchUserInfo()
        history.push('/virtual-shop/confirm-shop', {
          from: location.pathname,
        })
      } else if (urlBeforeLogout) {
        // Check if the URL is absolute
        const isAbsoluteUrl =
          urlBeforeLogout.startsWith('http://') || urlBeforeLogout.startsWith('https://')

        if (isAbsoluteUrl) {
          // If the URL is absolute, use window.location.replace
          window.location.replace(urlBeforeLogout)
        } else {
          // If the URL is relative, use history.replace
          history.replace(urlBeforeLogout)
        }

        localStorage.removeItem('URL_BEFORE_LOGOUT')
      } else {
        await fetchUserInfo()
        history.push('/virtual-shop/list')
      }
    }
  }

  const isSignUpRoute = location.pathname === '/virtual-shop/sign-up'

  return (
    <div className='sign-up'>
      <div
        className='p-login min-vh-100'
        // style={isSignUpRoute ? { backgroundColor: 'transparent' } : null}
      >
        <div
          className='page-login page-login--screen'
          style={isSignUpRoute ? { borderRadius: '16px' } : null}
        >
          <div
            className='main-top align-self-start'
            // style={isSignUpRoute ? { display: 'none' } : null}
          >
            <div className='container'>
              <Link
                to='/'
                className='main-logo text-left text-white font-semibold d-flex flex-column align-items-center position-relative'
                style={{ width: '30%' }}
              >
                <img src={logoLeft} />
                <small
                  style={{
                    position: 'absolute',
                    top: 60,
                    fontSize: 10,
                  }}
                >
                  Support Local Business & Community
                </small>
              </Link>
              <div className='main-img text-center'>
                <img src={imgTop} alt={'main-img'} />
              </div>
            </div>
          </div>
          {!isSignUpWithPassword && !isShowFormConnect && (
            <SignUpOption
              setIsSignUpWithPassword={setIsSignUpWithPassword}
              setIsShowFormConnect={setIsShowFormConnect}
            />
          )}

          {isSignUpWithPassword && !isShowFormConnect && (
            <CreateUserProfile
              isSubmitting={isSubmitting}
              {...{
                avatarFileUrl,
                onUserImageUploaded,
                onSubmit,
                register,
                errors,
                handleSubmit,
                watchAllFields,
                isValidChecklist,
                setIsValidChecklist,
              }}
            />
          )}

          {isShowFormConnect && !isSignUpWithPassword && <FormConnect />}
        </div>
      </div>
    </div>
  )
}

export default SignUpPage
