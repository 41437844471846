/* eslint-disable no-plusplus */
import MySlider from '_components/MySlider'
import { capitalizeWords } from '_utils/function'
import { getUserInfo } from '_utils/localData'
import { isEmpty } from 'lodash'
import React from 'react'
import { AiFillShop } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import ShopSocialIcon from './ShopSocialIcon'

function ShopBanner({ shopDetail, handleOnClick }) {
  const shopColorTheme = shopDetail?.shopColorTheme
  const colorTheme = shopColorTheme?.customsColorTheme || shopColorTheme?.defaultColorTheme

  const userInfo = getUserInfo()

  return (
    <div className='shop-detail-banner row mx-0 flex-column'>
      {isEmpty(shopDetail) ? null : (
        <>
          <header className={'col-12'}>
            <div className='d-flex justify-content-between mb-3'>
              <p
                className='shop-detail-banner-title text-start fw-bold mb-0'
                style={{
                  color: colorTheme?.headerTextColor,
                  width: !userInfo?.id ? '300px' : undefined,
                }}
              >
                {capitalizeWords(shopDetail?.shopName)}
              </p>
              {!userInfo?.id ? (
                <Link
                  to='/virtual-shop/sign-in'
                  className='sign-in text-secondary f-14 fw-semibold align-self-end'
                >
                  Sign-in / Sign-up
                </Link>
              ) : null}
            </div>
          </header>
          <div className='w-100'></div>
          <div className='d-flex align-items-start px-0'>
            <div className='rounded overflow-hidden mb-4 col-12'>
              <div className='wrapper-banner w-100'>
                <MySlider
                  listImage={shopDetail?.logo}
                  className='shop-detail-banner-img object-fit-contain h-100 w-100'
                />
              </div>
            </div>
            <div className='col'>
              <ShopSocialIcon shopDetail={shopDetail} handleOnClick={handleOnClick} />
            </div>
          </div>
          <div className='w-100'></div>
          <div className='desc rounded p-3 shadow-sm mb-3 col-12'>
            <p
              className='mb-2 text-title text-uppercase d-flex align-items-center'
              style={{ color: colorTheme?.generalTextColor }}
            >
              <AiFillShop size={20} className='me-2' /> <span>Description</span>
            </p>
            <p className='shop-detail-banner-desc m-0 p-0'>{shopDetail?.headline}</p>
          </div>
        </>
      )}
    </div>
  )
}

export default ShopBanner
