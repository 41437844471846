import { DeliveryContext } from '_contexts/DeliveryContext'
import { useContext } from 'react'

const DeliveryExport = () => {
  const { filteredOrdersFulfillment, handleExport } = useContext(DeliveryContext)
  return (
    <button
      onClick={() => handleExport(filteredOrdersFulfillment)}
      className='text-uppercase bg-transparent btn shadow-none rounded btn-export border-0 ms-auto w-auto'
    >
      Export
    </button>
  )
}

export default DeliveryExport
