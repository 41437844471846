/* eslint-disable no-confusing-arrow */
import MyGallery from '_components/MyGallery'
import Pagination from '_components/Pagination'
import { OrderRecordContext } from '_contexts/OrderRecordContext'
import { fetchReverseOrdersDetail } from '_redux/modules/order'
import { scrollToTop } from '_utils/function'
import {
  REVERSE_ORDER_FOR_SELLER_BUTTON,
  REVERSE_ORDER_HISTORY_LIST_BUTTON,
  REVERSE_ORDER_REASON_LIST_BUTTON,
  REVERSE_ORDER_STATUS_CANCELLED,
  REVERSE_ORDER_STATUS_COMPLETED,
  REVERSE_ORDER_STATUS_PENDING,
  REVERSE_ORDER_STATUS_PROCESSING,
  REVERSE_ORDER_STATUS_UNKNOWN,
} from '_utils/LazadaConstants'
import classNames from 'classnames'
import { camelCase, snakeCase } from 'lodash'
import moment from 'moment'
import React, { useContext, useMemo, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const PageSize = 10

function ListReverseOrder() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(1)
  const { selectedShop } = useSelector((state) => state.shop)
  const { listReverseOrdersForSeller } = useSelector((state) => state.order)
  const { searchString, activeTabReverseOrder } = useContext(OrderRecordContext)
  const tableData = [
    {
      [REVERSE_ORDER_FOR_SELLER_BUTTON]: [
        'Reverse Order ID',
        'Request Type',
        'Shipping Type',
        'Trade Order ID',
        'Created Date',
      ],
      [REVERSE_ORDER_HISTORY_LIST_BUTTON]: ['Time', 'Operator', 'Picture', 'Created Date'],
      [REVERSE_ORDER_REASON_LIST_BUTTON]: ['Reason ID', 'Reason', 'Created Date'],
    },
  ]

  const requestTypeMapping = {
    cancelled: REVERSE_ORDER_STATUS_CANCELLED,
    completed: REVERSE_ORDER_STATUS_COMPLETED,
    pending: REVERSE_ORDER_STATUS_PENDING,
    processing: REVERSE_ORDER_STATUS_PROCESSING,
    unknown: REVERSE_ORDER_STATUS_UNKNOWN,
  }

  const filteredData = useMemo(() => {
    if (searchString) {
      const lowerCaseSearchString = searchString.toLowerCase()

      return listReverseOrdersForSeller.filter((row) => {
        const requestTypeString =
          Object.keys(requestTypeMapping)
            .find((key) => requestTypeMapping[key] === row.request_type)
            ?.toLowerCase() || ''

        if (requestTypeString.includes(lowerCaseSearchString)) {
          return true
        }

        const values = Object.values(row)
        const valuesAsString = values.map(JSON.stringify)
        const lowerCaseValues = valuesAsString.map((value) => value.toLowerCase()).join('')
        return lowerCaseValues.includes(lowerCaseSearchString)
      })
    }

    return listReverseOrdersForSeller
  }, [searchString, JSON.stringify(listReverseOrdersForSeller)])

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize

    if (filteredData?.length <= PageSize) {
      return filteredData
    }

    return filteredData?.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, JSON.stringify(filteredData)])

  const tableClasses =
    'table table-hover table-borderless align-middle text-center text-nowrap fw-normal table-reverse-order'
  const thClasses = 'border-bottom border-200 py-3'
  const tdClasses = 'border-bottom border-200 py-3'

  const renderStatus = (status) => {
    const statusMap = {
      [REVERSE_ORDER_STATUS_PENDING]: { background: 'bg-warning', text: 'Pending' },
      [REVERSE_ORDER_STATUS_PROCESSING]: { background: 'bg-info', text: 'Processing' },
      [REVERSE_ORDER_STATUS_COMPLETED]: { background: 'bg-success', text: 'Completed' },
      [REVERSE_ORDER_STATUS_CANCELLED]: { background: 'bg-danger', text: 'Cancelled' },
      [REVERSE_ORDER_STATUS_UNKNOWN]: { background: 'bg-secondary', text: 'Unknown' },
    }

    return (
      <span className={`badge ${statusMap[status]?.background}`}>{statusMap[status]?.text}</span>
    )
  }

  const directToReverseOrderDetail = async (selectedOrder) => {
    dispatch(
      fetchReverseOrdersDetail({
        shopId: selectedShop?.id,
        orderReverse: selectedOrder.reverse_order_id,
      })
    )
    history.push(`/admin-console/order-records/${selectedOrder.reverse_order_id}`)
  }

  return (
    <>
      <p className='mt-3 fw-bold'>Total Reverse Orders: {listReverseOrdersForSeller.length}</p>
      <table className={tableClasses}>
        <thead>
          <tr>
            {tableData[0][activeTabReverseOrder].map((header, index) => (
              <th
                key={index}
                scope='col'
                className={classNames(thClasses, header === 'Amount' && 'text-end')}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {currentData?.length ? (
            currentData.map((order, index) => (
              <tr
                key={`${activeTabReverseOrder}-${index}`}
                className='cursor-pointer'
                onClick={() => directToReverseOrderDetail(order)}
              >
                {tableData[0][activeTabReverseOrder].map((header, cellIndex) => {
                  const cellKey = `${activeTabReverseOrder}-${index}-${cellIndex}`
                  const cellClasses =
                    header === 'Amount' || header === 'Picture'
                      ? `${tdClasses}${
                          header === 'Picture'
                            ? ' d-flex flex-wrap align-items-center column-gap-1'
                            : ''
                        }`
                      : tdClasses

                  if (['Reverse Order ID', 'Trade Order ID'].includes(header)) {
                    return (
                      <td key={cellKey} className={cellClasses}>
                        {order[snakeCase(header)].slice(0, 8)}
                      </td>
                    )
                  }

                  if (header === 'Request Type') {
                    return (
                      <td key={cellKey} className={cellClasses}>
                        {renderStatus(order[snakeCase(header)])}
                      </td>
                    )
                  }

                  if (header === 'Amount') {
                    return (
                      <td key={cellKey} className={cellClasses}>
                        <CurrencyFormat
                          value={order[camelCase(header)] || 0}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </td>
                    )
                  }

                  if (header === 'Created Date') {
                    return (
                      <td key={cellKey} className={cellClasses}>
                        {moment(order[camelCase(header)]).format('DD-MM-YYYY')}
                      </td>
                    )
                  }

                  if (header === 'Picture') {
                    return (
                      <td key={cellKey} className={cellClasses}>
                        {order[camelCase(header)].map((pic, picIndex) => (
                          <MyGallery
                            key={`${cellKey}-${picIndex}`}
                            className='cursor-pointer'
                            image={pic.url}
                          />
                        ))}
                      </td>
                    )
                  }

                  return (
                    <td key={cellKey} className={cellClasses}>
                      {order[snakeCase(header)]}
                    </td>
                  )
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={tableData[0][activeTabReverseOrder].length}>
                <div className='text-center fw-bold'>
                  <p>No Data</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan={tableData[0][activeTabReverseOrder].length}>
              <Pagination
                currentPage={currentPage}
                totalCount={filteredData?.length || 0}
                pageSize={PageSize}
                onPageChange={(page) => {
                  setCurrentPage(page)
                  scrollToTop()
                }}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  )
}

export default ListReverseOrder
