import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

const DialogBoxUpgradeSubscription = ({ modal, toggle, ...args }) => {
  const history = useHistory()
  const { selectedShop } = useSelector((state) => state.shop)

  return (
    <Modal isOpen={modal} toggle={toggle} {...args} className={'modal-upgrade-subscription'}>
      <ModalBody className={'f-16 py-4'}>
        You existing subscription allows a maximum of 5 products. To add additional products, please
        upgrade your subscription.
      </ModalBody>
      <ModalFooter className={'f-16 pb-2'}>
        <div className={'d-flex align-items-center justify-content-between w-100 column-gap-5'}>
          <button
            onClick={() => history.push(`/admin-console/${selectedShop?.shopSlug}/subscription`)}
            className={'btn btn-submit w-50 rounded shadow-none'}
          >
            Upgrade
          </button>
          <button
            onClick={toggle}
            className={
              'btn w-50 mt-0 bg-transparent rounded border btn-cancel shadow-none text-secondary'
            }
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default DialogBoxUpgradeSubscription
