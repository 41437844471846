import CopyClipboardText from '_components/CopyClipboardText'
import MyGallery from '_components/MyGallery'
import Pagination from '_components/Pagination'
import { fetchListReverseOrderHistoryLazada, fetchReverseOrderReason } from '_redux/modules/order'
import {
  REVERSE_ORDER_STATUS_CANCELLED,
  REVERSE_ORDER_STATUS_COMPLETED,
  REVERSE_ORDER_STATUS_PENDING,
  REVERSE_ORDER_STATUS_PROCESSING,
} from '_utils/LazadaConstants'
import { scrollToTop } from '_utils/function'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { IoMdArrowBack } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import Search from '../../components/Search'

const PageSize = 10
function ReverseOrderDetail() {
  const [currentPage, setCurrentPage] = useState(1)
  const [searchString, setSearchString] = useState('')
  const [tableWidth, setTableWidth] = useState(null)

  const dispatch = useDispatch()
  const history = useHistory()
  const {
    params: { orderId },
  } = useRouteMatch()
  const selectedShop = useSelector((state) => state.shop.selectedShop) || {}
  const reverseOrderDetail = useSelector((state) => state.order.reverseOrderDetail) || {}

  const reverseOrderId = orderId

  const tableHeadersDTO = [
    { titleHeader: 'Product Name', class: 'text-start', minWidth: '20%' },
    { titleHeader: 'Image', class: 'text-start', minWidth: '10%' },
    { titleHeader: 'Reason', class: 'text-start', minWidth: '15%' },
    { titleHeader: 'Refund Method', class: 'text-start align-middle', minWidth: '120px' },
    { titleHeader: 'Unit Price', class: 'text-end align-middle', minWidth: '120px' },
    { titleHeader: 'Amount Refund', class: 'text-end align-middle', minWidth: '121px' },
  ]

  const handleSearch = (value) => {
    setSearchString(value)
  }

  const handleResize = useCallback(() => {
    const table = document.querySelector('.modal-reverse-order table')
    if (table) {
      setTableWidth(table.offsetWidth)
    }

    scrollToTop()
  }, [])

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  useEffect(() => {
    let isMounted = true

    if (reverseOrderId) {
      const fetchData = async () => {
        try {
          await dispatch(
            fetchListReverseOrderHistoryLazada({
              shopId: selectedShop?.id,
              orderReverseLineId: reverseOrderId,
              pageNumber: 1,
              pageSize: 10,
            })
          )
          await dispatch(
            fetchReverseOrderReason({
              shopId: selectedShop?.id,
              orderReverseLineId: reverseOrderId,
            })
          )
        } catch (error) {
          console.error('Failed to fetch data:', error)
        }
      }

      if (isMounted) fetchData()
    }

    return () => {
      isMounted = false
    }
  }, [reverseOrderId, dispatch, selectedShop?.id])

  const renderStatus = (status) => {
    const statusMap = {
      [REVERSE_ORDER_STATUS_PENDING]: { background: 'bg-warning', text: 'Pending' },
      [REVERSE_ORDER_STATUS_PROCESSING]: { background: 'bg-info', text: 'Processing' },
      [REVERSE_ORDER_STATUS_COMPLETED]: { background: 'bg-success', text: 'Completed' },
    }
    const isCancelStatus =
      status === REVERSE_ORDER_STATUS_CANCELLED ||
      status?.toLowerCase() === 'canceled' ||
      status?.toLowerCase() === 'cancel'

    const statusText = isCancelStatus ? 'Cancelled' : statusMap[status]?.text
    if (!statusText) return null

    return (
      <span className={`badge ${isCancelStatus ? 'bg-danger' : statusMap[status].background}`}>
        {statusText}
      </span>
    )
  }

  const filteredItems = useMemo(() => {
    if (searchString) {
      const lowerCaseSearchString = searchString.toLowerCase()
      return reverseOrderDetail?.reverseOrderLineDTOList?.filter((item) => {
        const values = Object.values(item)
        const valuesAsString = values.map(JSON.stringify)
        const lowerCaseValues = valuesAsString.map((value) => value.toLowerCase()).join('')
        return lowerCaseValues.includes(lowerCaseSearchString)
      })
    }

    return reverseOrderDetail?.reverseOrderLineDTOList
  }, [searchString, JSON.stringify(reverseOrderDetail?.reverseOrderLineDTOList)])

  const currentOrders = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize

    if (filteredItems?.length <= PageSize) {
      return filteredItems
    }

    return filteredItems?.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, JSON.stringify(filteredItems)])

  return (
    <div className='reverse-order-detail p-3'>
      <div className='row mb-3'>
        <div className='col-12 mb-3'>
          <button
            type='button'
            className='bg-transparent border-0 w-auto title shadow-none p-0'
            onClick={() => history.goBack()}
          >
            <u
              style={{
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IoMdArrowBack size={18} />
              Back
            </u>
          </button>
        </div>
        <div className='col-12 mb-3'>
          <div className='d-flex align-items-center justify-content-between'>
            <h5 className='title mb-0'>Reverse Order Detail</h5>
            <Search searchString={searchString} handleSearch={handleSearch} />
          </div>
        </div>
        <div className='w-100'></div>
        <div className='col-6'>
          <p className='d-flex align-items-center'>
            Reverse Order Id:
            <span className='ms-1 fw-bold'>
              <CopyClipboardText text={reverseOrderDetail?.reverse_order_id} />
            </span>
          </p>
        </div>
        <div className='col-6'>
          <p className='d-flex align-items-center justify-content-end'>
            Trade Order Id:
            <span className='ms-1 fw-bold'>
              <CopyClipboardText text={reverseOrderDetail?.trade_order_id} />
            </span>
          </p>
        </div>
        <div className='w-100'></div>
        <div className='col-12 mt-3'>
          Status: <span className='fw-bold'>{renderStatus(reverseOrderDetail?.request_type)}</span>
        </div>
      </div>

      <p className='mb-3 fw-bold title' style={{ fontSize: '0.75rem' }}>
        Reverse Order DTO List
      </p>
      <p className='mb-3'>
        Total: <b>{reverseOrderDetail?.reverseOrderLineDTOList?.length || 0} items</b>
      </p>
      <div className='table-wrapper'>
        <table className='table sticky-header-table'>
          <thead className='sticky-header'>
            <tr>
              {tableHeadersDTO.map((item) => (
                <th
                  key={item.titleHeader}
                  className={item.class}
                  style={{
                    ...(item.minWidth > 0
                      ? {
                          width: `${
                            tableWidth ? (tableWidth * item.minWidth.slice(0, -2)) / 100 : 0
                          }px`,
                        }
                      : {
                          minWidth: item.minWidth,
                        }),
                  }}
                >
                  {item.titleHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentOrders?.length ? (
              currentOrders.map((item, index) => (
                <tr key={index}>
                  <td>
                    <ul>
                      <li className='d-flex align-items-center mb-2'>
                        <span>{item.productDTO.product_name || 'test'}</span>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <MyGallery
                      className='cursor-pointer'
                      image={
                        item.productDTO.product_url ||
                        'https://placeholder.com/300x300.png?text=No+Image'
                      }
                    />
                  </td>
                  <td>{item.reason_text}</td>
                  <td>{item.refund_payment_method}</td>
                  <td className='text-end'>
                    <b>
                      <CurrencyFormat
                        value={item.item_unit_price || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </b>
                  </td>

                  <td className='text-end'>
                    <b className='text-danger'>
                      <CurrencyFormat
                        value={item.refund_amount || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </b>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={tableHeadersDTO.length} className='text-center fw-semibold'>
                  Not Found Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={currentPage}
        totalCount={filteredItems?.length || 0}
        pageSize={PageSize}
        onPageChange={(page) => {
          setCurrentPage(page)
          scrollToTop()
        }}
      />
    </div>
  )
}

export default ReverseOrderDetail
