import { DEFAULT_PRODUCT_DIGISTALL, PACKAGE_SUBSCRIPTION_BASIC } from '_utils/constant'
import { getUserToken } from '_utils/localData'
import { isNull, isUndefined } from 'lodash'
import { useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { AiOutlinePlus } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import ProductItem from './ProductItem'

function ProductList({ control, register, getValues, setValue, errors, watch }) {
  const {
    fields,
    append: appendProduct,
    insert: insertProduct,
    remove: removeProduct,
  } = useFieldArray({
    control,
    name: 'products',
  })

  const userToken = getUserToken()
  const { currentPackage } = useSelector((state) => state.subscription)
  const [isMaxProductsReached, setIsMaxProductsReached] = useState(false)

  const watchFieldArray = watch('products')
  const controlledFields = fields.map((field, _index) => ({
    ...field,
    ...watchFieldArray[_index],
  }))

  const swapProduct = (oldIndex, newIndex) => {
    const a = controlledFields[oldIndex]
    const b = controlledFields[newIndex]
    const subItemA = a.subItems
    const subItemB = b.subItems

    setValue(`products.${oldIndex}`, b)
    setValue(`products.${newIndex}`, a)
    setValue(`products.${oldIndex}.subItems`, subItemB)
    setValue(`products.${newIndex}.subItems`, subItemA)
  }

  useEffect(() => {
    const currentPackageIsBasic =
      currentPackage?.subscriptionPackageType === PACKAGE_SUBSCRIPTION_BASIC

    if (
      (currentPackageIsBasic || currentPackage === undefined || currentPackage === null) &&
      controlledFields.length >= 5
    ) {
      setIsMaxProductsReached(true)
    } else {
      setIsMaxProductsReached(false)
    }
  }, [controlledFields.length])

  const handleAddItem = () => {
    const currentPackageIsBasic =
      currentPackage?.subscriptionPackageType === PACKAGE_SUBSCRIPTION_BASIC

    if (!userToken || isUndefined(currentPackage) || isNull(currentPackage)) {
      if (controlledFields.length >= 5) {
        setIsMaxProductsReached(true)
      } else {
        setIsMaxProductsReached(false)
      }
    } else if (currentPackageIsBasic) {
      if (controlledFields.length >= 5) {
        setIsMaxProductsReached(true)
      } else {
        setIsMaxProductsReached(false)
      }
    }
    appendProduct({ ...DEFAULT_PRODUCT_DIGISTALL })
  }

  return (
    <div>
      {controlledFields.map((item, index) => (
        <ProductItem
          key={index}
          productIndex={index}
          totalListProduct={controlledFields?.length}
          setIsMaxProductsReached={setIsMaxProductsReached}
          {...{
            control,
            register,
            appendProduct,
            insertProduct,
            removeProduct,
            setValue,
            getValues,
            swapProduct,
            watch,
            errors,
          }}
          product={item}
        />
      ))}
      <p
        className='fs-6 text-center cursor-pointer'
        style={{
          margin: '1rem -0.75rem',
        }}
      >
        {!isMaxProductsReached ? (
          <a
            className='btn-add-item bg-transparent border-dashed d-inline-block w-100'
            onClick={handleAddItem}
          >
            <AiOutlinePlus
              style={{
                transform: 'translateY(-2px)',
              }}
            />
            Add Item
          </a>
        ) : (
          <span className='text-orange f-12 d-block' style={{ lineHeight: 1.5 }}>
            You&apos;re using the basic package, after creating the shop, go to the admin console
            page and go to the subscription tab to upgrade your account.
          </span>
        )}
      </p>
    </div>
  )
}

export default ProductList
