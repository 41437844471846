import React from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

export default function ModalWarning({ data, title, modalWarning, toggleWarning, handleCancel }) {
  return (
    <div>
      <Modal isOpen={modalWarning} toggle={toggleWarning} centered className='modal-warning'>
        <ModalBody style={{ textAlign: 'center', padding: 0 }}>
          Warning!
          <br />
          Please ensure that all refunds are processed before cancelling {title}.
          <br />
          <br />
          Any dispute, host will need to take responsibility on refund and cancellation of {title}s.
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
          <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
            <button className='btn btn--solid btn--solid04' onClick={toggleWarning}>
              CANCEL
            </button>
          </div>
          <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
            <button
              className='btn btn--org btn--org02'
              onClick={(e) =>
                handleCancel(
                  e,
                  data.ref,
                  data.status,
                  data && data.buyerStatus ? data.buyerStatus : data.bookerStatus,
                  data.combinedPaymentRef
                )
              }
            >
              OK
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}
