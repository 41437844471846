import { CheckoutContext } from '_contexts/CheckoutContext'
import PayNowLogo from '_images/paynow-logo.svg'
import { PAYMENT_COD, TRANSFER_BANKING, TURN_ON } from '_utils/constant'
import { useContext } from 'react'
import { BsCashCoin } from 'react-icons/bs'

const styleDisabled = {
  opacity: 0.5,
  pointerEvents: 'none',
  filter: 'grayscale(1)',
}

export default function SelectPaymentMethod() {
  const { buyer, shop, setValue } = useContext(CheckoutContext)

  const onChangeMethod = (value) => {
    if (value === TRANSFER_BANKING) {
      setValue('isCheckCOD', false)
    }

    setValue('paymentMethod', value)
  }

  return (
    <>
      {shop?.cashOnDeliveryPickup === TURN_ON && (
        <>
          <p className={'mb-2'}>Select payment method:</p>
          <div className='row mb-3'>
            <div className='col-12 border rounded mb-2'>
              <div className='form-check ps-2 d-flex align-items-center' style={{ height: 50 }}>
                <label
                  className='w-100 p-2 fw-normal cursor-pointer form-check-label h-100 d-flex align-items-center position-relative'
                  htmlFor='banking'
                >
                  <img
                    src={PayNowLogo}
                    className='object-fit-contain'
                    style={{ transform: 'translateX(-60px)' }}
                    alt='paynow-logo'
                    width={200}
                    height={100}
                    // loading='lazy'
                  />
                  <input
                    type='radio'
                    name='banking'
                    id='banking'
                    className='form-check-input'
                    checked={buyer.paymentMethod === TRANSFER_BANKING}
                    style={{ right: '1rem' }}
                    onChange={() => onChangeMethod(TRANSFER_BANKING)}
                  />
                </label>
              </div>
            </div>
            <div className='col-12 border rounded' style={buyer.isGift ? styleDisabled : {}}>
              <div className='form-check ps-2 d-flex align-items-center' style={{ height: 50 }}>
                <label
                  className='w-100 p-2 fw-normal cursor-pointer form-check-label h-100 d-flex align-items-center'
                  htmlFor='cod'
                >
                  <span className='icon-text' style={{ transform: 'translateY(3px)' }}>
                    <BsCashCoin size={25} color={'green'} className={'me-2'} />
                    Cash on Delivery / Pickup
                  </span>
                </label>
                <input
                  type='radio'
                  name='cod'
                  id='cod'
                  className='form-check-input'
                  style={{ right: '1rem' }}
                  checked={buyer.paymentMethod === PAYMENT_COD}
                  onChange={() => onChangeMethod(PAYMENT_COD)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
