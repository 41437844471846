import React, { Fragment, useState } from 'react'
import { DropdownItem, Tooltip } from 'reactstrap'
import { convertTimeStringWithDuration } from '../utils/functions/converter'

export default function TooltipDropdownItem({
  event,
  deliveryEvent,
  tabOrderEvent,
  handleSelectEvent,
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  const data = event || deliveryEvent
  const { id } = data

  const renderTitleDeliveryEvent = () => {
    if (deliveryEvent) {
      return `Delivery for (${convertTimeStringWithDuration(
        data.deliveryTime,
        data.deliveryDuration
      )})`
    }
    return null
  }

  const title = event ? event.title : renderTitleDeliveryEvent()

  return (
    <Fragment>
      <DropdownItem
        onClick={() => handleSelectEvent(id, title)}
        active={tabOrderEvent === id}
        id={`tooltip-${id}`}
      >
        {title}
      </DropdownItem>
      <Tooltip
        placement='right'
        isOpen={tooltipOpen}
        target={`tooltip-${id}`}
        toggle={toggleTooltip}
        className='tooltip-event'
        autohide={false}
      >
        {title}
      </Tooltip>
    </Fragment>
  )
}
