/* eslint-disable class-methods-use-this */
import React, { lazy, PureComponent, Suspense } from 'react'
import * as Yup from 'yup'
import './assets/css/pe-icon-7.css'

import contactApi from '_api/contact'
import { fetchMyShops } from '_redux/modules/shop'
import { getUserToken } from '_utils/localData'
import { connect } from 'react-redux'
import './assets/scss/themes.scss'
import AboutUs from './components/AboutUs'
import Pricing from './components/Pricing'

const Navbar = lazy(() => import('./components/Navbar/NavbarPage'))

const Section = lazy(() => import('./Section'))
const Services = lazy(() => import('./components/Services'))
const Feature = lazy(() => import('./components/Feature'))
const Footer = lazy(() => import('./components/Footer/Footer'))

class LandingPage extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      navItems: [
        { id: 1, idnm: 'home', navheading: 'Home' },
        { id: 2, idnm: 'how-to', navheading: 'How-to' },
        { id: 3, idnm: 'features', navheading: 'Features' },
        { id: 4, idnm: 'pricing', navheading: 'Pricing' },
        { id: 5, idnm: 'about-us', navheading: 'About Us' },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: '',
      isOpenModal: false,
      contactForm: {
        name: '',
        email: '',
        message: '',
      },
      errorMessage: {
        name: '',
        email: '',
        message: '',
      },
      isSubmitting: false,
      isApiCalled: false,
      userToken: getUserToken(),
      fetchMyShopsCalled: false,
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this) // Bind the submit function
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollNavigation, true)

    if (this.state.userToken && !this.state.fetchMyShopsCalled) {
      this.props.fetchMyShops()
      this.setState({ fetchMyShopsCalled: true })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true)
  }

  scrollNavigation = () => {
    const scrollup = document.documentElement.scrollTop
    if (scrollup > this.state.pos) {
      this.setState({ navClass: 'nav-sticky', imglight: false })
    } else {
      this.setState({ navClass: '', imglight: false })
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    const { contactForm, isApiCalled } = this.state

    // Define the validation schema
    const schema = Yup.object().shape({
      name: Yup.string()
        .required('Name is required')
        .max(50, 'Name must be less than or equal to 50 characters'),
      email: Yup.string()
        .matches(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/, 'Invalid email')
        .required('Email is required')
        .max(254, 'Email must be less than or equal to 254 characters'),
      message: Yup.string()
        .required('Message is required')
        .max(1000, 'Message must be less than or equal to 1000 characters'),
    })

    let valid = false

    try {
      schema.validateSync(contactForm, { abortEarly: false })
      valid = true
    } catch (error) {
      // If validation fails, update the error message
      const errorMessage = {}
      error.inner.forEach((event) => {
        errorMessage[event.path] = event.message
      })
      this.setState({ errorMessage })
    }

    if (valid && !isApiCalled) {
      this.setState({ isSubmitting: true, apiCalled: true })

      // If validation is successful, submit the form data
      const postData = {
        ...contactForm,
        content: contactForm.message,
      }

      await contactApi.sendContact(postData)

      // Reset the form data
      this.setState({
        contactForm: {
          name: '',
          email: '',
          message: '',
        },
        errorMessage: {
          name: '',
          email: '',
          message: '',
        },
        isApiCalled: false,
        isSubmitting: false,
      })

      // alert('Your message has been sent success!')

      // Close the modal
      this.setState({
        isOpenModal: false,
      })

      this.setState({ isSubmitting: false })
    }
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpenModal: !prevState.isOpenModal,
      isApiCalled: false,
      contactForm: {
        name: '',
        email: '',
        message: '',
      },
      errorMessage: {
        name: '',
        email: '',
        message: '',
      },
    }))
  }

  onChange = (e, key) => {
    const { contactForm } = this.state
    contactForm[key] = e.target.value
    this.setState({ contactForm })
  }

  // set preloader div
  PreLoader() {
    return (
      <div id='preloader'>
        <div id='status'>
          <div className='spinner'>
            <div className='bounce1'></div>
            <div className='bounce2'></div>
            <div className='bounce3'></div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='landing-page'>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />

          <div className='container'>
            <p className='text-end f-14'>
              Co-Hoot is here to support all local business and community
            </p>
          </div>

          {/* Importing Section */}
          <Section />

          {/* Importing Service */}
          <Services />

          {/* Importing Feature */}
          <Feature />

          <Pricing />

          <AboutUs />

          {/* Importing Footer */}
          <Footer
            contactForm={this.state.contactForm}
            errorMessage={this.state.errorMessage}
            isSubmitting={this.state.isSubmitting}
            toggleModal={this.toggleModal}
            handleSubmit={this.handleSubmit}
            onChange={this.onChange}
            isOpenModal={this.state.isOpenModal}
          />
        </Suspense>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchMyShops: () => dispatch(fetchMyShops()),
})

export default connect(null, mapDispatchToProps)(LandingPage)
