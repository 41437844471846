import { BsFillCheckCircleFill } from 'react-icons/bs'
import { Modal, ModalBody } from 'reactstrap'

const ModalNotifySuccessSubscription = ({ isOpenModalSuccess, toggleModalSuccess }) => (
  <Modal
    isOpen={isOpenModalSuccess}
    toggle={toggleModalSuccess}
    className={'modal-subscription-success'}
  >
    <div
      style={{
        height: 100,
        background: '#27ae60',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <BsFillCheckCircleFill size={50} className={'mb-1'} />
    </div>
    <ModalBody className={'f-16'}>
      <div className='text-center fw-bold f-18'>Payment Success</div>
      <div className='text-center mb-3 mt-2'>
        Your account has been enabled the new subscription success.
      </div>
      <button className={'btn btn-submit text-white d-flex'} onClick={toggleModalSuccess}>
        OK
      </button>
    </ModalBody>
  </Modal>
)

export default ModalNotifySuccessSubscription
