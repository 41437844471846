import orderEventApi from '_api/orderEvent'
import { callNewListOrder, clearDataPurchaseDetailByEid } from '_redux/modules/orderEvent'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_REFUNDED,
  BUYER_ORDER_UNPAID,
  HOST_ORDER_CANCELED,
  HOST_ORDER_UNPAID,
  SINGAPORE_CODE_NO_ADD,
} from '_utils/constant'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const unverifiedOrdersByHost = [HOST_ORDER_CANCELED, HOST_ORDER_UNPAID]
const ordersIncomplete = [BUYER_ORDER_REFUNDED, BUYER_ORDER_CANCELED, BUYER_ORDER_UNPAID]

export default function ModalDetailOrder({
  id,
  order,
  modalDetailOrder,
  toggleDetailOrder,
  handleOpenWarning,
}) {
  const history = useHistory()
  const dispatch = useDispatch()

  const [messageNoteUpdate, setMessageNoteUpdate] = useState('')
  const [canEdit, setCanEdit] = useState(false)
  const [canCancel, setCanCancel] = useState(false)

  const onChangeNote = async (e, ref) => {
    const _data = e.target.value
    try {
      await orderEventApi.updateOrderBuyerInfo(ref, { comment: _data })
      dispatch(callNewListOrder())
      dispatch(clearDataPurchaseDetailByEid(id))
      setMessageNoteUpdate('Updated')
      // setTimeRender((prevState) => prevState + 1)
    } catch (error) {
      const { msgResp } = error
      setMessageNoteUpdate(`Error: ${msgResp}`)
    }
  }
  const handleEditOrder = (e, _order) => {
    e.preventDefault()
    history.push(`/event/detail?eid=${id}&&oid=${_order?.ref}`)
  }
  useEffect(() => {
    setTimeout(() => {
      setMessageNoteUpdate('')
    }, 3000)
  }, [messageNoteUpdate])

  useEffect(() => {
    setCanEdit(false)
    setCanCancel(false)
    if (order?.status && order?.buyerStatus) {
      if (
        unverifiedOrdersByHost.includes(order.status) &&
        ordersIncomplete.includes(order.buyerStatus)
      ) {
        setCanEdit(true)
      }
      if (order?.status !== HOST_ORDER_CANCELED) {
        setCanCancel(true)
      }
    }
  }, [order?.status, order?.buyerStatus])

  return (
    <div>
      <Modal
        isOpen={modalDetailOrder}
        toggle={toggleDetailOrder}
        centered
        className='modal-detail-order'
      >
        <ModalHeader style={{ padding: 0, border: 0, width: '100%', display: 'flex' }}>
          <div
            className='d-flex justify-content-between w-100 fw-normal align-items-start'
            style={{ height: '32px' }}
          >
            <span
              className='pull-left'
              style={{ fontSize: '12px', color: 'rgb(124, 121, 122)', paddingLeft: '5px' }}
            >
              Ref: <span id='orderNo'>{order?.ref?.substring(0, 4)}</span>
            </span>
            {canEdit ? (
              <a
                className='f-12 d-inline-block'
                href='#'
                onClick={(e) => handleEditOrder(e, order)}
                style={{
                  color: 'rgb(124, 121, 122)',
                  paddingRight: '5px',
                }}
              >
                Edit Order
              </a>
            ) : null}
            {canCancel ? (
              <a
                className='f-12'
                href='#'
                onClick={(e) => handleOpenWarning(e, order)}
                style={{
                  color: 'rgb(124, 121, 122)',
                  paddingRight: '5px',
                }}
              >
                Cancel Order
              </a>
            ) : null}
          </div>
          <div className='welcome-group welcome-group--white'>
            <div className='welcome-group__img'>
              <div className='c-image-edit'>
                <div className='c-image'>
                  <img
                    width='100%'
                    height='100%'
                    id='userPhotoUrl'
                    src={
                      order.uPhotoUrl
                        ? order.uPhotoUrl
                        : process.env.REACT_APP_AVATAR_URL + order?.uName
                    }
                    alt=''
                  />
                </div>
                <a
                  id='userWhatsapp'
                  href={`https://wa.me/${SINGAPORE_CODE_NO_ADD}${order?.uPhone}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <span className='icon-whatapp' />
                </a>
              </div>
            </div>
          </div>
          <p className='c-txt-14 text-center d-flex flex-column'>
            <span style={{ fontWeight: 500 }}>{order?.uName}</span>
            <small id='userPhone'>{order?.uPhone}</small>
          </p>
        </ModalHeader>
        <ModalBody>
          <div>
            {order?.allProductPerOrder && order?.allProductPerOrder.length > 0
              ? order?.allProductPerOrder.map((item, _index) => (
                  <div key={_index} className='detail-list-news__item f-16'>
                    <div className='row'>
                      <div className='col-7'>
                        <h3 className='fs-inherit'>{item.pName}</h3>
                        <div
                          id='subItemOf_6176aac14f084d3d096183d9'
                          style={{ fontSize: '0.75rem' }}
                        >
                          {item.pSubItems && item.pSubItems.length > 0
                            ? item.pSubItems.map((_item, idex) => (
                                <>
                                  <span key={idex}>
                                    {_item.name} (+ ${_item.price})
                                  </span>
                                  <br />
                                </>
                              ))
                            : null}
                        </div>
                      </div>
                      <div className='col-3'>
                        <p>${item.pPrice.toFixed(2)}</p>
                      </div>
                      <div className='col-2 text-end'>
                        QTY: <strong>{item.pQuantity}</strong>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>

          <div className='detail-list-news__item'>
            <div className='detail-list-news__des mt-1'>
              <span style={{ fontWeight: 'bold' }}>NOTE</span>
              <textarea
                id='comment2'
                cols={30}
                rows={2}
                className='form-control form-control--80'
                onBlur={(e) => onChangeNote(e, order?.ref)}
                defaultValue={order.comment}
              />
              <span id='commentUpdated' style={{ color: '#51aaaa' }}>
                {messageNoteUpdate}
              </span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className='border-0 pt-0'>
          <div className='text-right price mt-3 mx-0'>
            <p>
              Total Purchase:
              <span>
                $
                <span id='amount' className='ms-0'>
                  {order?.totalFee?.toFixed(2)}
                </span>
              </span>
            </p>
            <p>
              Payment Made:
              <span id='paymentStatus' className='t-done'>
                {order.buyerStatus >= BUYER_ORDER_PAID ? 'Paid' : 'Unpaid'}
              </span>
            </p>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}
