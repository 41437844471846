/* eslint-disable func-names */
/* eslint-disable function-paren-newline */
import { yupResolver } from '@hookform/resolvers/yup'
import { DEFAULT_PRODUCT_DIGISTALL, TYPE_SHOP_WITH_SINGLE_LIST } from '_utils/constant'
import { getCategoryName, getCreateShopData, setCreateShopData } from '_utils/localData'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Spinner } from 'reactstrap'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import ProductList from './components/ProductList'
import './style.scss'

const validationSchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Product name is required'),
      description: Yup.string().nullable().notRequired(),
      price: Yup.number()
        .typeError('Product price must be a number')
        .positive('Product price must be greater than 0')
        .required('Product price is required'),
      // maxQuantity: Yup.number()
      //   .typeError('Max quantity stock is required')
      //   .required('Max quantity stock is required')
      //   .positive('Max quantity must be positive number'),
      defaultQuantity: Yup.number()
        .typeError('Default quantity is required')
        .default(0)
        .required('Default quantity is required'),
      limitPerOrder: Yup.number()
        .typeError('Limit per order is required')
        .required('Limit per order is required')
        .positive('Limit must be positive number'),
      minOrderQty: Yup.number()
        .typeError('Minimum order quantity must be greater than 0')
        .min(1, 'Minimum order quantity must be greater than 0')
        .required('Minimum order quantity must not be blank.')
        .test(
          'lessThanLimitPerOrder',
          'Min order must be less than or equal limit per order',
          function test(value) {
            const { limitPerOrder } = this.parent
            return value <= limitPerOrder
          }
        ),
      photoUrls: Yup.string().notRequired().nullable(),
      subItems: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required('Sub item name is required'),
          required: Yup.boolean().default(false),
          list: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Option name is required'),
              price: Yup.string()
                .typeError('Option price is required')
                .required('Option price is required'),
            })
          ),
        })
      ),
    })
  ),
})

function AddItems() {
  const history = useHistory()
  const dataCreateShop = getCreateShopData()

  const isCheckProduct = !!dataCreateShop?.products
  const dataCreateShopProduct = isCheckProduct
    ? {
        products: dataCreateShop?.products[0]?.items,
      }
    : { products: [DEFAULT_PRODUCT_DIGISTALL] }

  const itemMatchWithCategory = isCheckProduct
    ? {
        products: dataCreateShop?.products.filter((item) => item.category === getCategoryName())[0]
          ?.items || [DEFAULT_PRODUCT_DIGISTALL],
      }
    : { products: [DEFAULT_PRODUCT_DIGISTALL] }

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues:
      dataCreateShop?.shopFrontType === TYPE_SHOP_WITH_SINGLE_LIST
        ? dataCreateShopProduct
        : itemMatchWithCategory,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  })

  const onSubmit = (data) => {
    if (dataCreateShop?.shopFrontType === TYPE_SHOP_WITH_SINGLE_LIST) {
      setCreateShopData({
        ...dataCreateShop,
        products: [
          {
            category: '',
            items: data?.products?.map((item) => ({
              ...item,
              photoUrls: item?.photoUrl,
            })),
          },
        ],
      })

      history.push('/virtual-shop/confirm-shop')
    }
  }

  const handleCancel = () => {
    if (watch('products').length) {
      Swal.fire({
        title: 'Are you exit this page?',
        text: 'You will lose all the data you have entered!',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonColor: '#15cdca',
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          reset()
          history.push('/virtual-shop/create')
        }
      })
    } else {
      history.push('/virtual-shop/create')
    }
  }

  if (!dataCreateShop?.shopFrontType) {
    history.push('/virtual-shop/create')
  }

  return (
    <div className='container'>
      <div className='add-items create-items-page' style={{ marginTop: 0 }}>
        <p className='fw-bold text-center mt-3 title'>Items for sales</p>
        <form className='h-100 d-flex flex-column' onSubmit={handleSubmit(onSubmit)}>
          <ProductList {...{ control, register, getValues, setValue, errors, watch }} />
          <div className='row mt-auto'>
            <div className='col-6'>
              <button
                type='button'
                onClick={handleCancel}
                className='btn btn-cancel btn-primary-outline text-uppercase shadow-none'
              >
                Cancel
              </button>
            </div>
            <div className='col-6'>
              <button
                type='submit'
                className='btn btn-primary rounded-pill text-uppercase text-white'
                disabled={isSubmitting}
              >
                {isSubmitting ? <Spinner color='light' size='sm' /> : 'Save & Next'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddItems
