import { getUserInfo } from '_utils/localData'

const DeliveryAvatar = ({ photoUrl }) => {
  const userInfo = getUserInfo()
  return (
    <img src={photoUrl || process.env.REACT_APP_AVATAR_URL + userInfo.name} className={'avatar'} />
  )
}

export default DeliveryAvatar
