import { OrderRecordContext } from '_contexts/OrderRecordContext'
import { ModalContext } from '_contexts/ToggleModalContext'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { DateRange } from 'react-date-range'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

function OrderDate() {
  const { toggleModal, isModal } = useContext(ModalContext)

  const { onSelectRangeOrderDate, filter } = useContext(OrderRecordContext)

  const [rangeDate, setRangeDate] = useState([
    {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
      key: 'selection',
    },
  ])

  useEffect(() => {
    setRangeDate([
      {
        startDate: filter.orderDate[0].startDate,
        endDate: filter.orderDate[0].endDate,
        key: 'selection',
      },
    ])
  }, [JSON.stringify(filter.orderDate)])

  const formatDate = (date) => {
    const { startDate, endDate, key } = date[0]
    const newStartDate = new Date(startDate?.setHours(0, 0, 0, 0))
    const newEndDate = new Date(endDate?.setHours(23, 59, 59, 999))

    return [
      {
        startDate: newStartDate,
        endDate: newEndDate,
        key,
      },
    ]
  }

  const handleSubmit = () => {
    toggleModal()
    onSelectRangeOrderDate(formatDate(rangeDate))
  }

  const handleCancel = () => {
    toggleModal()
  }

  return (
    <>
      <button
        className='rounded-0 bg-transparent btn-order-date border d-block'
        style={{ width: 'fit-content' }}
        onClick={toggleModal}
      >
        <span>Order Date</span>
      </button>
      <Modal toggle={toggleModal} isOpen={isModal} className='modal-calendar-orders'>
        <ModalBody>
          <div className='select-range-date'>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setRangeDate([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={rangeDate}
              rangeColors={['#15cdca']}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className='d-flex range-date-button flex-row-reverse'>
            <button className='btn btn-submit rounded-pill shadow-none' onClick={handleSubmit}>
              Ok
            </button>
            <button className='btn btn-cancel me-2' onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

OrderDate.propTypes = {
  getRangeOrderDate: PropTypes.func,
}

export default OrderDate
