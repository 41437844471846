import React from 'react'

import cancelImage from '_images/cancel.png'
import classNames from 'classnames'

function RenderListImage({ listImageShop, handleOnRemoveImg, onUserImageUploaded, noImage }) {
  const handleUploadImage = (event) => {
    onUserImageUploaded(event)
  }

  const handleCancelImage = (index) => {
    handleOnRemoveImg(index)
  }

  const renderNoImage = () => (
    <div className='upload-image c-form d-flex p-3 align-items-center justify-content-center flex-column rounded-0 shadow-none w-100 h-100'>
      <img src={noImage} alt='' />
      <div className='upload-image-txt' style={{ display: 'contents' }}>
        <span>{listImageShop.length > 0 ? 'Upload more shop image' : 'Upload shop image'}</span>
      </div>
      <div className='u-file-upload__item'>
        <div className='u-file-upload__wrap'>
          <a className='c-upload preview-images-zone'>
            <input
              type='file'
              accept='image/*'
              className='inputFile'
              onChange={(e) => handleUploadImage(e)}
              readOnly
              multiple
            />
          </a>
        </div>
      </div>
    </div>
  )

  const renderImageItem = (item, index) => (
    <div key={item.url} className='col-3 mb-2'>
      <div className='upload-image w-100 h-100'>
        <img src={item.url} alt='' className='main-image mx-auto d-block' />
        <img src={cancelImage} className='cancel-btn' onClick={() => handleCancelImage(index)} />
      </div>
    </div>
  )

  return (
    <div className={classNames('row align-items-stretch w-100 list-image')}>
      {listImageShop.length === 0 ? (
        <div className='col-12'>{renderNoImage()}</div>
      ) : (
        <>
          {listImageShop.map((item, index) => renderImageItem(item, index))}
          <div className='col-3 mb-2'>{renderNoImage()}</div>
        </>
      )}
    </div>
  )
}

export default RenderListImage
