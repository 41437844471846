import { DeliveryContext } from '_contexts/DeliveryContext'
import { useContext } from 'react'

const DeliveryPickup = () => {
  const { setCheckedIncludePickup, checkedIncludePickup, loading } = useContext(DeliveryContext)
  return (
    <div className='mb-3 d-flex align-items-center'>
      <input
        type='checkbox'
        id='includePickup'
        className='custom-checkbox-shop'
        checked={checkedIncludePickup}
        onChange={(e) => setCheckedIncludePickup(e.target.checked)}
      />
      <label htmlFor='includePickup' className='cursor-pointer mb-0'>
        Include Pickup
      </label>
      {checkedIncludePickup && loading && (
        <span className='ms-1 text-info f-12 mt-auto'>Getting list pick-up order...</span>
      )}
    </div>
  )
}

export default DeliveryPickup
