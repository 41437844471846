import LoadingOverlay from '_components/LoadingOverlay'
import { fetchListShopDeleted, fetchMyShops } from '_redux/modules/shop'
import { LIST_SHOP, LIST_SHOP_DELETED } from '_utils/constant'
import React, { useEffect, useMemo, useState } from 'react'
import { FaStore, FaStoreSlash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import ListShops from './components/ListShops'
import ListShopsDeleted from './components/ListShopsDeleted'
import './style.scss'

function MyShops() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(LIST_SHOP)

  const { myShops } = useSelector((state) => state.shop)

  useEffect(() => {
    let isMounted = true

    const fetchData = async () => {
      try {
        setLoading(true)
        await Promise.all([dispatch(fetchMyShops()), dispatch(fetchListShopDeleted())])
      } catch (error) {
        if (isMounted) {
          addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
        }
      } finally {
        if (isMounted) {
          setLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      isMounted = false
    }
  }, [])

  const myShopsSorted = useMemo(
    () =>
      myShops.sort((a, b) => {
        const aDate = new Date(a.createdAt).getTime()
        const bDate = new Date(b.createdAt).getTime()
        return bDate - aDate
      }),
    [JSON.stringify(myShops)]
  )

  if (loading) return <LoadingOverlay style={{ marginTop: 50 }} />

  return (
    <div
      className='my-shops'
      // style={{
      //   margin: '0 -1rem',
      // }}
    >
      <Nav tabs className='border-0 column-gap-2 flex-nowrap'>
        <NavItem className='border-0'>
          <NavLink
            className={`my-shop-nav-link cursor-pointer custom-tab border-0 rounded-2 d-flex align-items-center justify-content-center w-100 ${
              activeTab === LIST_SHOP ? 'active' : ''
            }`}
            onClick={() => setActiveTab(LIST_SHOP)}
          >
            <span>List Shops</span>
            <FaStore className='ms-1' size={20} />
          </NavLink>
        </NavItem>
        <NavItem className='border-0'>
          <NavLink
            className={`my-shop-nav-link cursor-pointer custom-tab border-0 rounded-2 d-flex align-items-center justify-content-center w-100 ${
              activeTab === LIST_SHOP_DELETED ? 'active' : ''
            }`}
            onClick={() => setActiveTab(LIST_SHOP_DELETED)}
          >
            <span>List Shops Deleted</span>
            <FaStoreSlash className='ms-1' size={20} />
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={LIST_SHOP}>
          <Row>
            <Col sm='12'>
              <ListShops myShops={myShopsSorted} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={LIST_SHOP_DELETED}>
          <Row>
            <Col sm='12'>
              <ListShopsDeleted />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default MyShops
