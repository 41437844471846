/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
import deliveryApi from '_api/delivery'
import deliveryEventApi from '_api/deliveryEvent'
import { DeliveryContext } from '_contexts/DeliveryContext'
import { useHover } from '_hooks/useHover'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_UNPAID,
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_CANCEL_STATUS,
  DELIVERY_DELIVERED_STATUS,
  DELIVERY_EVENT_HOST_CANCELLED,
  DELIVERY_EVENT_HOST_PAID,
  DELIVERY_EVENT_HOST_UNPAID,
  DELIVERY_PENDING_STATUS,
  DELIVERY_UNPAID_STATUS,
} from '_utils/constant'
import { convertToCurrentGMT } from '_utils/functions/converter'
import moment from 'moment'
import React, { useContext } from 'react'
import CurrencyFormat from 'react-currency-format'
import { AiFillGift } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import orderEventApi from '../../../api/orderEvent'

function getFulfillmentClass(status, deliveryIdParam) {
  if (deliveryIdParam) {
    const statusMap = {
      [DELIVERY_EVENT_HOST_CANCELLED]: 'bg-secondary border-secondary',
      [DELIVERY_EVENT_HOST_UNPAID]: 'bg-warning border-warning',
      [DELIVERY_EVENT_HOST_PAID]: 'bg-success border-success',
    }

    return statusMap[status] || 'bg-light border-light'
  }

  const statusMap = {
    [DELIVERY_CANCEL_STATUS]: 'bg-secondary border-secondary',
    [DELIVERY_UNPAID_STATUS]: 'bg-warning border-warning',
    [DELIVERY_PENDING_STATUS]: 'bg-info border-info',
    [DELIVERY_EVENT_HOST_PAID]: 'bg-success border-success',
  }

  return statusMap[status] || 'bg-light border-light'
}

const disabledStyle = {
  pointerEvents: 'none',
  opacity: 0.5,
  filter: 'grayscale(100%)',
}

function FulfillmentItem({
  fulfillment,
  fulfillmentIndex,
  maxLengthFulfillment,
  isTableScrollBar,
}) {
  const {
    swapUpFulfillment,
    swapDownFulfillment,
    updateStatusFulfillment,
    deliveryIdParam,
    openPaymentScreenDeliveryEvent,
  } = useContext(DeliveryContext)

  const { addToast } = useToasts()
  const [hoverArrowUp, isUpHovered] = useHover()
  const [hoverArrowDown, isDownHovered] = useHover()

  const { loadingGetListOrderDeliveryCombinedPayment } = useSelector((state) => state.deliveryEvent)

  const isDeliveryZones = !!fulfillment?.deliveryZones

  const updateFulfillmentStatusAPI = async (_ref, _data, typeFulfillment) => {
    if (typeFulfillment === 'delivery') {
      try {
        let message = ''
        updateStatusFulfillment(_ref, _data.status, _data.bookerStatus, isDeliveryZones)

        if (isDeliveryZones) {
          if (!fulfillment?.combinedPaymentRef) {
            const { msgResp } = await deliveryEventApi.updateDeliveryBookingStatus(_ref, _data)
            message = msgResp
          } else {
            const postData = {
              combinedPaymentRef: _ref,
              placerStatus: _data.bookerStatus,
              status: _data.status,
            }
            const { msgResp } = await orderEventApi.updateInvoicesStatus(postData)
            message = msgResp
          }
        } else {
          const { msgResp } = await deliveryApi.updateDeliveryStatus(_ref, _data)

          message = msgResp
        }
        addToast(message, { appearance: 'success', autoDismiss: true })
      } catch (error) {
        const { msgResp } = error
        addToast(msgResp || error.message, { appearance: 'error', autoDismiss: true })
      }
    } else {
      try {
        updateStatusFulfillment(_ref, _data.status, _data.bookerStatus)
        const { msgResp } = await deliveryApi.updatePickupStatus(_ref, {
          status: _data.status,
          bookerStatus: _data.bookerStatus,
        })
        addToast(msgResp, { appearance: 'success', autoDismiss: true })
      } catch (error) {
        const { msgResp } = error
        addToast(msgResp || error.message, { appearance: 'error', autoDismiss: true })
      }
    }
  }

  const onChangeSelect = async (e, _fulfillment) => {
    try {
      const { ref, bookerStatus, combinedPaymentRef } = _fulfillment

      const statusSelected = Number(e.target.value)
      const hasCombinedPaymentRef = _fulfillment?.combinedPaymentRef

      const statusShopMap = {
        [BUYER_ORDER_UNPAID]: {
          [DELIVERY_DELIVERED_STATUS]: BUYER_ORDER_PAID,
          [DELIVERY_CANCEL_STATUS]: BUYER_ORDER_CANCELED,
          [DELIVERY_PENDING_STATUS]: DELIVERY_PENDING_STATUS,
          [DELIVERY_UNPAID_STATUS]: BUYER_ORDER_UNPAID,
        },
        [BUYER_ORDER_PAID]: {
          [DELIVERY_CANCEL_STATUS]: BUYER_ORDER_CANCELED,
          [DELIVERY_UNPAID_STATUS]: BUYER_ORDER_UNPAID,
          [DELIVERY_DELIVERED_STATUS]: BUYER_ORDER_PAID,
          [DELIVERY_PENDING_STATUS]: DELIVERY_PENDING_STATUS,
        },
        [BUYER_ORDER_CANCELED]: {
          [DELIVERY_CANCEL_STATUS]: BUYER_ORDER_CANCELED,
          [DELIVERY_UNPAID_STATUS]: BUYER_ORDER_UNPAID,
          [DELIVERY_DELIVERED_STATUS]: BUYER_ORDER_PAID,
          [DELIVERY_PENDING_STATUS]: DELIVERY_PENDING_STATUS,
        },
      }

      const statusDeliveryEventMap = {
        [DELIVERY_BUYER_PAID]: {
          [DELIVERY_EVENT_HOST_PAID]: DELIVERY_BUYER_PAID,
          [DELIVERY_EVENT_HOST_CANCELLED]: DELIVERY_EVENT_HOST_CANCELLED,
          [DELIVERY_EVENT_HOST_UNPAID]: !hasCombinedPaymentRef
            ? DELIVERY_EVENT_HOST_UNPAID
            : DELIVERY_BUYER_PAID,
        },
        [DELIVERY_BUYER_UNPAID]: {
          [DELIVERY_EVENT_HOST_CANCELLED]: DELIVERY_EVENT_HOST_CANCELLED,
          [DELIVERY_EVENT_HOST_UNPAID]: DELIVERY_BUYER_UNPAID,
          [DELIVERY_EVENT_HOST_PAID]: DELIVERY_BUYER_PAID,
        },
        [DELIVERY_EVENT_HOST_CANCELLED]: {
          [DELIVERY_EVENT_HOST_CANCELLED]: DELIVERY_EVENT_HOST_CANCELLED,
          [DELIVERY_EVENT_HOST_UNPAID]: DELIVERY_BUYER_UNPAID,
          [DELIVERY_EVENT_HOST_PAID]: DELIVERY_BUYER_PAID,
        },
      }

      const mappedBookerStatus = !isDeliveryZones
        ? statusShopMap[bookerStatus]
        : statusDeliveryEventMap[bookerStatus]

      const data = {
        status: statusSelected,
        bookerStatus: mappedBookerStatus ? mappedBookerStatus[statusSelected] : bookerStatus,
      }

      const typeFulfillment = _fulfillment.pickupBookingTimestamp ? 'pickup' : 'delivery'

      await updateFulfillmentStatusAPI(combinedPaymentRef || ref, data, typeFulfillment)
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp || error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleSwapUp = (_delivery) => {
    swapUpFulfillment(_delivery)
  }

  const renderTimeFulfillment = () => {
    if (fulfillment?.pickupBookingTime) {
      const convertTimeToArray = fulfillment?.pickupBookingTime?.split('-')
      const newTimeCurrentGMT = convertTimeToArray
        ?.map((time) => {
          const currentTime = convertToCurrentGMT(
            `${fulfillment?.pickupBookingDate?.date} ${time.trim()}`
          )
          return moment(currentTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
        })
        ?.join(' - ')

      return newTimeCurrentGMT
    }

    if (fulfillment?.deliveryBookingTime) {
      const convertTimeToArray = fulfillment?.deliveryBookingTime?.split('-')
      const newTimeCurrentGMT = convertTimeToArray
        ?.map((time) => {
          const currentTime = convertToCurrentGMT(
            `${fulfillment?.deliveryBookingDate?.date} ${time.trim()}`
          )
          return moment(currentTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
        })
        ?.join(' - ')

      return newTimeCurrentGMT
    }
    return moment.unix(fulfillment?.createdAt).format('HH:mm')
  }

  const getOptionValue = (defaultValue, eventHostValue) =>
    !isDeliveryZones ? defaultValue : eventHostValue

  const optionValues = {
    cancelled: getOptionValue(DELIVERY_CANCEL_STATUS, DELIVERY_EVENT_HOST_CANCELLED),
    unpaid: getOptionValue(DELIVERY_UNPAID_STATUS, DELIVERY_EVENT_HOST_UNPAID),
    pending: DELIVERY_PENDING_STATUS,
    delivered: getOptionValue(DELIVERY_DELIVERED_STATUS, DELIVERY_EVENT_HOST_PAID),
  }

  const optionLabels = {
    delivered: !isDeliveryZones ? 'Delivered' : 'Verified',
  }

  return (
    <div
      className={`${fulfillmentIndex % 2 !== 0 ? 'bg-light' : ''} ${
        isTableScrollBar ? 'item-custom-col flex-nowrap' : ''
      } row align-items-center p-3 f-12`}
    >
      <div className='col text-center'>
        <p className='mb-0 fw-bold' style={{ color: '#079d9a' }}>
          {fulfillmentIndex + 1}
        </p>
        <div className='d-flex flex-column align-items-center'>
          {fulfillmentIndex !== 0 && (
            <div
              className='arrow-up cursor-pointer'
              ref={hoverArrowUp}
              onClick={() => handleSwapUp(fulfillment)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill={isUpHovered ? '#B5B1B3' : '#D2CFD0'}
              >
                <path d='M12 3l12 18h-24z' />
              </svg>
            </div>
          )}
          {fulfillmentIndex !== maxLengthFulfillment && (
            <div
              className='arrow-down cursor-pointer'
              ref={hoverArrowDown}
              onClick={() => swapDownFulfillment(fulfillment)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill={isDownHovered ? '#B5B1B3' : '#D2CFD0'}
              >
                <path d='M12 21l-12-18h24z' />
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className='col'>
        <div className='d-flex flex-column'>
          {fulfillment?.giftRecipientInfo ? (
            <>
              <span>{fulfillment.giftRecipientInfo?.name}</span>
              <span>{fulfillment.giftRecipientInfo?.phone}</span>
              <AiFillGift color='red' size={18} className='mt-2' />
            </>
          ) : (
            <>
              <span>{fulfillment.uName}</span>
              <span>{fulfillment.uPhone}</span>
            </>
          )}
        </div>
      </div>
      <div className='col'>
        {fulfillment?.listOrder?.map((item) => (
          <p key={item.id} className='mb-0'>
            {item.pName} ({item.pQuantity})
          </p>
        ))}

        {fulfillment?.deliveryZones && (
          <>
            {fulfillment?.dzName && <span className='mb-0'>{fulfillment.dzName}</span>}
            {fulfillment?.dzPrice && (
              <CurrencyFormat
                value={fulfillment.dzPrice}
                displayType='text'
                thousandSeparator
                prefix='$'
                renderText={(value) => <span className='ms-1 fw-bold'>({value})</span>}
              />
            )}
          </>
        )}
      </div>
      <div className='col'>
        <div className='d-flex flex-column'>
          <span className='fw-bold mb-2'>
            {fulfillment?.pickupBookingTimestamp ? 'Pick-up' : 'Delivery'}
          </span>
          <span className='mb-2'>
            {fulfillment?.pickupOptionName || fulfillment.deliveryOptionName}
          </span>
          <span>
            {fulfillment?.giftRecipientInfo
              ? fulfillment.giftRecipientInfo?.address
              : fulfillment?.pickupAddress || fulfillment?.uAddress || fulfillment?.address}
          </span>
        </div>
      </div>
      <div className='col'>
        <div>
          Fulfill date: <br />
          <span className='fw-bold text-orange'>
            {fulfillment?.pickupBookingDate?.date ||
              fulfillment?.deliveryBookingDate?.date ||
              fulfillment?.createdDate}
          </span>
        </div>
        <div className='mt-1'>
          Time fulfillment: <br />{' '}
          <span className='fw-bold text-orange'>{renderTimeFulfillment()}</span>
        </div>
      </div>
      {deliveryIdParam && (
        <div className='col'>
          {fulfillment.bookerStatus === DELIVERY_BUYER_UNPAID && (
            <span className='n-paid text-orange' style={{ paddingRight: 0 }}>
              Not Paid
            </span>
          )}
          {fulfillment.bookerStatus === DELIVERY_BUYER_PAID && (
            <a
              href='#'
              className={fulfillment?.combinedPaymentRef ? 'paid-combined' : 'paid'}
              data-toggle='modal'
              data-target='#modal-paid'
              onClick={(e) => {
                if (loadingGetListOrderDeliveryCombinedPayment) return
                openPaymentScreenDeliveryEvent(e, fulfillment)
              }}
              style={loadingGetListOrderDeliveryCombinedPayment ? disabledStyle : {}}
            >
              Paid
            </a>
          )}
          {fulfillment.bookerStatus === DELIVERY_EVENT_HOST_CANCELLED && (
            <span className=''>Cancelled</span>
          )}
          {fulfillment.bookerStatus === DELIVERY_BUYER_REFUND && <span className=''>Refunded</span>}
        </div>
      )}
      <div className='col status-col'>
        <select
          className={`${getFulfillmentClass(
            fulfillment.status,
            deliveryIdParam
          )} form-select-sm rounded text-white`}
          value={fulfillment.status}
          onChange={(e) => onChangeSelect(e, fulfillment)}
        >
          <option value={optionValues.cancelled}>Cancelled</option>
          <option value={optionValues.unpaid}>Unpaid</option>
          {!isDeliveryZones && <option value={optionValues.pending}>Pending</option>}
          <option value={optionValues.delivered}>{optionLabels.delivered}</option>
        </select>
      </div>
    </div>
  )
}
export default FulfillmentItem
