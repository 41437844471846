export const SELECTED_SHOP = 'SELECTED_SHOP'
export const CREATE_SHOP = 'CREATE_SHOP'
export const FETCH_SHOP_OPENING = 'FETCH_SHOP_OPENING'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const FETCH_SHOP_DETAILS = 'FETCH_SHOP_DETAILS'
export const FETCH_CATEGORY = 'FETCH_CATEGORY'
export const SET_SHOP_ID = 'SET_SHOP_ID'
export const ADD_PURCHASE = 'ADD_PURCHASE'
export const FETCH_LIST_SUBSCRIPTION_BY_SHOP = 'FETCH_LIST_SUBSCRIPTION_BY_SHOP'
export const CLEAR_PICKUP_OPTION = 'CLEAR_PICKUP_OPTION'
export const CLEAR_DELIVERY_OPTION = 'CLEAR_DELIVERY_OPTION'
export const SET_VIEW_DEFAULT = 'SET_VIEW_DEFAULT'
export const FETCH_MY_SHOPS = 'FETCH_MY_SHOPS'
export const FETCH_LIST_SHOP_DELETED = 'FETCH_LIST_SHOP_DELETED'
export const DELETE_MY_SHOP = 'DELETE_MY_SHOP'
export const RESTORE_MY_SHOP = 'RESTORE_MY_SHOP'
export const FETCH_SHOP_SELECTED = 'FETCH_SHOP_SELECTED'
export const GET_SHOP_DETAIL_BY_HOST = 'GET_SHOP_DETAIL_BY_HOST'
export const SET_LIST_SHOP_ACTIVE = 'SET_LIST_SHOP_ACTIVE'
export const SET_LIST_SHOP_INACTIVE = 'SET_LIST_SHOP_INACTIVE'
export const CLEAR_SHOP_DETAIL = 'CLEAR_SHOP_DETAIL'
export const SELECT_THEME_COLOR = 'SELECT_THEME_COLOR'
export const UPDATE_SHOP_DETAIL = 'UPDATE_SHOP_DETAIL'
export const CLEAR_MY_SHOPS = 'CLEAR_MY_SHOPS'
export const CLEAR_SHOP_ACTIVE = 'CLEAR_SHOP_ACTIVE'
export const CLEAR_SHOP_INACTIVE = 'CLEAR_SHOP_INACTIVE'
export const UPDATE_SHOP_IN_MY_SHOPS = 'UPDATE_SHOP_IN_MY_SHOPS'

export const UPDATE_MY_SHOPS = 'UPDATE_MY_SHOPS'
