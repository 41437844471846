import { ManageEventContext } from '_contexts/EventContext'
import { LIMIT_TEXT, TURN_OFF } from '_utils/constant'
import { shortDescription } from '_utils/function'
import { convertToCurrentGMT } from '_utils/functions/converter'
import { getUserInfo } from '_utils/localData'
import React, { useContext, useState } from 'react'
import ReactImageGallery from 'react-image-gallery'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import HeaderEventInfo from '../components/HeaderEventInfo'

const styleDisabledCard = {
  filter: 'grayScale(1)',
  pointerEvents: 'none',
}
const styleButtonDelete = {
  padding: '5px',
  height: '50px',
  position: 'relative',
  zIndex: 2,
}
const styleBackgroundButtonActionsDisabled = {
  backgroundColor: 'rgb(128 128 128)',
}
const styleDisabledClick = {
  pointerEvents: 'none',
}
const styleOverlayDelete = {
  position: 'relative',
}
const styleOverlay = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#dc3545',
  zIndex: 1,
  borderTopRightRadius: '5px',
  borderBottomRightRadius: '5px',
}

export const EventButtons = ({ sortedButtons, isEventClosed }) => (
  <div
    className='group-list__footer d-flex align-items-center w-100 rounded'
    style={isEventClosed ? styleBackgroundButtonActionsDisabled : {}}
  >
    {sortedButtons.map((button) => (
      <div
        key={button.key}
        className='flex-grow-1 wrap-button-item'
        style={button.key === 'deleteEvent' && isEventClosed ? styleOverlayDelete : {}}
      >
        {button.key === 'deleteEvent' && isEventClosed && <div style={styleOverlay}></div>}
        <button className={button.className} onClick={button.onClick} style={button.style}>
          {button.label}
        </button>
      </div>
    ))}
  </div>
)

export default function EventItem({ event }) {
  const history = useHistory()
  const userInfo = getUserInfo()

  const { onReopenEvent, view, toggleBroadcastModal, onDeleteEvent } =
    useContext(ManageEventContext)

  const { myShops, selectedShop } = useSelector((state) => state.shop)

  const [isShowMore, setIsShowMore] = useState(false)

  const listImages = event?.photoUrls?.map((item) => ({ original: item })) || []
  const shopName = myShops.find((shop) => shop.id === event?.shopId)?.shopName
  const isGridView = view === 'grid'
  const isEventClosed = event.status === TURN_OFF
  const styleCard = {
    width: isGridView ? 'calc(50% - 10px)' : '50%',
    maxWidth: isGridView ? 'calc(50% - 10px)' : '50%',
    margin: isGridView ? '' : '0 auto 1rem auto',
  }

  const showMore = () => {
    setIsShowMore(true)
  }

  const directToEventDetail = (selectedEvent) => {
    window.open(
      `${process.env.REACT_APP_COBEE_URL}/event/detail-shared?eid=${selectedEvent.id}`,
      '_blank'
    )
  }

  const directToEditEvent = (selectedEvent) => {
    history.push(`/admin-console/${selectedShop?.shopSlug}/event/update?id=${selectedEvent.id}`)
  }

  const directToCheckOrders = (selectedEvent) => {
    history.push(`/admin-console/${selectedShop?.shopSlug}/order-records?eid=${selectedEvent.id}`)
  }

  const buttonsStatusOpen = [
    {
      key: 'viewEvent',
      className: 'btn bg-transparent shadow-none rounded-0',
      onClick: () => window.open(process.env.REACT_APP_COBEE_URL, '_blank'),
      style: isEventClosed ? styleDisabledClick : {},
      label: 'View Event',
      order: 1,
    },
    {
      key: 'editEvent',
      className: 'btn bg-transparent shadow-none rounded-0',
      onClick: () => directToEditEvent(event),
      label: 'Edit Event',
      order: 2,
    },
    {
      key: 'broadcast',
      className: 'btn bg-transparent shadow-none rounded-0',
      onClick: () => toggleBroadcastModal(event),
      label: 'Broadcast',
      order: 3,
    },
    {
      key: 'checkOrders',
      className: 'btn bg-transparent shadow-none rounded-0',
      onClick: () => directToCheckOrders(event),
      label: 'Check Orders',
      order: 4,
    },
  ]

  const buttonsStatusClosed = [
    {
      key: 'viewEvent',
      className: 'btn bg-transparent shadow-none rounded-0',
      onClick: () => window.open(process.env.REACT_APP_COBEE_URL, '_blank'),
      style: isEventClosed ? styleDisabledClick : {},
      label: 'View Event',
      order: 1,
    },
    {
      key: 'checkOrders',
      className: 'btn bg-transparent shadow-none rounded-0',
      onClick: () => directToCheckOrders(event),
      label: 'Check Orders',
      order: 2,
    },
    {
      key: 'reopenEvent',
      className: 'btn bg-transparent shadow-none rounded-0',
      onClick: () => onReopenEvent(event),
      label: 'Re-open',
      order: 3,
      condition: isEventClosed,
    },
    {
      key: 'deleteEvent',
      className: 'btn bg-transparent shadow-none rounded-0 text-white',
      onClick: () => onDeleteEvent(event),
      style: styleButtonDelete,
      label: 'Delete',
      order: 4,
      condition: isEventClosed,
    },
  ]

  const buttons = isEventClosed ? buttonsStatusClosed : buttonsStatusOpen

  return (
    <div className='group-list__item event-item flex-column p-0' style={styleCard}>
      <div className='p-3 w-100' style={isEventClosed ? styleDisabledCard : {}}>
        <HeaderEventInfo
          createdUserPhotoUrl={event?.createdUserPhotoUrl}
          createdUserName={event?.createdUserName || userInfo?.name}
          eClosingTime={convertToCurrentGMT(event?.closingTime)}
          eTotalOrderCount={event?.totalOrderCount}
          shopName={shopName}
        />
        <div className='group-list__des'>
          {listImages?.length > 0 && (
            <ReactImageGallery
              items={listImages}
              lazyLoad
              autoPlay
              showPlayButton={false}
              showNav={false}
              showBullets={true}
              showThumbnails={false}
              onClick={() => directToEventDetail(event)}
              additionalClass={
                view === 'grid' ? 'image-gallery img--grid' : 'image-gallery img--list'
              }
            />
          )}

          <h4>
            <button
              className={'bg-transparent border-0 text-blue'}
              onClick={() => directToEventDetail(event)}
            >
              {event?.title}
            </button>
          </h4>
          <p className='paragraph-pre-wrap mb-0'>
            <button
              className={'bg-transparent border-0 text-start'}
              onClick={() => directToEventDetail(event)}
            >
              {isShowMore
                ? event?.description
                : shortDescription({ description: event?.description })}
            </button>
            {!isShowMore &&
            event?.description &&
            event?.description.split(' ').length > LIMIT_TEXT ? (
              <span className='read-more' onClick={showMore}>
                More
              </span>
            ) : null}
          </p>
        </div>
      </div>

      <EventButtons
        sortedButtons={buttons.sort((a, b) => a.order - b.order)}
        isEventClosed={isEventClosed}
      />
    </div>
  )
}
