import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

function ModalProductCheckbox(props) {
  const {
    isOpenModalProductCheckboxOpen,
    toggleModalProductCheckbox,
    listProductSelected,
    setListProductSelected,
  } = props
  const { listProduct } = useSelector((state) => state.product)
  const sortedListProduct = [...listProduct].sort((a, b) =>
    moment(a.createdAt).diff(moment(b.createdAt))
  )

  const [tempSelectedProducts, setTempSelectedProducts] = useState(listProductSelected)

  useEffect(() => {
    setTempSelectedProducts(listProductSelected)
  }, [listProductSelected])

  const isProductSelected = (productId) =>
    tempSelectedProducts.some((selectedProduct) => selectedProduct.id === productId)

  const handleSelectProduct = (selectedProduct) => {
    if (isProductSelected(selectedProduct.id)) {
      setTempSelectedProducts(
        tempSelectedProducts.filter((product) => product.id !== selectedProduct.id)
      )
    } else {
      setTempSelectedProducts([...tempSelectedProducts, selectedProduct])
    }
  }

  const handleSyncData = () => {
    setListProductSelected(tempSelectedProducts)
    toggleModalProductCheckbox()
  }

  return (
    <Modal
      isOpen={isOpenModalProductCheckboxOpen}
      toggle={toggleModalProductCheckbox}
      centered
      className='modal-list-prod-checkbox f-16'
    >
      <ModalHeader>
        <span className='c-txt-gra sync-data-title'>Select Fulfill Product</span>
      </ModalHeader>
      <ModalBody>
        <ul className='list-unstyled d-flex align-items-center flex-wrap gap-3'>
          {sortedListProduct.map((product) => (
            <li key={product.id}>
              <label htmlFor={product.id} className='cursor-pointer'>
                <input
                  id={product.id}
                  type='checkbox'
                  checked={isProductSelected(product.id)}
                  onChange={() => handleSelectProduct(product)}
                  className='me-2 custom-checkbox-shop'
                />
                {product.name}
              </label>
            </li>
          ))}
        </ul>
        {tempSelectedProducts.length === 0 && (
          <div className='alert alert-warning' role='alert'>
            Select at least 1 product
          </div>
        )}
        <div className='sync-data-btn'>
          <button
            onClick={handleSyncData}
            className='btn btn--org btn--org02 w-100'
            disabled={tempSelectedProducts.length === 0}
          >
            Sync data
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalProductCheckbox
