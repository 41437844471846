import React from 'react'
import { Controller } from 'react-hook-form'

function PickupTimeSlot({
  checkedTimeSlot,
  handleAddTimeSlot,
  handleCheckTimeSlot,
  index,
  control,
}) {
  return (
    <div className='row align-items-center'>
      <div className='col-5'>
        <span className='d-inline-block'>Add Time Slot</span>
      </div>
      <div className='col-7'>
        <div className='form-check form-switch'>
          <input
            id={`pickup-time-slot-${index}`}
            className='form-check-input mt-0 check__from cursor-pointer'
            type='checkbox'
            role='switch'
            checked={checkedTimeSlot}
            onChange={(e) => handleAddTimeSlot(e)}
          />
        </div>
      </div>
      {checkedTimeSlot && (
        <>
          <div className='w-100'></div>
          <div className='col-8 mx-auto mt-2 d-flex flex-wrap'>
            <Controller
              name='timeSlots'
              control={control}
              render={({ field: { value, onChange } }) =>
                value.map((time, i) => (
                  <div className='form-check' key={i}>
                    <label
                      className='form-check-label cursor-pointer'
                      htmlFor={`radioPickup-${index}-${i}`}
                    >
                      <input
                        className='form-check-input cursor-pointer'
                        type='radio'
                        onChange={(e) => handleCheckTimeSlot(e, i, onChange)}
                        checked={time.isCheck}
                        name='radioPickup'
                        id={`radioPickup-${index}-${i}`}
                      />
                      <span
                        style={{
                          marginTop: 3,
                          display: 'inline-block',
                        }}
                      >
                        {time.title}
                      </span>
                    </label>
                  </div>
                ))
              }
            />
          </div>
          {/* <div className='w-100'></div>
          <div className='d-flex align-items-center border-top
          border-2 pt-2 mt-2 col-8 mx-auto fw-semibold'>
            <span className='text-black'>Limit Orders:</span>
            <input type='text' className='form-control-sm border w-25 mx-2' />
            <span>Every 15 mins</span>
          </div> */}
        </>
      )}
    </div>
  )
}

export default PickupTimeSlot
