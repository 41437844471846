/* eslint-disable no-shadow */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

export default function TableHeaderWithSort({
  headers,
  onSorting,
  defaultSortingField = '',
  defaultSortingOrder = 'desc',
  className = '',
  style = {},
  scrollBar = false,
  getIsTableHeaderScrollBar = () => {},
  headerScrollBarRef,
}) {
  const [sortingField, setSortingField] = useState(defaultSortingField)
  const [sortingOrder, setSortingOrder] = useState(defaultSortingOrder)

  useEffect(() => {
    scrollBar ? getIsTableHeaderScrollBar(true) : getIsTableHeaderScrollBar(false)
  }, [scrollBar])

  const onSortingChange = (field, type) => {
    const order = field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc'

    setSortingField(field)
    setSortingOrder(order)
    onSorting(field, order, type)
  }

  return (
    <>
      <div
        className={`${className} ${
          scrollBar ? 'overflow-x-scroll overflow-y-auto flex-nowrap' : ''
        }`}
        style={style}
        ref={headerScrollBarRef}
      >
        {headers
          .filter((item) => item)
          .map(({ name, field, className, type, sortable }) => (
            <div
              className={className}
              key={name}
              onClick={() => (sortable ? onSortingChange(field, type) : null)}
              style={{
                ...(sortable ? { cursor: 'pointer' } : {}),
                minWidth:
                  name.toLowerCase() === 'status'
                    ? scrollBar
                      ? '100px'
                      : 'auto'
                    : scrollBar
                    ? '150px'
                    : 'auto',
              }}
            >
              {name}
              {sortingField && sortingField === field && (
                <i className={sortingOrder === 'desc' ? 'arrow_carrot-down' : 'arrow_carrot-up'} />
              )}
            </div>
          ))}
      </div>
    </>
  )
}

TableHeaderWithSort.propTypes = {
  headers: PropTypes.array,
  onSorting: PropTypes.func,
  defaultSortingField: PropTypes.string,
  defaultSortingOrder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}
