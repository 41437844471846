import ReactDatePicker from 'react-datepicker'
import { FaRegCalendar } from 'react-icons/fa'

const DatePicker = ({ startDate, onChangeDate }) => (
  <div className='mb-3'>
    <label htmlFor='datePicker' className='form-label'>
      Choose date:
    </label>

    <ReactDatePicker
      id='date-picker'
      className='form-control date-picker text-black border-0'
      dateFormat={'dd/MM/yyyy'}
      selected={startDate}
      value={startDate}
      onChange={onChangeDate}
      showIcon
      icon={<FaRegCalendar />}
    />
  </div>
)

export default DatePicker
