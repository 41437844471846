import uploadApi from '_api/upload'
import InputField from '_components/InputField'
import noImage from '_images/noimage02.svg'
import { DEFAULT_SUB_ITEM, SHIFT_DOWN, SHIFT_UP } from '_utils/constant'
import React, { useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'
import eventApi from '../../../api/event'
import useQuery from '../../../hooks/useQuery'
import EditableSelect from './EditableSelect'
import InputProduct from './InputProduct'
import ProductSettingModal from './ProductSettingModal'
import SubItem from './SubItem'

const ProductItem = (props) => {
  const {
    productIndex,
    product,
    totalListProduct,
    control,
    register,
    removeProduct,
    insertProduct,
    swapProduct,
    watch,
    setValue,
    errors,
    getValues,
  } = props
  const {
    fields,
    remove: removeSubItem,
    insert: insertSubItem,
    append: appendSubItem,
  } = useFieldArray({
    control,
    name: `products.${productIndex}.subItems`,
  })

  const { addToast } = useToasts()
  const query = useQuery()
  const eidParam = query.get('eid')
  const watchFieldArray = watch(`products.${productIndex}.subItems`)
  const controlledFields = fields.map((field, _index) => ({
    ...field,
    ...watchFieldArray[_index],
  }))

  const [imgFileUrl, setImgFileUrl] = useState('')

  useEffect(() => {
    if (product?.photoUrls?.length) {
      setImgFileUrl(product?.photoUrls[0])
    } else {
      setImgFileUrl('')
    }
  }, [product?.photoUrls?.length, imgFileUrl])

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const onUserImageUploaded = async (event) => {
    const { files } = event.target
    if (files) {
      const img = files[0]
      if (img) {
        const { msgResp } = await uploadApi.uploadPhoto(files)
        setImgFileUrl(URL.createObjectURL(img))
        setValue(`products.${productIndex}.photoUrls`, [msgResp.url])
      } else {
        setImgFileUrl('')
        setValue(`products.${productIndex}.photoUrls`, [])
      }
    }
  }

  const removeImage = () => {
    setImgFileUrl('')
    setValue(`products.${productIndex}.photoUrls`, [])
  }

  const handleChangeSequence = (index, direction) => {
    if (index - 1 >= 0 && direction === SHIFT_UP) {
      swapProduct(index, index - 1)
    }
    if (index + 1 < totalListProduct && direction === SHIFT_DOWN) {
      swapProduct(index, index + 1)
    }
  }

  const onRemoveProduct = async () => {
    if (product.id.includes('-')) {
      removeProduct(productIndex)
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await eventApi.checkBeforeRemoveProduct(eidParam, product.id)
            removeProduct(productIndex)
          } catch (error) {
            addToast(error.message || error.msgResp, {
              appearance: 'error',
              autoDismiss: true,
            })
          }
        }
      })
    }
  }

  return (
    <div>
      <div className='detail-list-news__item detail-list-news__item--background mx-0 d-flex align-items-center'>
        <div className='c-form w-100'>
          <div className='row' style={{ flexWrap: 'nowrap' }}>
            <label className='col-sm-3 col-2 align-self-center'>Item Image</label>
            <div
              className='c-form upload-image-square mt-3 mb-3'
              style={{ position: 'relative', width: '140px' }}
            >
              <div className='upload-image'>
                <img src={imgFileUrl || noImage} alt='' style={{ width: 80 }} />
                <div className='upload-image-txt mt-3'>
                  <span className='mt-3'>Upload Image</span>
                </div>
                <div className='u-file-upload__item'>
                  <div className='u-file-upload__wrap'>
                    <a className='c-upload preview-images-zone'>
                      <input
                        value={''}
                        type='file'
                        accept='image/*'
                        className='inputFile'
                        onChange={onUserImageUploaded}
                      />
                    </a>
                  </div>
                </div>
              </div>
              {imgFileUrl ? <div className='button-cross' onClick={removeImage}></div> : null}
            </div>
          </div>
          <div className='row'>
            <label className='col-sm-3 col-2 align-self-center'>Item name</label>
            <div className='col-sm-9 col-10'>
              {/* <InputProduct
                register={register(`products.${productIndex}.name`)}
                className={`form-control ${
                  errors?.products &&
                  Object.entries(errors?.products).length !== 0 &&
                  errors?.products?.[productIndex]?.name
                    ? 'is-error'
                    : ''
                }`}
                error={errors?.products?.[productIndex]?.name?.message}
              /> */}
              <EditableSelect
                productNameDefault={getValues(`products.${productIndex}.name`)}
                productIndex={productIndex}
                control={control}
                setValue={setValue}
                name={`products.${productIndex}.name`}
                hasError={
                  errors?.products &&
                  Object.entries(errors?.products).length !== 0 &&
                  errors?.products?.[productIndex]?.name
                }
                errorMessage={errors?.products?.[productIndex]?.name?.message}
                errors={errors}
              />
            </div>
          </div>
          <div className='row'>
            <label className='col-sm-3 col-2 align-self-center'>Description</label>
            <div className='col-sm-9 col-10'>
              <InputField
                type='text'
                placeholder='(Optional)'
                className={`form-control ${
                  errors?.products &&
                  Object.entries(errors?.products).length !== 0 &&
                  errors?.products[productIndex]?.description
                    ? 'is-error'
                    : ''
                }`}
                register={register(`products.${productIndex}.description`)}
              />
            </div>
          </div>
          <div className='row row-price'>
            <label className='col-sm-3 col-2 align-self-center'>Price ($)</label>
            <div className='col-sm-9 col-10'>
              <InputProduct
                type='number'
                className={`form-control ${
                  errors?.products &&
                  Object.entries(errors?.products).length !== 0 &&
                  errors?.products[productIndex]?.price
                    ? 'is-error'
                    : ''
                }`}
                register={register(`products.${productIndex}.price`)}
                step='0.01'
                error={errors?.products?.[productIndex]?.price?.message}
              />
              <a onClick={() => appendSubItem(JSON.parse(JSON.stringify({ ...DEFAULT_SUB_ITEM })))}>
                Add sub item
              </a>
              <div className='btn-action'>
                <a className='btn-add' onClick={toggle} />
                <a
                  className='btn-copy'
                  style={{ marginLeft: '14px' }}
                  onClick={() => {
                    insertProduct(
                      parseInt(productIndex + 1, 10),
                      JSON.parse(
                        JSON.stringify({
                          ...product,
                          pid: '',
                          photoUrls: [],
                        })
                      )
                    )
                  }}
                />
                <a className='btn-remove ms-0' onClick={onRemoveProduct} />
              </div>
            </div>
          </div>
        </div>
        <div
          className='arrow-wrapper d-flex flex-column align-items-center'
          style={{ width: '10%' }}
        >
          <div
            className='arrow-drap arrow-drap-up'
            onClick={() => handleChangeSequence(productIndex, SHIFT_UP)}
          />
          <div
            className='arrow-drap arrow-drap-down mt-5'
            onClick={() => handleChangeSequence(productIndex, SHIFT_DOWN)}
          />
        </div>
      </div>
      {controlledFields?.map((item, i) => (
        <SubItem
          key={i}
          productIndex={productIndex}
          nestIndex={i}
          subItem={item}
          {...{ control, register, removeSubItem, insertSubItem, appendSubItem, watch, errors }}
        />
      ))}

      <ProductSettingModal
        isOpen={isOpen}
        toggle={toggle}
        register={register}
        productIndex={productIndex}
        product={product}
        setValue={setValue}
        errors={errors}
      />
    </div>
  )
}

export default ProductItem
