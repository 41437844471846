/* eslint-disable no-useless-return */
import lazadaApi from '_api/lazada'
import orderApi from '_api/order'

import {
  ORDER_CANCELED_STATUS,
  ORDER_CONFIRMED_STATUS,
  ORDER_FAILED_DELIVERY_STATUS,
  ORDER_PACKED_STATUS,
  ORDER_PENDING_STATUS,
  ORDER_READY_TO_SHIP_STATUS,
} from '_utils/LazadaConstants'
import { BUYER_ORDER_CANCELED, ORDER_CO_KII } from '_utils/constant'
import { getCartCheckout, removeCartCheckout, removeCartOrderDetailsByRef } from '_utils/localData'
import { mockDataReverseOrdersForSeller } from '_utils/mockData'
import { isArray } from 'lodash'
import moment from 'moment'
import { DEFAULT_KEY } from 'redux-cache'
import {
  ADD_TO_CART,
  CLEAR_CART,
  CLEAR_LIST_MY_PURCHASE,
  CLEAR_LIST_ORDERS,
  CLEAR_LIST_ORDERS_BY_HOST,
  CLEAR_LIST_PURCHASE,
  CLEAR_ORDER_DETAILS_BY_REF,
  DELETE_ORDER_BY_BUYER_LOGIN,
  DELETE_ORDER_BY_BUYER_NO_LOGIN,
  FETCH_LIST_ORDER_LAZADA,
  GET_CART,
  GET_LIST_ORDERS,
  GET_LIST_ORDERS_SHOP_BY_HOST_FAILURE,
  GET_LIST_ORDERS_SHOP_BY_HOST_REQUEST,
  GET_LIST_ORDERS_SHOP_BY_HOST_SUCCESS,
  GET_LIST_ORDER_FULFILLMENT,
  GET_LIST_ORDER_LOGIN,
  GET_LIST_ORDER_NO_LOGIN,
  GET_LIST_PURCHASE,
  GET_ORDER_DETAILS_BY_REF_LOGIN,
  GET_ORDER_DETAILS_BY_REF_NO_LOGIN,
  GET_REVERSE_ORDER_DETAIL,
  GET_REVERSE_ORDER_FOR_SELLER,
  GET_REVERSE_ORDER_HISTORY,
  GET_REVERSE_ORDER_REASON,
  ORDER_SET_DATE_AND_TIME,
  SELECT_ALL_ORDER_ITEMS,
  SELECT_CHECKBOX_ORDER_ITEM,
  SELECT_TAB_ORDER_PLATFORM,
  SET_LIST_DATE_IS_PICKED,
  SYNC_LIST_ORDERS,
  TOGGLE_SHOW_ORDER_PRODUCT,
  UPDATE_CART,
  UPDATE_LIST_ORDER_ITEMS_AFTER_REPACKAGED,
  UPDATE_LIST_ORDER_LAZADA,
  UPDATE_NEW_LIST_PURCHASE,
  UPDATE_STATUS_DELIVERY_CONFIRMED,
  UPDATE_STATUS_DELIVERY_FAILED,
  UPDATE_STATUS_ORDER,
  UPDATE_STATUS_ORDER_LAZADA,
  UPDATE_STATUS_ORDER_NO_LOGIN,
} from '../constants/orderConstants'

const initialState = {
  [DEFAULT_KEY]: null,
  cart: [],
  dateAndTime: '',
  productOrderCart: [],
  listOrderFulfillment: [],
  listDateIsPicked: [],
  listOrdersByHost: [],
  listPurchase: [],
  orderDetailsByRef: null,
  shopSlugBefore: '',
  listOrdersLazada: [],
  listReverseOrdersForSeller: [],
  listReverseOrdersHistory: [],
  reverseOrderDetail: null,
  listReverseOrderReason: [],
  originalListPurchase: [],
  tabOrderPlatform: ORDER_CO_KII,
}

export const selectTabOrderPlatform = (value) => (dispatch) => {
  dispatch({
    type: SELECT_TAB_ORDER_PLATFORM,
    payload: { tabOrderPlatform: value },
  })
}

export const setListDateIsPicked = (data) => (dispatch) => {
  dispatch({
    type: SET_LIST_DATE_IS_PICKED,
    payload: { listDateIsPicked: data },
  })
}

export const clearOrderDetailsByRef = () => (dispatch) => {
  dispatch({
    type: CLEAR_ORDER_DETAILS_BY_REF,
    payload: {
      orderDetailsByRef: null,
    },
  })
  removeCartOrderDetailsByRef()
}

export const getOrderDetailsByRef = (ref) => async (dispatch) => {
  try {
    const { msgResp } = await orderApi.getOrderDetailByRef(ref)
    if (msgResp && isArray(msgResp) && msgResp.length) {
      dispatch({
        type: GET_ORDER_DETAILS_BY_REF_LOGIN,
        payload: { orderDetailsByRef: msgResp[0] },
      })
    }
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const getOrderDetailsByRefNoLogin =
  ({ ref, uEmail }) =>
  async (dispatch) => {
    try {
      const { msgResp } = await orderApi.getOrderDetailsByRefNoLogin({
        ref,
        uEmail,
      })
      dispatch({
        type: GET_ORDER_DETAILS_BY_REF_NO_LOGIN,
        payload: { orderDetailsByRef: msgResp[0] },
      })
    } catch (error) {
      throw new Error(error.message || error.msgResp)
    }
  }

export const updateStatusOrderByBuyerNoLogin =
  ({ ref, status, uEmail }) =>
  async (dispatch, getState) => {
    const { order } = getState()
    const { listPurchase } = order
    const newListPurchase = [...listPurchase]
    const indexPurchase = newListPurchase.findIndex((item) => item.ref === ref)
    newListPurchase[indexPurchase].status = status

    try {
      await orderApi.updateStatusOrderByBuyerNoLogin({ ref, status, uEmail })
      dispatch({
        type: UPDATE_STATUS_ORDER_NO_LOGIN,
        payload: { listPurchase: newListPurchase },
      })
    } catch (error) {
      throw new Error(error.message || error.msgResp)
    }
  }

export const updateOrderCanceledByRef = (ref) => (dispatch, getState) => {
  const { order } = getState()
  const { listPurchase } = order

  const newListPurchase = [...listPurchase]
  const indexPurchase = newListPurchase.findIndex((item) => item.ref === ref)
  newListPurchase[indexPurchase].status = BUYER_ORDER_CANCELED

  dispatch({
    type: UPDATE_NEW_LIST_PURCHASE,
    payload: { listPurchase: newListPurchase },
  })
}

export const getListMyPurchaseNoLogin = (search) => async (dispatch) => {
  try {
    let response = null

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (emailRegex.test(search)) {
      const { msgResp } = await orderApi.getListOrderByBuyerNoLogin({ uEmail: search })
      response = msgResp
    } else {
      const { msgResp } = await orderApi.getListOrderByBuyerNoLogin({ ref: search })
      response = msgResp
    }
    dispatch({
      type: GET_LIST_ORDER_NO_LOGIN,
      payload: { originalListPurchase: response, listPurchase: response },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const getListMyPurchaseLogin = () => async (dispatch, getState) => {
  try {
    const { order } = getState()
    const { listPurchase } = order

    const { msgResp } = await orderApi.getListOrderByBuyer()

    if (listPurchase?.length) {
      dispatch({
        type: CLEAR_LIST_MY_PURCHASE,
        payload: { listPurchase: [] },
      })
    }

    dispatch({
      type: GET_LIST_ORDER_LOGIN,
      payload: { listPurchase: msgResp, originalListPurchase: msgResp },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const selectDateAndTime = (dateAndTime) => (dispatch) => {
  dispatch({
    type: ORDER_SET_DATE_AND_TIME,
    payload: { dateAndTime },
  })
}

export const clearCart = () => (dispatch) => {
  dispatch({
    type: CLEAR_CART,
    payload: { cart: [], productOrderCart: [], shopSlugBefore: '' },
  })
  removeCartCheckout()
}

export const updateCart = (data) => (dispatch) => {
  const { orderProducts, productOrderRender } = data
  const totalQuantity = orderProducts?.reduce((total, product) => total + product.quantity, 0)

  if (totalQuantity === 0) {
    dispatch(clearCart())
    return
  }

  dispatch({
    type: UPDATE_CART,
    payload: { cart: orderProducts, productOrderCart: productOrderRender },
  })
}

export const addToCart = (orders, sid, productOrderRender, shopSlug) => (dispatch) => {
  dispatch({
    type: ADD_TO_CART,
    payload: {
      cart: {
        orderList: orders,
        shopSlug,
      },
      sid,
      productOrderCart: productOrderRender,
      shopSlugBefore: shopSlug,
    },
  })
}

export const getCart = () => (dispatch) => {
  const cart = getCartCheckout()
  const shopSlugBefore = getCartCheckout()?.shopSlug
  if (cart) {
    dispatch({
      type: GET_CART,
      payload: { cart, shopSlugBefore },
    })
  }
}

export const clearListOrders = () => (dispatch) => {
  dispatch({
    type: CLEAR_LIST_ORDERS,
    payload: { listOrders: [] },
  })
}

export const fetchListOrderLazada = (shopId, filter) => async (dispatch) => {
  try {
    const { msgResp } = await orderApi.getListLazadaOrder(
      shopId,
      moment(filter.orderDate[0].startDate).format('DD-MM-YYYY hh:mm'),
      moment(filter.orderDate[0].endDate).format('DD-MM-YYYY hh:mm')
    )

    dispatch({
      type: FETCH_LIST_ORDER_LAZADA,
      payload: {
        listOrdersLazada: msgResp.map((item) => {
          const updatedListOrderItems = item.orderItems.map((orderItem) => ({
            ...orderItem,
            selected: false,
          }))
          return {
            ...item,
            isShowProductOrder: true,
            selectAll: false,
            orderItems: updatedListOrderItems,
          }
        }),
      },
    })
  } catch (error) {
    return error
    // throw new Error(error)
  }
}

export const fetchListReverseOrderLazada = (data) => async (dispatch) => {
  try {
    const { msgResp } = await lazadaApi.getReverseOrdersForSeller(data)

    const mockData = [...mockDataReverseOrdersForSeller]

    dispatch({
      type: GET_REVERSE_ORDER_FOR_SELLER,
      payload: {
        listReverseOrdersForSeller: msgResp.items.length ? msgResp.items : mockData,
      },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const fetchReverseOrdersDetail = (data) => async (dispatch) => {
  try {
    const { msgResp } = await lazadaApi.getReverseOrdersDetail(data)

    // const mockData = {
    //   reverse_order_id: 'reverse_order_id_001',
    //   request_type: 'CANCEL',
    //   reverseOrderLineDTOList: [
    //     {
    //       return_order_line_gmt_create: '0',
    //       platform_sku_id: 'th-000',
    //       is_need_refund: 'true',
    //       trade_order_gmt_create: '0',
    //       reason_text: 'Out of stock',
    //       item_unit_price: '0',
    //       trade_order_line_id: '0',
    //       return_order_line_gmt_modified: '0',
    //       ofc_status: 'INITIAL',
    //       seller_sku_id: 'th-123',
    //       productDTO: {
    //         product_id: '0',
    //         sku: '0',
    //       },
    //       refund_payment_method: 'Alipay',
    //       buyer: {
    //         user_id: '0',
    //       },
    //       reason_code: '123',
    //       whqc_decision: 'scrap',
    //       reverse_status: 'REQUEST_INITIATE',
    //       refund_amount: '0',
    //       tracking_number: 'NLRSGZ10444515',
    //       is_dispute: 'true',
    //       reverse_order_line_id: '0',
    //     },
    //   ],
    //   shipping_type: 'PICK_UP',
    //   is_rtm: 'true',
    //   trade_order_id: 'trade_order_id_001',
    // }

    dispatch({
      type: GET_REVERSE_ORDER_DETAIL,
      payload: {
        reverseOrderDetail: msgResp,
      },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const fetchListReverseOrderHistoryLazada = () => async (dispatch) => {
  try {
    // const { msgResp } = await lazadaApi.getListReverseOrdersHistoryList(data)
    const mockData = {
      page_info: {
        total: '10',
        page_size: '10',
        current_page_number: '1',
      },
      list: [
        {
          time: 1710925699,
          operator: 'Jason',
          picture: new Array(Math.floor(Math.random() * 5)).fill(null).map((_, index) => ({
            url: `https://picsum.photos/300/300?random=${index}`,
            name: `image-${index}`,
          })),
        },
      ],
    }

    dispatch({
      type: GET_REVERSE_ORDER_HISTORY,
      payload: {
        listReverseOrdersHistory: mockData.list,
      },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const fetchReverseOrderReason = () => async (dispatch) => {
  try {
    // const { msgResp } = await lazadaApi.getReverseOrdersReasonList(data)

    const mockData = [
      {
        muti_language_text: 'out of stock',
        text: 'out of stock',
        reason_id: '1000017',
      },
    ]

    dispatch({
      type: GET_REVERSE_ORDER_REASON,
      payload: {
        listReverseOrderReason: mockData,
      },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const updateListOrderAfterRepackaged =
  (shopId, lazadaOrderId, listOrderItems) => async (dispatch, getState) => {
    const { order } = getState()
    const { listOrdersLazada } = order

    const updatedListOrder = listOrdersLazada.map((_order) => {
      if (_order.lazadaOrderId === lazadaOrderId) {
        const updatedListOrderItems = _order.orderItems.map((orderItem) => {
          const matchOrderItem = listOrderItems.find(
            (lstOrderItem) => lstOrderItem.id === orderItem.id
          )

          if (matchOrderItem) {
            return {
              ...orderItem,
              status: ORDER_PENDING_STATUS,
            }
          }
          return orderItem
        })

        return {
          ..._order,
          orderItems: updatedListOrderItems,
        }
      }

      return _order
    })

    try {
      await lazadaApi.repackagedOrder({
        shopId,
        listPackageId: listOrderItems.map((orderItem) => orderItem.packageId),
      })

      dispatch({
        type: UPDATE_LIST_ORDER_ITEMS_AFTER_REPACKAGED,
        payload: {
          listOrdersLazada: updatedListOrder,
        },
      })
    } catch (error) {
      throw new Error(error.msgResp || error.message)
    }
  }

export const selectAllOrderItems = (data) => (dispatch, getState) => {
  const { order } = getState()
  const { listOrdersLazada } = order

  const updatedListOrder = listOrdersLazada.map((item) => {
    if (item.lazadaOrderId === data.lazadaOrderId) {
      const updatedListOrderItems = item.orderItems.map((orderItem) => ({
        ...orderItem,
        selected: !data.selectAll,
      }))

      return {
        ...item,
        selectAll: !item.selectAll,
        orderItems: updatedListOrderItems,
      }
    }

    return item
  })

  dispatch({
    type: SELECT_ALL_ORDER_ITEMS,
    payload: {
      listOrdersLazada: updatedListOrder,
    },
  })
}

export const selectCheckBoxOrderItem = (lazadaOrderId, data) => (dispatch, getState) => {
  const { order } = getState()
  const { listOrdersLazada } = order

  const updatedListOrder = listOrdersLazada.map((item) => {
    if (item.lazadaOrderId === lazadaOrderId) {
      const updatedOrderItems = item.orderItems.map((orderItem) => {
        if (orderItem.id === data.id) {
          return {
            ...orderItem,
            selected: !data.selected,
          }
        }
        return orderItem
      })

      return {
        ...item,
        orderItems: updatedOrderItems,
      }
    }

    return item
  })

  dispatch({
    type: SELECT_CHECKBOX_ORDER_ITEM,
    payload: {
      listOrdersLazada: updatedListOrder,
    },
  })
}

export const getListOrdersByHost = (shopId, rangeDate) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LIST_ORDERS_SHOP_BY_HOST_REQUEST,
    })
    const { msgResp } = await orderApi.getListOrdersByHost(shopId, rangeDate)
    dispatch({
      type: GET_LIST_ORDERS_SHOP_BY_HOST_SUCCESS,
      payload: { listOrdersByHost: msgResp },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const clearListOrdersByHost = () => (dispatch) => {
  dispatch({
    type: CLEAR_LIST_ORDERS_BY_HOST,
    payload: {
      listOrdersByHost: [],
    },
  })
}

export const getListOrders = (data) => (dispatch) => {
  dispatch({
    type: GET_LIST_ORDERS,
    payload: { listOrders: data },
  })
}

export const getListOrderFulfillment = (data) => (dispatch) => {
  dispatch({
    type: GET_LIST_ORDER_FULFILLMENT,
    payload: { listOrderFulfillment: data },
  })
}

export const updateOrderStatus = (statusSelected, ref) => async (dispatch, getState) => {
  try {
    const { order } = getState()
    const { listOrdersByHost } = order
    const newListOrders = [...listOrdersByHost]
    const indexNewListOrders = newListOrders.findIndex((item) => item.ref === ref)

    if (indexNewListOrders !== -1) {
      await orderApi.updateOrderStatus(ref, {
        status: statusSelected.status,
        buyerStatus: statusSelected.buyerStatus,
      })

      newListOrders[indexNewListOrders].status = statusSelected.status
      newListOrders[indexNewListOrders].buyerStatus = statusSelected.buyerStatus

      dispatch({
        type: UPDATE_STATUS_ORDER,
        payload: {
          listOrdersByHost: newListOrders,
        },
      })
    }
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const updateStatusOrderLazada =
  (_item, productSelected, statusSelected, shopId, selectedReason) =>
  async (dispatch, getState) => {
    try {
      const { order } = getState()
      const { listOrdersLazada } = order
      const orderRecordSelected = listOrdersLazada.find((item) => item.id === _item.id)

      const updatedListOrderRecord = listOrdersLazada.map((item) => {
        if (orderRecordSelected) {
          return {
            ...item,
            orderItems: item.orderItems.map((product) => {
              if (product.id === productSelected.id) {
                return {
                  ...product,
                  status: statusSelected,
                }
              }
              return product
            }),
          }
        }
        return item
      })

      const listOrderItemId = []

      if (productSelected.id && !listOrderItemId.length) {
        listOrderItemId.push(productSelected.id)
      }

      switch (statusSelected) {
        case ORDER_FAILED_DELIVERY_STATUS: {
          await lazadaApi.confirmDeliveryFailForDBS({
            shopId,
            listPackageId: [productSelected?.packageId],
          })
          dispatch({
            type: UPDATE_STATUS_DELIVERY_FAILED,
            payload: {
              listOrdersLazada: updatedListOrderRecord,
            },
          })
          break
        }
        case ORDER_CONFIRMED_STATUS: {
          await lazadaApi.confirmDeliveryForDBS({
            shopId,
            listPackageId: [productSelected?.packageId],
          })
          dispatch({
            type: UPDATE_STATUS_DELIVERY_CONFIRMED,
            payload: {
              listOrdersLazada: updatedListOrderRecord,
            },
          })
          break
        }
        case ORDER_READY_TO_SHIP_STATUS: {
          await lazadaApi.updateStatusReadyToShip({
            shopId,
            listPackageId: [productSelected?.packageId],
          })
          dispatch({
            type: UPDATE_STATUS_ORDER_LAZADA,
            payload: {
              listOrdersLazada: updatedListOrderRecord,
            },
          })
          break
        }
        case ORDER_CANCELED_STATUS: {
          await lazadaApi.updateStatusOrderCancel({
            shopId,
            orderItemId: productSelected?.id,
            reasonId: selectedReason?.reason_id,
          })
          dispatch({
            type: UPDATE_STATUS_ORDER_LAZADA,
            payload: {
              listOrdersLazada: updatedListOrderRecord,
            },
          })
          break
        }
        case ORDER_PACKED_STATUS: {
          try {
            const { msgResp } = await lazadaApi.updateStatusPacked({
              lazadaOrderId: _item?.lazadaOrderId,
              listOrderItemId,
              shopId,
            })

            const updatedListOrder = listOrdersLazada.map((item) => ({
              ...item,
              orderItems: item.orderItems.map((orderItem) => {
                if (
                  orderItem.id === productSelected.id &&
                  orderItem.id === msgResp[0].orderItemId
                ) {
                  return {
                    ...orderItem,
                    packageId: msgResp[0].packageId,
                    status: statusSelected,
                  }
                }

                return orderItem
              }),
            }))

            dispatch({
              type: UPDATE_LIST_ORDER_LAZADA,
              payload: {
                listOrdersLazada: updatedListOrder,
              },
            })
          } catch (error) {}
          break
        }
        default:
          return
      }
    } catch (error) {
      throw new Error(error.message || error.msgResp)
    }
  }

export const toggleShowOrderProduct = (lazadaOrderId) => (dispatch, getState) => {
  const { order } = getState()
  const { listOrdersLazada } = order

  const updatedListOrderRecord = listOrdersLazada.map((item) => {
    if (item.lazadaOrderId === lazadaOrderId) {
      return {
        ...item,
        isShowProductOrder: !item?.isShowProductOrder,
      }
    }

    return item
  })

  dispatch({
    type: TOGGLE_SHOW_ORDER_PRODUCT,
    payload: {
      listOrdersLazada: updatedListOrderRecord,
    },
  })
}

export const deleteOrderByBuyerNoLogin = (purchaseItem) => async (dispatch, getState) => {
  try {
    const { order } = getState()
    const { listPurchase } = order

    const newListPurchase = listPurchase.filter((item) => item.ref !== purchaseItem.ref)

    dispatch({
      type: DELETE_ORDER_BY_BUYER_NO_LOGIN,
      payload: { listPurchase: newListPurchase },
    })

    await orderApi.deleteOrderByBuyerNoLogin({
      ref: purchaseItem.ref,
      uEmail: purchaseItem.uEmail,
    })
  } catch (e) {
    const originalListPurchase = getState().order.listPurchase
    dispatch({
      type: DELETE_ORDER_BY_BUYER_NO_LOGIN,
      payload: { listPurchase: originalListPurchase },
    })
    throw new Error(e.message || e.msgResp)
  }
}

export const deleteOrderByBuyerLogin = (purchaseItem) => async (dispatch, getState) => {
  try {
    const { order } = getState()
    const { listPurchase } = order

    const newListPurchase = listPurchase.filter((item) => item.ref !== purchaseItem.ref)

    dispatch({
      type: DELETE_ORDER_BY_BUYER_LOGIN,
      payload: { listPurchase: newListPurchase },
    })

    await orderApi.deleteOrderByBuyerLogin(purchaseItem.ref)
  } catch (e) {
    const originalListPurchase = getState().order.listPurchase
    dispatch({
      type: DELETE_ORDER_BY_BUYER_LOGIN,
      payload: { listPurchase: originalListPurchase },
    })
    throw new Error(e.message || e.msgResp)
  }
}

export const clearListPurchase = () => (dispatch) => {
  dispatch({
    type: CLEAR_LIST_PURCHASE,
    payload: { listPurchase: [] },
  })
}

export const onFilterMyPurchase = (statusSelected) => (dispatch, getState) => {
  const { order } = getState()
  const { originalListPurchase } = order

  if (isNaN(statusSelected)) {
    dispatch({
      type: GET_LIST_PURCHASE,
      payload: { listPurchase: originalListPurchase },
    })
  } else {
    const newListPurchase = originalListPurchase.filter((item) => item.status === statusSelected)

    dispatch({
      type: GET_LIST_PURCHASE,
      payload: { listPurchase: newListPurchase },
    })
  }
}

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_DETAILS_BY_REF_LOGIN:
    case DELETE_ORDER_BY_BUYER_LOGIN:
    case SELECT_TAB_ORDER_PLATFORM:
    case GET_REVERSE_ORDER_REASON:
    case GET_REVERSE_ORDER_DETAIL:
    case GET_REVERSE_ORDER_HISTORY:
    case UPDATE_STATUS_DELIVERY_CONFIRMED:
    case UPDATE_STATUS_DELIVERY_FAILED:
    case GET_REVERSE_ORDER_FOR_SELLER:
    case UPDATE_LIST_ORDER_ITEMS_AFTER_REPACKAGED:
    case UPDATE_LIST_ORDER_LAZADA:
    case SELECT_CHECKBOX_ORDER_ITEM:
    case SELECT_ALL_ORDER_ITEMS:
    case UPDATE_STATUS_ORDER_LAZADA:
    case TOGGLE_SHOW_ORDER_PRODUCT:
    case FETCH_LIST_ORDER_LAZADA:
    case CLEAR_LIST_ORDERS_BY_HOST:
    case CLEAR_LIST_MY_PURCHASE:
    case SET_LIST_DATE_IS_PICKED:
    case GET_LIST_PURCHASE:
    case CLEAR_ORDER_DETAILS_BY_REF:
    case CLEAR_LIST_PURCHASE:
    case DELETE_ORDER_BY_BUYER_NO_LOGIN:
    case GET_ORDER_DETAILS_BY_REF_NO_LOGIN:
    case UPDATE_STATUS_ORDER_NO_LOGIN:
    case UPDATE_NEW_LIST_PURCHASE:
    case GET_LIST_ORDER_NO_LOGIN:
    case GET_LIST_ORDER_LOGIN:
    case CLEAR_CART:
    case ORDER_SET_DATE_AND_TIME:
    case UPDATE_CART:
    case ADD_TO_CART:
    case GET_CART:
    case GET_LIST_ORDERS_SHOP_BY_HOST_REQUEST:
    case GET_LIST_ORDERS_SHOP_BY_HOST_SUCCESS:
    case GET_LIST_ORDERS_SHOP_BY_HOST_FAILURE:
    case GET_LIST_ORDER_FULFILLMENT:
    case SYNC_LIST_ORDERS:
    case CLEAR_LIST_ORDERS:
    case GET_LIST_ORDERS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default orderReducer
