import NotFoundData from '_images/not-found-data.jpg'
import React from 'react'

const NotFoundDataComponent = (props) => (
  <div className='text-center'>
    <img src={NotFoundData} style={{ width: 200, height: 200 }} alt='' />
    <h6 className='text-secondary'>{props.text}</h6>
  </div>
)

export default NotFoundDataComponent
