import LongText from '_components/LongText'
import MyGallery from '_components/MyGallery'
import noImage from '_images/noimage02.svg'
import { SUBITEM_REQUIRED, VIEW_GRID, VIEW_LIST } from '_utils/constant'
import { formatNumberToCurrencyHasDot } from '_utils/functions/formatter'
import classNames from 'classnames'
import { isEmpty, isNull } from 'lodash'
import CurrencyFormat from 'react-currency-format'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { IoMdTrash } from 'react-icons/io'
import { useSelector } from 'react-redux'

const colorSoldOut = {
  filter: 'grayscale(100%)',
  opacity: 0.5,
  pointerEvents: 'none',
}

function ProductItem({
  findProduct,
  product,
  changeProductQuantityInput,
  handleChangeSubItem,
  getValueSelect,
  addAnotherOrder,
  deleteItemOrder,
  viewDefault,
  // isEqualDate,
  className,
  styleFooterCard,
  isDesktop,
}) {
  if (!product) return null

  const { cart } = useSelector((state) => state.order)
  const { shopDetail } = useSelector((state) => state.shop)
  const shopColorTheme = shopDetail?.shopColorTheme
  const colorTheme = shopColorTheme?.customsColorTheme || shopColorTheme?.defaultColorTheme
  const isShowInstruction = Boolean(shopDetail?.additionalInstruction)

  const handleAddToCart = (id, index) => {
    changeProductQuantityInput({
      pid: id,
      number: findProduct(id, index).quantityInput + 1,
      index,
      type: 'plus',
    })
  }

  const isSoldOut = !product?.availableOfStock

  const checkListImage = (imageLink) => {
    if (
      isNull(imageLink) ||
      isEmpty(imageLink) ||
      (!imageLink.includes('png') && !imageLink.includes('jpg'))
    ) {
      return noImage
    }

    return imageLink
  }

  const getTranslateX = () => {
    if (viewDefault === VIEW_GRID) {
      return 'translateY(0)'
    }
    return isDesktop ? 'translate(200px, -100px)' : 'translate(130%, -110px)'
  }

  return (
    <div className={classNames(`mb-3 px-2 ${className}`)}>
      <div
        className={classNames(
          'product-item d-flex',
          viewDefault === VIEW_GRID ? 'flex-column' : 'row align-items-stretch'
        )}
        style={isSoldOut ? colorSoldOut : {}}
      >
        <div
          className={classNames(
            'row align-items-center position-relative w-100',
            viewDefault === VIEW_GRID ? 'flex-column' : 'align-items-stretch'
          )}
        >
          <div
            className={`product-item-img head rounded bg-white p-1 shadow-sm mx-auto ${
              viewDefault === VIEW_GRID ? 'view-grid' : 'col col-auto view-list'
            }`}
          >
            <MyGallery image={checkListImage(product?.photoUrl || product?.photoUrls)} />
          </div>
          <div
            className={classNames(
              'product-item-info-title body d-flex flex-column col-12 col-lg-9',
              viewDefault === VIEW_GRID
                ? 'align-items-center mt-3 w-100 view-grid px-0'
                : 'align-items-start h-auto'
            )}
            style={{
              gap: '5px',
            }}
          >
            <p
              className={`mb-0 name ${viewDefault === VIEW_GRID ? 'text-center' : 'text-start'}`}
              style={{ color: colorTheme?.titleItemColor }}
            >
              {product.name}
            </p>
            <p
              className='price mb-0'
              style={{
                color: colorTheme?.priceColor,
              }}
            >
              <CurrencyFormat
                className='ps-0'
                value={product.price || 0}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </p>
            {viewDefault === VIEW_GRID && product.minOrderQty > 1 ? (
              <small
                style={{ fontSize: 12 }}
                className={`text-danger font-semibold ${
                  viewDefault === VIEW_GRID ? 'text-center' : 'text-end'
                }`}
              >
                (Min order {product.minOrderQty} qty)
              </small>
            ) : null}
            {viewDefault === VIEW_LIST && product.minOrderQty > 1 ? (
              <small
                style={{ fontSize: 12 }}
                className={`text-danger font-semibold ${
                  viewDefault === VIEW_GRID ? 'text-center' : 'text-end'
                }`}
              >
                (Min order {product.minOrderQty} qty)
              </small>
            ) : null}

            <div
              className={classNames(
                'fw-normal fw-semibold text-secondary text-wrap',
                viewDefault === VIEW_LIST ? 'text-left' : 'text-center'
              )}
            >
              {product.description !== null && (
                <LongText
                  content={product?.description}
                  className={classNames(
                    'cursor-pointer text-break description',
                    viewDefault === VIEW_LIST ? 'me-auto' : 'mx-auto',
                    isShowInstruction && !isNull(product.instruction) ? 'mb-2' : 'mb-0'
                  )}
                  limit='80'
                />
              )}
            </div>

            {isSoldOut ? <div className='fw-bold text-end text-uppercase'>(Sold Out)</div> : null}
          </div>
          {viewDefault === VIEW_LIST ? (
            <div className='product-item-info-quantity view-list'>
              <div className='quanlity'>
                <span
                  className='icon-mim'
                  onClick={() =>
                    changeProductQuantityInput({
                      pid: product.id,
                      number: findProduct(product.id, product.index).quantityInput - 1,
                      index: product.index,
                      type: 'minus',
                    })
                  }
                >
                  -
                </span>
                <span className='icon-input'>
                  <input
                    type='text'
                    step='1'
                    min='0'
                    max='100'
                    className='form-control'
                    style={{ color: colorTheme?.generalTextColor }}
                    value={findProduct(product.id, product.index).quantityInput}
                    onChange={(e) =>
                      changeProductQuantityInput({
                        pid: product.id,
                        number: parseInt(e.target.value, 10),
                        index: product.index,
                        type: 'typing',
                      })
                    }
                  />
                </span>
                <span
                  className='icon-plugs'
                  onClick={() => {
                    changeProductQuantityInput({
                      pid: product.id,
                      number: findProduct(product.id, product.index).quantityInput + 1,
                      index: product.index,
                      type: 'plus',
                    })
                  }}
                >
                  +
                </span>
              </div>
            </div>
          ) : null}
        </div>

        <div
          className='product-item-footer text-center d-block'
          style={
            viewDefault === VIEW_GRID
              ? { ...styleFooterCard, marginTop: ' auto' }
              : { height: 'auto', marginTop: 0 }
          }
        >
          {product.subItems.length > 0 ? (
            <>
              <div className='product-item-info-select px-0 mb-2'>
                <div className='c-form c-form-select-list product-item-info-select-item'>
                  <div className='form-item my-0'>
                    {product.subItems
                      ? product.subItems.map((s, i) => (
                          <div
                            key={i}
                            className={classNames(
                              product.subItems.length > 1 ? 'my-3' : 'my-0',
                              viewDefault === VIEW_LIST ? 'ms-auto w-50' : 'mx-auto w-100 mt-3'
                            )}
                          >
                            <select
                              className='form-control w-100 shadow-sm border cursor-pointer rounded me-0'
                              onChange={(e) =>
                                handleChangeSubItem({
                                  pid: product.id,
                                  subItem: {
                                    ...s,
                                    index: i,
                                  },
                                  value: e.target.value !== '' ? JSON.parse(e.target.value) : null,
                                  pIndex: product.index,
                                })
                              }
                              value={getValueSelect({
                                pid: product.id,
                                pIndex: product.index,
                                subItemType: s.type,
                              })}
                            >
                              {s.required !== SUBITEM_REQUIRED && (
                                <option value={''}>Option</option>
                              )}
                              {s.list &&
                                s.list.map((l, _i) => (
                                  <option
                                    key={_i}
                                    value={JSON.stringify({ name: l.name, price: l.price })}
                                  >
                                    {l.name} (+
                                    {formatNumberToCurrencyHasDot(`${l.price}`)})
                                  </option>
                                ))}
                            </select>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
              <div
                style={{ height: 20 }}
                className={`text-add-another d-flex align-items-center my-3 ${
                  viewDefault === VIEW_LIST ? 'justify-content-end' : 'justify-content-center'
                }`}
              >
                <div
                  className={classNames(
                    'text-wrap font-semibold text-primary cursor-pointer text-add',
                    viewDefault === VIEW_LIST ? 'text-end' : '',
                    product?.isCopy ? 'd-flex align-items-center justify-content-center' : ''
                  )}
                >
                  <span onClick={() => addAnotherOrder(product.id, product.index)}>
                    Add Another Order
                  </span>
                  {product?.isCopy ? (
                    <IoMdTrash
                      className='text-wrap font-semibold text-danger cursor-pointer d-block ms-2'
                      onClick={() => deleteItemOrder(product.id, product.index)}
                      size={20}
                    />
                  ) : null}
                </div>
              </div>
            </>
          ) : null}

          {viewDefault === VIEW_GRID ? (
            <div
              className={classNames(
                'product-item-info-quantity d-flex align-items-center',
                viewDefault === VIEW_GRID
                  ? 'w-100 justify-content-center flex-column gap-2'
                  : 'justify-content-end flex-column gap-1'
              )}
              style={{
                transform: getTranslateX(),
                width: viewDefault === VIEW_GRID ? '100%' : 'fit-content',
                height: 40,
              }}
            >
              {!cart?.orderList?.length &&
              viewDefault === VIEW_GRID &&
              !findProduct(product.id, product.index).quantityInput ? (
                <button
                  className='btn btn-primary shadow-none fw-semibold'
                  onClick={() => handleAddToCart(product.id, product.index)}
                >
                  <AiOutlineShoppingCart size={20} className={'me-1'} />
                  Add to cart
                </button>
              ) : (
                <div className='quanlity'>
                  <span
                    className='icon-mim'
                    onClick={() =>
                      changeProductQuantityInput({
                        pid: product.id,
                        number: findProduct(product.id, product.index).quantityInput - 1,
                        index: product.index,
                        type: 'minus',
                      })
                    }
                  >
                    -
                  </span>
                  <span className='icon-input'>
                    <input
                      type='text'
                      step='1'
                      min='0'
                      max='100'
                      className='form-control'
                      style={{ color: colorTheme?.generalTextColor }}
                      value={findProduct(product.id, product.index).quantityInput}
                      onChange={(e) =>
                        changeProductQuantityInput({
                          pid: product.id,
                          number: parseInt(e.target.value, 10),
                          index: product.index,
                          type: 'typing',
                        })
                      }
                    />
                  </span>
                  <span
                    className='icon-plugs'
                    onClick={() => {
                      changeProductQuantityInput({
                        pid: product.id,
                        number: findProduct(product.id, product.index).quantityInput + 1,
                        index: product.index,
                        type: 'plus',
                      })
                    }}
                  >
                    +
                  </span>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ProductItem
