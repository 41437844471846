import { LIST_TIME } from '_utils/constant'
import { FaRegClock } from 'react-icons/fa6'

const TimeSelect = ({ id, value, onChange, options }) => (
  <div className='d-flex align-items-center time-select rounded'>
    <select
      className='form-control border-0 shadow-none cursor-pointer'
      id={id}
      value={value}
      onChange={onChange}
    >
      {options.map((timeOption) => (
        <option key={timeOption.id} value={timeOption.value}>
          {timeOption.text}
        </option>
      ))}
    </select>
    <FaRegClock className='me-2' size={24} />
  </div>
)

const TimePicker = ({ time, onChangeTime }) => (
  <div className='mb-3'>
    <label className='form-label'>Choose time slot:</label>
    <div className='d-flex align-items-center justify-content-between'>
      <div className='me-2'>
        <label htmlFor='timeStart' className='form-label'>
          Between
        </label>
        <TimeSelect id='start' value={time.start} onChange={onChangeTime} options={LIST_TIME} />
      </div>
      <span className='mx-2 mt-5'>-</span>
      <div>
        <label htmlFor='timeEnd' className='form-label'>
          To
        </label>
        <TimeSelect id='end' value={time.end} onChange={onChangeTime} options={LIST_TIME} />
      </div>
    </div>
  </div>
)

export default TimePicker
