/* eslint-disable max-len */
import moment from 'moment'
import axiosClient from './axiosClient'

const eventApi = {
  getListEventsOpening: (shopId = null) => {
    const url = '/list-events-opening'
    if (shopId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['shop-id'] = shopId
        return config
      })
    }

    return axiosClient.get(url)
  },
  getEventDetail: ({
    eid,
    isGetProducts = true,
    isGetBuyerNames = false,
    isGetDeliveryZones = false,
  }) => {
    if (!eid) return null
    const url = `/get-shop-event-detail?id=${eid}${isGetProducts ? '&getProducts=1' : ''}${
      isGetBuyerNames ? '&getBuyerNames=1' : ''
    }${isGetDeliveryZones ? '&getDeliveryZones=1' : ''}`
    return axiosClient.get(url)
  },
  getListEventsByShopId: (shopId, date) => {
    let url = `/list-shop-event-by-shop?shopId=${shopId}`
    if (date) {
      url = `/list-shop-event-by-shop?shopId=${shopId}&date=${moment(date).format(
        'DD-MM-YYYY'
      )} 00:00`
    }
    return axiosClient.get(url)
  },
  getListMyEvents: (shopId) => {
    const url = '/list-my-events'
    if (shopId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['shop-id'] = shopId
        return config
      })
    }
    return axiosClient.get(url)
  },
  getShareEventCode: (eventId) => {
    const url = `/update-event-share?id=${eventId}`
    return axiosClient.patch(url)
  },
  getEventById: (id) => {
    const url = `/get-shop-event-detail?id=${id}&getProducts=1&getDeliveryZones=1`
    return axiosClient.get(url)
  },
  updateEventStatus: (id, data) => {
    const url = `/update-event-status?id=${id}`
    return axiosClient.patch(url, data)
  },
  getEvent: (id) => {
    const url = `/get-event?id=${id}&getProduct=1`
    return axiosClient.get(url)
  },
  createEvent: (data) => {
    const url = '/create-shop-event'
    return axiosClient.post(url, data)
  },
  updateEvent: (data) => {
    const url = `/update-shop-event?id=${data.id}`
    return axiosClient.patch(url, data)
  },
  deleteEvent: (id) => {
    const url = `/delete-shop-event?id=${id}&getProducts=1`
    return axiosClient.delete(url)
  },
  updateEventDiscount: (id, data) => {
    const url = `/update-shop-event?id=${id}`
    return axiosClient.patch(url, data)
  },
  updateEventDelivery: (id, data) => {
    const url = `/update-shop-event?id=${id}`
    return axiosClient.patch(url, data)
  },
  updateEventRequestPayment: (id, data) => {
    const url = `/update-shop-event?id=${id}`
    return axiosClient.patch(url, data)
  },
  updateEventRequestPaymentAndNotify: (id, data) => {
    const url = `/shop-event-request-payment?eid=${id}`
    return axiosClient.patch(url, data)
  },
  getShareEvent: ({ eventId, shareCode }) => {
    const url = `/get-share-event?id=${eventId}&shareCode=${shareCode}`
    return axiosClient.get(url)
  },
  createScheduledEvent: (data) => {
    const url = '/create-scheduled-event'
    return axiosClient.post(url, data)
  },
  updateScheduledEvent: (data) => {
    const url = `/update-scheduled-event?id=${data?.id}`
    return axiosClient.patch(url, data)
  },
  getScheduledEvent: (id) => {
    const url = `/get-scheduled-event?id=${id}&getProducts=1`
    return axiosClient.get(url)
  },
  listMyScheduledEvents: (shopId) => {
    const url = '/list-my-scheduled-events'
    if (shopId) {
      axiosClient.interceptors.request.use((config) => {
        config.headers['shop-id'] = shopId
        return config
      })
    }
    return axiosClient.get(url)
  },
  deleteScheduledEvent: (id) => {
    const url = `/delete-scheduled-event?id=${id}`
    return axiosClient.delete(url)
  },
  getEventsClosePerMonthByHost: (data, shopId) => {
    const url = `/list-event-close-per-month-by-host?month=${data.month}&year=${data.year}&shopId=${shopId}`
    return axiosClient.get(url)
  },
  getEventsCollectionPerMonthByHost: (data, shopId) => {
    const url = `/list-event-collection-per-month-by-host?month=${data.month}&year=${data.year}&shopId=${shopId}`
    return axiosClient.get(url)
  },
  getListEventAndDelivery: (shopId) => {
    const url = `/get-list-orders-and-deliveries-by-host?shopId=${shopId}`
    return axiosClient.get(url)
  },
  paymentScheduleEvent: ({ packageType, packageId, startDate }) => {
    const url = '/create-transaction-for-co-bee-feature'
    return axiosClient.post(url, {
      packageType,
      packageId,
      start_date: startDate,
    })
  },
  broadcastMessage: (data) => {
    const url = '/create-notification'
    return axiosClient.post(url, data)
  },
  checkBeforeRemoveProduct: (eventId, productId) => {
    const url = '/check-product-before-remove'
    return axiosClient.post(url, {
      eid: eventId,
      pid: productId,
    })
  },
}

export default eventApi
