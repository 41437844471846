import { DeliveryContext } from '_contexts/DeliveryContext'
import { PACKAGE_BASIC, SUBSCRIPTION_STATUS_UNPAID } from '_utils/constant'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import ButtonDeliveryEvent from './ButtonDeliveryEvent'
import Search from './Search'

const DeliveryActions = () => {
  const { search, handleSearch, toggleModal, handleClear } = useContext(DeliveryContext)
  const { listInvoiceSubscription } = useSelector((state) => state.subscription)

  const shouldDisplayEvents = () => {
    if (listInvoiceSubscription.length === 1) {
      return listInvoiceSubscription[0].subscriptionPackageType !== PACKAGE_BASIC
    }
    if (listInvoiceSubscription.length > 1) {
      return listInvoiceSubscription.some(
        (subscription) =>
          subscription.subscriptionPackageType !== PACKAGE_BASIC &&
          subscription.status !== SUBSCRIPTION_STATUS_UNPAID
      )
    }
    return false
  }

  return (
    <div className='d-flex align-items-center'>
      <Search searchString={search} handleSearch={handleSearch} />
      <button
        className='btn btn-fulfillment-date w-auto shadow-none bg-transparent rounded-0 border'
        onClick={toggleModal}
      >
        Fulfillment Date
      </button>

      {shouldDisplayEvents() && <ButtonDeliveryEvent />}

      <button
        className='btn btn-clear bg-transparent border rounded-0 shadow-none w-auto mt-0 ms-auto'
        onClick={handleClear}
      >
        Clear filter
      </button>
    </div>
  )
}

export default DeliveryActions
