import React, { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Select, { components } from 'react-select'
import { FormFeedback } from 'reactstrap'

const Input = (props) => <components.Input {...props} isHidden={false} />

export default function EditableSelect(props) {
  const { selectedShop } = useSelector((state) => state.shop)
  const {
    productNameDefault,
    name,
    control,
    setValue,
    className,
    hasError,
    errorMessage,
    productIndex,
  } = props
  // const [value, setValueState] = useState(null)
  const [inputValue, setInputValue] = useState('')

  const productNameDefaultMapped = {
    label: productNameDefault,
    value: productNameDefault,
  }

  useEffect(() => {
    if (productNameDefault && Object.keys(productNameDefaultMapped).length > 0) {
      setInputValue(productNameDefault)
    }
  }, [JSON.stringify(productNameDefaultMapped)])

  const listProductMapped = selectedShop?.products?.map((product) => ({
    label: product.name,
    value: product.id,
    description: product.description,
    price: product.price,
    nameIndex: name,
    availableOfStock: product.availableOfStock,
    limitPerOrder: product.limitPerOrder,
    minOrderQty: product.minOrderQty,
  }))

  const options = useRef(listProductMapped).current

  const selectRef = useRef()

  const onInputChange = (text, { action }) => {
    if (action === 'input-change') {
      setInputValue(text)
      setValue(name, text)
    }
  }

  const onChange = (option) => {
    // setValueState(option)
    setInputValue(option ? option.label : '')
    setValue(name, option ? option.label : '')

    if (option) {
      setValue(`products.${productIndex}.description`, option.description)
      setValue(`products.${productIndex}.price`, option.price)
      setValue(`products.${productIndex}.maxQuantity`, option.availableOfStock)
      setValue(`products.${productIndex}.limitPerOrder`, option.limitPerOrder)
      setValue(`products.${productIndex}.minOrderQty`, option.minOrderQty)
    }
  }
  return (
    <div>
      <Controller
        key={name}
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            key={name}
            className={className}
            ref={selectRef}
            options={options}
            isClearable={true}
            placeholder={''}
            value={
              options.find((option) => option.label === field.value) || productNameDefaultMapped
            }
            inputValue={inputValue}
            onInputChange={onInputChange}
            onChange={(selectedOption) => {
              field.onChange(selectedOption ? selectedOption.value : '')
              onChange(selectedOption)
            }}
            controlShouldRenderValue={true}
            components={{ Input }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                border: hasError ? '1px solid red' : '1px solid #179d9a',
                boxShadow: state.isFocused
                  ? hasError
                    ? '0 0 0 1px red'
                    : '0 0 0 1px #179d9a'
                  : 'none',
                '&:hover': {
                  border: hasError ? '1px solid red' : '1px solid #179d9a',
                },
              }),
              option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? 'white' : 'black',
                backgroundColor: state.isSelected ? '#179d9a' : 'white',
                '&:hover': {
                  backgroundColor: '#179d9a',
                  color: 'white',
                },
              }),
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral80: '#179d9a',
              },
            })}
          />
        )}
      />
      <FormFeedback>{errorMessage}</FormFeedback>
    </div>
  )
}
