import { getDateRemainFromNow } from '_utils/function'
import { convertToCurrentGMT } from '_utils/functions/converter'
import { FaTruck } from 'react-icons/fa'

const DeliveryStatus = ({ deliveryTime }) => (
  <div className={'deliveryStatus'}>
    <FaTruck className={'truckIcon'} size={30} />
    <span>Delivery in {getDateRemainFromNow(convertToCurrentGMT(deliveryTime))}</span>
  </div>
)

export default DeliveryStatus
