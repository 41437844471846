/* eslint-disable no-return-assign */
import orderApi from '_api/order'
import uploadApi from '_api/upload'
import LoadingOverlay from '_components/LoadingOverlay'
import ModalNotifySuccess from '_components/ModalNotifySuccess'
import {
  clearCart,
  clearOrderDetailsByRef,
  getOrderDetailsByRef,
  getOrderDetailsByRefNoLogin,
} from '_redux/modules/order'
import {
  BUYER_ORDER_CANCELED,
  BUYER_ORDER_PAID,
  BUYER_ORDER_UNPAID,
  HOST_ORDER_FULFILLED,
  HOST_ORDER_VERIFIED,
} from '_utils/constant'
import { getOrderRefParam, scrollToTop } from '_utils/function'
import { getCartOrderDetailsByRef, getDataShopDetail, getUserToken } from '_utils/localData'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { BiArrowBack, BiCheckCircle, BiEdit } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'
import Bill from './components/Bill'
import ContactDetails from './components/ContactDetails'
import Payment from './components/Payment'
import './style.scss'

function PaymentCode() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const location = useLocation()
  const userToken = getUserToken()

  const previousUrl = location.state?.from

  const paymentScreenshotURLRef = useRef(null)
  const [previewImg, setPreviewImg] = useState('')
  const [loading, setLoading] = useState(false)
  const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)
  const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const photoRef = useRef()
  const shopDetail = getDataShopDetail()

  const orderRefParams = getOrderRefParam()

  const { orderDetailsByRef: orderDetailsByRefRedux } = useSelector((state) => state.order)
  const [orderDetailsByRef, setOrderDetailsByRef] = useState({})

  useEffect(() => {
    if (orderDetailsByRefRedux) {
      setOrderDetailsByRef(orderDetailsByRefRedux)

      if (orderDetailsByRefRedux?.paymentScreenshot) {
        setPreviewImg(orderDetailsByRefRedux?.paymentScreenshot)
        paymentScreenshotURLRef.current = orderDetailsByRefRedux?.paymentScreenshot
        setIsUploaded(true)
      }
    }
  }, [JSON.stringify(orderDetailsByRefRedux)])

  const cart = orderDetailsByRef?.listProduct
  const cartOrderDetailsByRef = getCartOrderDetailsByRef()
  const [subTotal, setSubTotal] = useState(0)

  const totalPrice =
    subTotal +
    (orderDetailsByRef?.orderDelivery?.deliveryFee || 0) +
    (orderDetailsByRef?.orderPickup?.pickupFee || 0) +
    (orderDetailsByRef?.transactionFees || 0)

  const totalSubItemsPrice = (arr) => {
    if (arr.length === 0) {
      return 0
    }

    if (arr.length === 1) {
      return arr[0].price
    }

    return arr.reduce((acc, curr) => acc + curr.price, 0)
  }

  useEffect(() => {
    if (cart) {
      const calcSubtotal = cart.reduce((acc, curr) => {
        const subItemsPrice = totalSubItemsPrice(curr.subItems)
        const productPrice = curr?.priceNoSubItems + subItemsPrice

        const productTotalPrice = productPrice * curr.quantity
        return acc + productTotalPrice
      }, 0)

      setSubTotal(calcSubtotal)
    }
  }, [cart?.length])

  const onClearCart = () => {
    dispatch(clearOrderDetailsByRef())
    dispatch(clearCart())
  }

  const handleUserTokenOrderDetails = async () => {
    const orderRef = cartOrderDetailsByRef?.ref || orderRefParams

    if (orderRef) {
      await dispatch(getOrderDetailsByRef(orderRef))
    }
  }

  const handleNoLoginOrderDetails = async () => {
    if (cartOrderDetailsByRef?.ref) {
      await dispatch(
        getOrderDetailsByRefNoLogin({
          ref: cartOrderDetailsByRef?.ref,
          uEmail: cartOrderDetailsByRef?.email,
        })
      )
    }
  }

  const handleOrderDetails = async () => {
    if (userToken) {
      await handleUserTokenOrderDetails()
    } else {
      await handleNoLoginOrderDetails()
    }
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      await handleOrderDetails()
    } catch (error) {
      addToast(error.message || error.msgResp, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    scrollToTop()

    if (orderRefParams && userToken) {
      const fetchDataOrder = async () => {
        setLoading(true)
        await dispatch(getOrderDetailsByRef(orderRefParams))
        setLoading(false)
      }

      fetchDataOrder()
    } else {
      if (cartOrderDetailsByRef?.ref) {
        const fetchData1 = async () => {
          setLoading(true)
          await dispatch(
            getOrderDetailsByRefNoLogin({
              ref: cartOrderDetailsByRef?.ref,
              uEmail: cartOrderDetailsByRef?.email,
            })
          )
          setLoading(false)
        }

        fetchData1()
      }
      if (localStorage.getItem('emailNoLogin')) {
        const fetchData2 = async () => {
          setLoading(true)
          await dispatch(
            getOrderDetailsByRefNoLogin({
              ref: orderRefParams,
              uEmail: localStorage.getItem('emailNoLogin'),
            })
          )
          setLoading(false)
        }

        fetchData2()
      }
    }
  }, [orderRefParams, userToken, cartOrderDetailsByRef?.ref, localStorage.getItem('emailNoLogin')])

  useEffect(() => {
    scrollToTop()
    if (!orderRefParams && cartOrderDetailsByRef?.ref) {
      fetchData()
    }

    return () => {
      setPreviewImg('')
    }
  }, [orderRefParams, cartOrderDetailsByRef?.ref])

  useEffect(() => {
    const handleBackButton = (event) => {
      if (!userToken) {
        event.preventDefault()
        onClearCart()
        history.push('/virtual-shop/list')
      }
    }

    window.addEventListener('popstate', handleBackButton)

    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [userToken, history])

  const handleWhenShowModalPaymentSuccess = (type) => {
    if (type === 'continue') {
      // Yes
      if (!isEmpty(shopDetail)) {
        history.push(`/${shopDetail?.shopSlug}`)
      } else if (!isEmpty(orderDetailsByRef)) {
        const shopSlug =
          orderDetailsByRef?.shopLink.split('/')[orderDetailsByRef?.shopLink.split('/').length - 1]
        history.push(`/${shopSlug}`)
      } else {
        const shopSlugNoLogin = localStorage.getItem('shopSlugNoLogin')
        history.push(`/${shopSlugNoLogin}`)
        localStorage.removeItem('shopSlugNoLogin')
      }
      onClearCart()
    } else {
      // No
      history.push('/virtual-shop/list')
      onClearCart()
    }
  }

  const onUserImageUploaded = async (_event) => {
    const { files } = _event.target
    if (files) {
      const img = files[0]
      setPreviewImg(URL.createObjectURL(img))
      setIsUploaded(true)

      const { msgResp: res } = await uploadApi.uploadPhoto(files)
      const { url } = res
      paymentScreenshotURLRef.current = url

      setOrderDetailsByRef({
        ...orderDetailsByRef,
        paymentScreenshot: url,
      })

      setIsUploaded(false)
    }
  }

  const confirmPayment = async (e) => {
    e.preventDefault()
    setIsSubmittedPayment(true)
    if (!cart?.buyer?.isCheckCOD && !paymentScreenshotURLRef.current) {
      setIsSubmittedPayment(false)
      alert('Please upload payment screenshot')
    } else {
      try {
        const postData = {
          ref: orderDetailsByRef?.ref,
          buyerStatus: BUYER_ORDER_PAID,
          status: BUYER_ORDER_PAID,
          paymentScreenshot: paymentScreenshotURLRef.current,
          uEmail: orderDetailsByRef?.uEmail || cart?.email,
        }

        if (userToken) {
          if (orderRefParams && orderDetailsByRef?.status === BUYER_ORDER_PAID) {
            await orderApi.updatePaymentScreenshot({
              ref: postData.ref,
              paymentScreenshot: postData.paymentScreenshot,
            })
            setOrderDetailsByRef({
              ...orderDetailsByRef,
              buyerStatus: BUYER_ORDER_PAID,
              status: BUYER_ORDER_PAID,
            })
            setIsUploaded(true)
          } else {
            await orderApi.updateStatusOrderByBuyer(postData)
            localStorage.setItem('ORDER_SUCCESS', 'true')
            setOrderDetailsByRef({
              ...orderDetailsByRef,
              buyerStatus: BUYER_ORDER_PAID,
              status: BUYER_ORDER_PAID,
            })
            setIsUploaded(true)
          }
        } else {
          // update order no login
          await orderApi.updateStatusOrderByBuyerNoLogin(postData)
          setOrderDetailsByRef({
            ...orderDetailsByRef,
            buyerStatus: BUYER_ORDER_PAID,
            status: BUYER_ORDER_PAID,
          })
        }

        setIsSubmittedPayment(false)
        if (orderRefParams) {
          addToast('Update order successfully!', {
            appearance: 'success',
            autoDismiss: true,
          })
        } else {
          setIsModalOpenSuccess(true)
        }
      } catch (err) {
        addToast(err.msgResp || err.message, { appearance: 'error', autoDismiss: true })
        setIsSubmittedPayment(false)
      }
    }
  }

  const handleBackPage = () => {
    history.push(`/virtual-shop/checkout/${orderDetailsByRef?.ref}`)
  }

  const isVerifiedOrder = [HOST_ORDER_VERIFIED, HOST_ORDER_FULFILLED].includes(
    orderDetailsByRef?.status
  )

  if (loading) {
    return <LoadingOverlay style={{ margin: '50px auto' }} />
  }

  return (
    <div className='container'>
      <div className='payment-code px-3 f-16 mt-0'>
        <div className='contact-details d-flex align-items-center justify-content-between mb-3'>
          <p className={'title fw-semibold mb-0 text-uppercase'}>Contact Details</p>
          {(previousUrl === undefined || previousUrl === 'checkout') &&
            userToken &&
            ![HOST_ORDER_VERIFIED, HOST_ORDER_FULFILLED, BUYER_ORDER_PAID].includes(
              orderDetailsByRef?.status
            ) && (
              <button
                onClick={handleBackPage}
                className={'bg-transparent border-0 d-flex align-items-center'}
                style={{
                  color: '#495057',
                }}
              >
                <BiArrowBack className='me-1' />
                Back
              </button>
            )}
          {userToken &&
          previousUrl === 'my-purchase' &&
          ![
            HOST_ORDER_VERIFIED,
            HOST_ORDER_FULFILLED,
            BUYER_ORDER_PAID,
            BUYER_ORDER_CANCELED,
          ].includes(orderDetailsByRef?.status) ? (
            <button
              onClick={handleBackPage}
              className={'text-decoration-underline bg-transparent border-0 text-primary'}
            >
              Edit Contact Details
              <BiEdit className='ms-1' />
            </button>
          ) : null}
        </div>
        <ContactDetails />
        <p className='text-uppercase title fw-semibold'>Order Summary</p>
        <Bill
          cart={orderDetailsByRef?.listProduct}
          totalPrice={totalPrice}
          subTotal={subTotal}
          orderDetailsByRef={orderDetailsByRef}
        />

        {!orderDetailsByRef?.cashOnDeliveryPickup ? (
          <div className='qr-code bg-white p-3 rounded-3 mt-3'>
            <>
              {orderDetailsByRef?.paymentScreenshot ||
              (!isVerifiedOrder && Boolean(orderDetailsByRef?.paymentScreenshot)) ||
              (orderDetailsByRef?.status === BUYER_ORDER_UNPAID &&
                !orderDetailsByRef?.paymentScreenshot) ? (
                <Payment
                  previewImg={previewImg}
                  confirmPayment={confirmPayment}
                  final={totalPrice}
                  phone={shopDetail?.createdUserPhone || orderDetailsByRef?.shopCreatedUserPhone}
                  isSubmittedPayment={isSubmittedPayment}
                  onUserImageUploaded={onUserImageUploaded}
                  stringRefNumber={cartOrderDetailsByRef?.ref}
                  photoRef={photoRef}
                  orderDetailsByRef={orderDetailsByRef}
                  isUploaded={isUploaded}
                  isOrderRef={!!orderRefParams}
                />
              ) : (
                <div className='d-flex flex-column align-items-center justify-content-center mt-3'>
                  <BiCheckCircle style={{ color: '#28a745' }} size={50} />
                  <span style={{ color: '#28a745' }}>Order Verified</span>
                </div>
              )}
            </>
          </div>
        ) : (
          <>
            {!orderRefParams ? (
              <button
                className='btn btn--main mt-3 btn-payment-code'
                onClick={confirmPayment}
                disabled={isSubmittedPayment || isUploaded || !isEmpty(orderDetailsByRef)}
              >
                {isSubmittedPayment ? (
                  <Spinner color='light' size='sm' />
                ) : orderRefParams ? (
                  'UPDATE ORDER'
                ) : (
                  'CONFIRM PAYMENT'
                )}
              </button>
            ) : (
              <div className='d-flex flex-column align-items-center justify-content-center mt-3'>
                <BiCheckCircle style={{ color: '#28a745' }} size={50} />
                <span style={{ color: '#28a745' }}>Order Verified</span>
              </div>
            )}
          </>
        )}

        <ModalNotifySuccess
          isModalOpen={isModalOpenSuccess}
          handleWhenShowModalPaymentSuccess={handleWhenShowModalPaymentSuccess}
        />
      </div>
    </div>
  )
}

export default PaymentCode
