import lazadaApi from '_api/lazada'
import {
  selectAllOrderItems,
  selectCheckBoxOrderItem,
  toggleShowOrderProduct,
  updateListOrderAfterRepackaged,
  updateStatusOrderLazada,
} from '_redux/modules/order'
import {
  ORDER_CANCELED_STATUS,
  ORDER_CONFIRMED_STATUS,
  ORDER_DELIVERED_STATUS,
  ORDER_FAILED_DELIVERY_STATUS,
  ORDER_PACKED_STATUS,
  ORDER_PENDING_STATUS,
  ORDER_READY_TO_SHIP_STATUS,
  ORDER_SHIPPED_STATUS,
  ORDER_UNPAID_STATUS,
} from '_utils/LazadaConstants'
import { getStatusColor, mappingStatus } from '_utils/functions/formatter'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { AiFillPrinter } from 'react-icons/ai'
import { FaBoxOpen, FaUser } from 'react-icons/fa'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Tooltip } from 'reactstrap'
import Swal from 'sweetalert2'
import ModalGetReasonCancel from '../Common/ModalGetReasonCancel'

function OrderRecordLazadaItem({ item, index }) {
  const { addToast } = useToasts()
  const { selectedShop } = useSelector((state) => state.shop)
  const [isOpenModalGetReasonCancel, setIsOpenModalGetReasonCancel] = useState(false)
  const [listReasonCancel, setListReasonCancel] = useState([])
  const [selectedOrderProduct, setSelectedOrderProduct] = useState({})
  const dispatch = useDispatch()

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  useEffect(() => {
    if (!isOpenModalGetReasonCancel) setSelectedOrderProduct({})
  }, [isOpenModalGetReasonCancel])

  const toggleOpenModalGetReasonCancel = () =>
    setIsOpenModalGetReasonCancel(!isOpenModalGetReasonCancel)

  const checkAndShowError = (condition, errorMessage) => {
    if (condition) {
      return addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const onChangeStatus = async (e, _item, productSelected) => {
    const { value } = e.target
    const statusSelected = Number(value)

    const listStatusCannotChange = [
      ORDER_SHIPPED_STATUS,
      ORDER_DELIVERED_STATUS,
      ORDER_CONFIRMED_STATUS,
      ORDER_FAILED_DELIVERY_STATUS,
      ORDER_CANCELED_STATUS,
    ]

    setSelectedOrderProduct(productSelected)

    if (listStatusCannotChange.includes(productSelected.status)) {
      return addToast('You cannot update status order', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    if (statusSelected === ORDER_PENDING_STATUS) {
      return addToast('You cannot update status to pending', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    if (statusSelected === ORDER_CANCELED_STATUS) {
      return Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15cdca',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const { msgResp } = await lazadaApi.getReasonCancel({
              shopId: selectedShop?.id,
              orderItemId: productSelected.id,
            })
            setListReasonCancel(msgResp)
            setIsOpenModalGetReasonCancel(true)
            // Swal.fire('Canceled!', 'Your file has been deleted.', 'success')
          } catch (error) {
            Swal.fire('Error!', error.message, 'error')
          }
        }
      })
    }

    const conditionChangeToReadyToShip =
      productSelected.status !== ORDER_PACKED_STATUS &&
      statusSelected === ORDER_READY_TO_SHIP_STATUS

    if (conditionChangeToReadyToShip) {
      checkAndShowError(
        conditionChangeToReadyToShip,
        'When transitioning to the ready to ship stage, the order must be in packaged status.'
      )
      return
    }

    const conditionChangeToConfirmDBS =
      productSelected.status !== ORDER_PACKED_STATUS && statusSelected === ORDER_CONFIRMED_STATUS

    if (conditionChangeToConfirmDBS) {
      checkAndShowError(
        conditionChangeToConfirmDBS,
        'When transitioning to the confirm delivery for DBS stage, the order must be in packaged status.'
      )
      return
    }

    const conditionChangeToConfirmFailDBS =
      productSelected.status !== ORDER_PACKED_STATUS &&
      statusSelected === ORDER_FAILED_DELIVERY_STATUS

    if (conditionChangeToConfirmFailDBS) {
      checkAndShowError(
        conditionChangeToConfirmFailDBS,
        'When transitioning to the confirm delivery fail for DBS stage, the order must be in packaged status.'
      )
      return
    }

    checkAndShowError(
      productSelected.status !== ORDER_PENDING_STATUS && statusSelected === ORDER_PACKED_STATUS,
      'When transitioning to the packaging stage, the order must be in pending status.'
    )

    try {
      await dispatch(
        updateStatusOrderLazada(_item, productSelected, statusSelected, selectedShop?.id)
      )
      addToast('Update status successfully', {
        autoDismiss: true,
        appearance: 'success',
      })
      // Swal.fire('Canceled!', 'Your file has been deleted.', 'success')
    } catch (error) {
      // Swal.fire('Error!', error.message, 'error')
      addToast(error.message, {
        autoDismiss: true,
        appearance: 'error',
      })
    }
  }

  const handlePrintAWB = async (orderItems) => {
    const filterPackageId = orderItems
      .filter((order) => order.packageId)
      .map((order) => order.packageId)

    try {
      const { msgResp } = await lazadaApi.printAWB({
        shopId: selectedShop?.id,
        listPackageId: filterPackageId,
      })

      if (msgResp) {
        window.open(msgResp, '_blank')
      }
    } catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const handleRepackaged = async (_item) => {
    const listStatusFulfillmentVerify = [
      ORDER_SHIPPED_STATUS,
      ORDER_DELIVERED_STATUS,
      ORDER_FAILED_DELIVERY_STATUS,
      ORDER_CONFIRMED_STATUS,
    ]

    const listOrderWithPackageId = _item.orderItems.filter(
      (order) => order.packageId && !listStatusFulfillmentVerify.includes(order.status)
    )

    try {
      await dispatch(
        updateListOrderAfterRepackaged(
          selectedShop?.id,
          _item.lazadaOrderId,
          listOrderWithPackageId
        )
      )
      addToast('Change to re-packaged successfully.', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const onSelectOrderItem = (selectedOrderRecord, selectedOrderItem) => {
    dispatch(selectCheckBoxOrderItem(selectedOrderRecord.lazadaOrderId, selectedOrderItem))
  }

  return (
    <>
      <div
        className={`order-record-item rounded bg-white p-3 shadow mb-3 ${
          item.orderItems[index]?.status === ORDER_CANCELED_STATUS ? 'overlay-disabled' : ''
        }`}
      >
        <div className='row'>
          <div className='col text-break'>
            <div className='d-flex h-100'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='select-all'
                  checked={item.selectAll}
                  onChange={() => dispatch(selectAllOrderItems(item))}
                />
              </div>
              <FaUser className='me-1 f-16' />
              {item.buyerEmail}
              <span className='ms-1'>(Total products: {item.itemsCount})</span>
            </div>
          </div>
          <div className='col text-break'>
            <div className='row'>
              <div className='col-6'>
                Order Number: <span className='text-info'>{item.lazadaOrderId}</span>
              </div>
              <div className='col-6'>
                Created Date:{' '}
                <span className='text-info'>
                  {moment.unix(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                </span>
              </div>
              <div className='w-100 mb-2'></div>
              <div className='col-12'>
                <div className='d-flex column-gap-2 justify-content-end'>
                  {item?.orderItems?.some((product) => product.status === ORDER_PACKED_STATUS) ? (
                    <button
                      className='btn btn-outline-info w-auto shadow-none align-self-end'
                      onClick={() => handlePrintAWB(item.orderItems)}
                    >
                      Print AWB
                      <AiFillPrinter className='ms-1' />
                    </button>
                  ) : null}

                  {item?.orderItems?.some((product) => product.status === ORDER_PACKED_STATUS) ? (
                    <button
                      className='btn btn-outline-info w-auto shadow-none'
                      onClick={() => handleRepackaged(item)}
                    >
                      Repackage
                      <FaBoxOpen className='ms-1' />
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className='col col-auto text-break'>
            <button
              className='border rounded-circle btn-toggle'
              onClick={() => dispatch(toggleShowOrderProduct(item.lazadaOrderId))}
            >
              {item.isShowProductOrder ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
          </div>
        </div>
        {item?.isShowProductOrder ? (
          <>
            <hr />
            {item.orderItems.map((orderItem) => (
              <div className='row mb-3 align-items-start' key={orderItem.id}>
                <div className='col col-4 text-break'>
                  <div className='d-flex column-gap-3'>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id={orderItem.id}
                        checked={orderItem.selected}
                        onChange={() => onSelectOrderItem(item, orderItem)}
                      />
                    </div>
                    <div className='d-flex text-break'>
                      <img
                        src={orderItem.productMainImage}
                        alt='product-image'
                        className='rounded me-2 object-fit-cover'
                        style={{ height: 75 }}
                      />
                      <span className='fw-semibold f-14'>{orderItem.productName}</span>
                    </div>
                    <div className='d-flex flex-column align-items-center'>
                      <p className='mb-0'>
                        <CurrencyFormat
                          className='fw-bold'
                          value={orderItem.price || 0}
                          displayType={'text'}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                        ({item.currency})
                      </p>
                      <strong className='text-primary f-16'>
                        <CurrencyFormat
                          value={orderItem.quantity || 0}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix='x'
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </strong>
                    </div>
                  </div>
                </div>
                <div className='col text-break'>
                  <p className='mb-0'>
                    <CurrencyFormat
                      className='fw-bold'
                      value={orderItem.price + item.shippingFee || 0}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                    ({item.currency})
                  </p>
                  <small className='d-block my-1 fw-bold'>
                    Shipping fee:{' '}
                    <CurrencyFormat
                      value={item.shippingFee || 0}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </small>
                  <span className='badge bg-info'>{item.paymentMethod}</span>
                </div>
                <div className='col text-break'>Standard</div>
                <div className='col text-break'>
                  <p className='mb-0'>{mappingStatus(orderItem.status)}</p>
                  {orderItem.status === ORDER_PACKED_STATUS && orderItem?.promisedShippingTimes ? (
                    <>
                      <span className='badge bg-info my-1' id={'test'}>
                        {orderItem.promisedShippingTimes}
                      </span>
                      <Tooltip isOpen={true} target={'test'} toggle={toggle}>
                        Promised shipping times
                      </Tooltip>
                    </>
                  ) : null}
                  {orderItem?.reason ? (
                    <p className='mb-0' style={{ color: '#676767' }}>
                      [Reason Cancel]: {orderItem.reason}
                    </p>
                  ) : null}
                </div>
                <div className='col text-break'>
                  <select
                    className='form-select-sm border w-100 rounded-3'
                    value={orderItem?.status}
                    onChange={(e) => onChangeStatus(e, item, orderItem)}
                    style={{
                      color: getStatusColor(orderItem.status),
                      fontSize: '1em',
                    }}
                  >
                    {orderItem.status === ORDER_UNPAID_STATUS && (
                      <option className='bg-white text-default' value={ORDER_CANCELED_STATUS}>
                        Unpaid
                      </option>
                    )}
                    <option className='bg-white text-default' value={ORDER_CANCELED_STATUS}>
                      Cancelled
                    </option>
                    <option className='bg-white text-default' value={ORDER_PENDING_STATUS}>
                      Pending
                    </option>
                    <option className='bg-white text-default' value={ORDER_PACKED_STATUS}>
                      Packaged
                    </option>
                    <option className='bg-white text-default' value={ORDER_READY_TO_SHIP_STATUS}>
                      Ready to ship
                    </option>
                    <option className='bg-white text-default' value={ORDER_CONFIRMED_STATUS}>
                      Confirm delivery for DBS
                    </option>
                    <option className='bg-white text-default' value={ORDER_FAILED_DELIVERY_STATUS}>
                      Confirm delivery fail for DBS
                    </option>
                    {orderItem.status === ORDER_SHIPPED_STATUS && (
                      <option className='bg-white text-default' value={ORDER_SHIPPED_STATUS}>
                        Shipped
                      </option>
                    )}
                    {orderItem.status === ORDER_DELIVERED_STATUS && (
                      <option className='bg-white text-default' value={ORDER_DELIVERED_STATUS}>
                        Delivered
                      </option>
                    )}
                    {orderItem.status === ORDER_FAILED_DELIVERY_STATUS && (
                      <option
                        className='bg-white text-default'
                        value={ORDER_FAILED_DELIVERY_STATUS}
                      >
                        Delivery failed
                      </option>
                    )}
                    {orderItem.status === ORDER_CONFIRMED_STATUS && (
                      <option className='bg-white text-default' value={ORDER_CONFIRMED_STATUS}>
                        Confirmed
                      </option>
                    )}
                  </select>
                </div>
              </div>
            ))}
          </>
        ) : null}

        <ModalGetReasonCancel
          isOpen={isOpenModalGetReasonCancel}
          toggle={toggleOpenModalGetReasonCancel}
          listReasonCancel={listReasonCancel}
          selectedOrderProduct={selectedOrderProduct}
          itemSelected={item}
        />
      </div>
    </>
  )
}

export default OrderRecordLazadaItem
