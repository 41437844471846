import { setListDateIsPicked } from '_redux/modules/order'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useQuery from '_hooks/useQuery'
import { ORDERS_PAGE } from '_utils/constant'
import moment from 'moment'
import './style.scss'

function formatDate(item, typePage) {
  const dateToFormat =
    item?.pickupBookingDate?.createdAtOrder ||
    item?.deliveryBookingDate?.createdAtOrder ||
    item?.createdAt

  const formatString = typePage === ORDERS_PAGE ? 'DD MMMM YYYY' : 'DD MMM YYYY'
  const formattedDate = moment.unix(dateToFormat).format(formatString)
  return moment.unix(dateToFormat).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
    ? 'Today'
    : formattedDate
}

function sortData(data, typePage) {
  return data.sort((a, b) => {
    if (typePage === ORDERS_PAGE) {
      return a.createdAt < b.createdAt ? -1 : 1
    }
    return a.deliveryTimestamp < b.deliveryTimestamp ? -1 : 1
  })
}

function ListItem({ item, isActive, handleClick, typePage }) {
  const isToday = item.displayDate === 'Today'
  return (
    <div
      className={`list_date-fulfillment-item cursor-pointer ${isActive ? 'active-s' : ''}`}
      onClick={handleClick}
      style={!isToday ? { height: 55 } : {}}
    >
      <div
        className={`d-flex w-100 ${
          !isToday ? 'flex-column gap-1' : 'align-items-center justify-content-between me-2'
        }`}
      >
        <span>{item.displayDate}</span>
        {typePage !== ORDERS_PAGE && <span>{moment.unix(item.createdAt).format('HH:mm:ss')}</span>}
      </div>
      <span>{'>'}</span>
    </div>
  )
}

function ListDateFulfillment({ data, handleAddItemIntoListDateIsPicked, listDate, typePage }) {
  const sortedData = useMemo(
    () =>
      sortData(data, typePage).map((item) => {
        const timestamp = item?.createdAt ?? item?.pickupTimestamp ?? item?.deliveryTimestamp
        const address = item?.pickupAddress ?? item.uAddress ?? item.address

        return {
          ...item,
          displayDate: formatDate(item, typePage),
          isActive: listDate.some(
            (date) => (date.deliveryTimestamp || date.createdAt) === timestamp
          ),
          handleClick: () =>
            handleAddItemIntoListDateIsPicked(
              timestamp === item?.createdAt
                ? { createdAt: timestamp, address }
                : { deliveryTimestamp: timestamp, address }
            ),
        }
      }),
    [JSON.stringify(data), typePage, JSON.stringify(listDate), handleAddItemIntoListDateIsPicked]
  )

  return (
    <>
      {sortedData.map((item, index) => (
        <ListItem
          key={index}
          item={item}
          isActive={item.isActive}
          handleClick={item.handleClick}
          typePage={typePage}
        />
      ))}
    </>
  )
}

export default function FulfillmentCalendar({ typePage }) {
  const dispatch = useDispatch()
  const query = useQuery()
  const deliveryIdParam = query.get('deliveryId')
  const startDateParam = query.get('startDate')
  const endDateParam = query.get('endDate')

  const { listOrdersByHost, listOrderFulfillment, listDateIsPicked } = useSelector(
    (state) => state.order
  )
  const { currentDeliveryAndDeliveryBooking } = useSelector((state) => state.deliveryEvent)

  const { shopEventDeliveryBookings = [] } = currentDeliveryAndDeliveryBooking

  const listOrdersDelivery = useMemo(() => {
    if (deliveryIdParam && startDateParam && endDateParam) {
      const filteredList = shopEventDeliveryBookings.filter((item) => {
        const deliveryTime = moment.unix(item.createdAt)
        const startDate = moment.unix(parseInt(startDateParam, 10)).toDate()
        const endDate = moment.unix(parseInt(endDateParam, 10)).toDate()
        return deliveryTime.isBetween(startDate, endDate)
      })
      return filteredList
    }
    return listOrderFulfillment
  }, [
    deliveryIdParam,
    JSON.stringify(shopEventDeliveryBookings),
    JSON.stringify(listOrderFulfillment),
    startDateParam,
    endDateParam,
  ])

  const handleAddItemIntoListDateIsPicked = (ref) => {
    const { deliveryTimestamp, createdAt } = ref
    const newDate = deliveryTimestamp || createdAt

    const isDateAlreadySelected = listDateIsPicked.some(
      (item) => (item.deliveryTimestamp || item.createdAt) === newDate
    )

    let newListDateIsPicked

    if (isDateAlreadySelected) {
      newListDateIsPicked = listDateIsPicked.filter(
        (item) => (item.deliveryTimestamp || item.createdAt) !== newDate
      )
    } else {
      newListDateIsPicked = [ref]
    }

    dispatch(setListDateIsPicked(newListDateIsPicked))
  }

  const handleClearDuplicateInArray = (array) => {
    const convertData = () =>
      array?.map((data) => {
        const dateToFormat =
          data?.pickupBookingDate?.createdAtOrder ||
          data?.deliveryBookingDate?.createdAtOrder ||
          data?.createdAt

        const formatString = typePage === ORDERS_PAGE ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:mm:ss'
        return {
          ...data,
          createdAtString: moment.unix(dateToFormat).format(formatString),
        }
      }) || []

    const arrayAfterConvert = convertData()

    const uniqueIDate = []
    const unique = arrayAfterConvert.filter((element) => {
      const isDuplicate = uniqueIDate.includes(element.createdAtString)

      if (!isDuplicate) {
        uniqueIDate.push(element?.createdAtString)
        return true
      }

      return false
    })

    return unique
  }

  return (
    <>
      <div className='list_date-fulfillment'>
        <ListDateFulfillment
          data={
            typePage === ORDERS_PAGE
              ? handleClearDuplicateInArray(listOrdersByHost)
              : handleClearDuplicateInArray(listOrdersDelivery)
          }
          listDate={listDateIsPicked}
          typePage={typePage}
          handleAddItemIntoListDateIsPicked={handleAddItemIntoListDateIsPicked}
        />
      </div>
    </>
  )
}
