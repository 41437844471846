import { DeliveryContext } from '_contexts/DeliveryContext'
import React, { useContext, useEffect, useState } from 'react'
import { DateRange } from 'react-date-range'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

function ModalFulfillmentDate() {
  const {
    isModalOpen,
    toggleModal,
    getRangeFulfillmentDate,
    rangeDate: fulfillmentDate,
  } = useContext(DeliveryContext)

  const [rangeDate, setRangeDate] = useState([])

  useEffect(() => {
    setRangeDate(fulfillmentDate)
  }, [JSON.stringify(fulfillmentDate)])

  const formatDate = (date) => {
    const { startDate, endDate, key } = date[0]
    const newStartDate = new Date(startDate?.setHours(0, 0, 0, 0))
    const newEndDate = new Date(endDate?.setHours(23, 59, 59, 999))

    return [
      {
        startDate: newStartDate,
        endDate: newEndDate,
        key,
      },
    ]
  }

  const handleSubmit = () => {
    toggleModal()
    getRangeFulfillmentDate(formatDate(rangeDate))
  }

  const handleCancel = () => {
    toggleModal()
  }

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} className='modal-fulfillment-date'>
      <ModalBody>
        <DateRange
          editableDateInputs={true}
          onChange={(item) => setRangeDate([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={rangeDate}
          rangeColors={['#15cdca']}
        />
      </ModalBody>
      <ModalFooter className='border-top-0 py-0'>
        <div className='d-flex align-items-center flex-row-reverse'>
          <button className='btn shadow-none rounded-pill btn-submit' onClick={handleSubmit}>
            OK
          </button>
          <button
            className='btn bg-transparent border-0 shadow-none rounded btn-cancel mt-0 text-secondary'
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ModalFulfillmentDate
