import CustomTimePicker from '_components/CustomTimePicker'
import { selectDateAndTime } from '_redux/modules/order'
import { DELIVERY_OPTION, SELF_PICK_UP } from '_utils/constant'
import { convertToCurrentGMT, convertToGMT0 } from '_utils/functions/converter'
import { getCartCheckout, setCartCheckout } from '_utils/localData'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import { BiCalendar } from 'react-icons/bi'
import { GoClock } from 'react-icons/go'
import { useDispatch, useSelector } from 'react-redux'

const PER_DAY = 1440 // Number of minutes in a day

const valueDay = {
  Sun: 1,
  Mon: 2,
  Tue: 3,
  Wed: 4,
  Thu: 5,
  Fri: 6,
  Sat: 7,
}

function Calendar({
  datesOfWeek,
  timeSlot,
  startTime,
  endTime,
  activeTab,
  type,
  isOpenTimePicker,
  setIsOpenTimePicker,
}) {
  if (!datesOfWeek) return null

  const dispatch = useDispatch()
  const cart = getCartCheckout()

  const [startDate, setStartDate] = useState(() => moment().add(1, 'day').toDate())
  const [timeSlotState, setTimeSlotState] = useState(timeSlot)

  const { dateAndTime, orderDetailsByRef } = useSelector((state) => state.order)
  const { selectedPickUp, selectedDelivery } = useSelector((state) => state.fulfillment)

  const currentStartTime = convertToCurrentGMT(`${datesOfWeek[0].date} ${startTime}`).slice(11)
  const currentEndTime = convertToCurrentGMT(`${datesOfWeek[6].date} ${endTime}`).slice(11)

  useEffect(() => {
    if (timeSlot !== undefined && timeSlot === null) {
      const THIRTY_MINUTES = 30
      setTimeSlotState(THIRTY_MINUTES)
    } else {
      setTimeSlotState(timeSlot)
    }
  }, [timeSlot])

  /**
   * minTime: 10:00
   * timeSlot: 120
   * maxTime: 15:00
   * => ["10:00 - 12:00", "12:00 - 14:00", "14:00 - 15:00"]
   */
  /**
   * minTime: 10:00
   * timeSlot: 120
   * maxTime: 15:00
   * => ["10:00 - 12:00", "12:00 - 14:00", "14:00 - 15:00"]
   */
  const timeIntervals = useMemo(() => {
    const selectedDate = moment(startDate).format('DD-MM-YYYY')
    if (timeSlotState && timeSlotState < PER_DAY) {
      const minTime = moment(currentStartTime, 'HH:mm')
      let maxTime = moment(currentEndTime, 'HH:mm')
      const timeSlots = []

      if (currentEndTime === '00:00') {
        maxTime = moment().endOf('day')
      }

      while (minTime.isBefore(maxTime)) {
        const newStartTime = minTime.format('HH:mm')
        minTime.add(timeSlotState, 'minutes')
        let newEndTime = minTime.format('HH:mm')
        if (newEndTime === '00:00' || minTime.isAfter(maxTime)) {
          newEndTime = '23:59'
        }
        timeSlots.push(`${selectedDate}, ${newStartTime} - ${newEndTime}`)
      }

      return timeSlots
    }

    const currentStartTimeGMT = convertToCurrentGMT(`28-12-2021 ${startTime}`).slice(11)
    const currentEndTimeGMT = convertToCurrentGMT(`28-12-2021 ${endTime}`).slice(11)
    return [`${selectedDate}, ${currentStartTimeGMT} - ${currentEndTimeGMT}`]
  }, [
    timeSlotState,
    activeTab,
    JSON.stringify(startTime),
    JSON.stringify(endTime),
    JSON.stringify(startDate),
  ])

  const [rangeTime, setRangeTime] = useState('')

  const convertToTimestamp = (dateStr, timeRange) => {
    if (timeRange) {
      const [, _endTime] = timeRange.split(' - ')

      const dateTimeStr = `${dateStr} ${_endTime}`

      const dateTime = moment(dateTimeStr, 'DD-MM-YYYY HH:mm')

      return dateTime.unix()
    }
  }

  const filterDateClose = useCallback(
    (date) => {
      const day = date.getDay()
      const { isClose } = datesOfWeek[day]
      // const { isHoliday } = datesOfWeek[day]

      return !isClose
    },
    [datesOfWeek]
  )

  const getNextAvailableDate = useCallback(
    (currentDate) => {
      const nextDate = moment(currentDate)
      while (!filterDateClose(nextDate.toDate())) {
        nextDate.add(1, 'days')
      }
      return nextDate.toDate()
    },
    [filterDateClose]
  )

  useEffect(() => {
    dispatch(
      selectDateAndTime({
        ...dateAndTime,
        date: moment(startDate).format('DD-MM-YYYY'),
        title: moment(startDate).format('ddd'),
        value: valueDay[moment(startDate).format('ddd')],
      })
    )
  }, [moment(startDate).format('DD-MM-YYYY')])

  useEffect(() => {
    let today = moment()
    const nextAvailableDate = getNextAvailableDate(today.toDate())
    setStartDate(nextAvailableDate)

    const bookingDateDelivery = orderDetailsByRef?.orderDelivery?.deliveryBookingDate
    const bookingDatePickup = orderDetailsByRef?.orderPickup?.pickupBookingDate
    const orderBookingDate = bookingDateDelivery || bookingDatePickup

    if (orderBookingDate) {
      const bookingMomentDate = moment(orderBookingDate?.date, 'DD-MM-YYYY')

      if (!filterDateClose(bookingMomentDate.toDate())) {
        today = getNextAvailableDate(bookingMomentDate.toDate())
      } else {
        today = bookingMomentDate
      }

      setStartDate(moment(today, 'DD-MM-YYYY').toDate())
      dispatch(
        selectDateAndTime({
          ...dateAndTime,
          date: moment(today).format('DD-MM-YYYY'),
          title: moment(today).format('ddd'),
          value: valueDay[moment(today).format('ddd')],
        })
      )
    } else if (timeIntervals?.length && timeIntervals[0]) {
      const currentDate = moment()
      let selectedInterval = timeIntervals[0]
      const listDisabledTime = []

      for (let i = 0; i < timeIntervals.length; i++) {
        const interval = timeIntervals[i]
        const intervalDate = moment(interval.split(',')[0], 'DD-MM-YYYY')
        const intervalRangeTime = interval.split(',')[1]
        const intervalEndTime = moment(
          `${intervalDate.format('DD-MM-YYYY')} ${intervalRangeTime.split('-')[1].trim()}`,
          'DD-MM-YYYY HH:mm'
        )

        if (currentDate.isAfter(intervalEndTime)) {
          listDisabledTime.push(intervalEndTime)
        }

        if (!currentDate.isAfter(intervalEndTime)) {
          selectedInterval = interval
          break
        }
      }

      if (listDisabledTime.length === timeIntervals.length) {
        setStartDate(moment(today).add(1, 'days').toDate())
      }

      setRangeTime(selectedInterval)

      const convertRangeTimeToGMT0 = selectedInterval
        .split(',')[1]
        ?.split('-')
        ?.map((time) => {
          const timeGMT0 = convertToGMT0(`
      ${moment(startDate).format('DD-MM-YYYY')}
      ${time}
    `)
          return timeGMT0?.slice(11)
        })
        ?.join(' - ')

      dispatch(
        selectDateAndTime({
          ...dateAndTime,
          date: moment(nextAvailableDate).format('DD-MM-YYYY'),
          title: moment(nextAvailableDate).format('ddd'),
          value: valueDay[moment(nextAvailableDate).format('ddd')],
          time: convertRangeTimeToGMT0,
        })
      )
    }
  }, [getNextAvailableDate])

  useEffect(() => {
    const bookingDateDelivery = orderDetailsByRef?.orderDelivery?.deliveryBookingDate
    const bookingDatePickup = orderDetailsByRef?.orderPickup?.pickupBookingDate
    const bookingTimeDelivery = orderDetailsByRef?.orderDelivery?.deliveryBookingTime
    const bookingTimePickup = orderDetailsByRef?.orderPickup?.pickupBookingTime

    const orderPickupId = orderDetailsByRef?.orderPickup?.pickupOptionId
    const orderDeliveryId = orderDetailsByRef?.orderDelivery?.deliveryOptionId
    const orderFulfillmentId = orderDeliveryId || orderPickupId

    if (
      (orderFulfillmentId === selectedPickUp?.id || orderFulfillmentId === selectedDelivery?.id) &&
      (bookingDateDelivery || bookingDatePickup)
    ) {
      const bookingDate = bookingDateDelivery || bookingDatePickup
      const rangeTimeBooking = bookingTimeDelivery || bookingTimePickup

      const convertRangeTimeOrderToArray = rangeTimeBooking.split('-')

      const convertRangeTimeOrderToCurrentGMT = convertRangeTimeOrderToArray
        ?.map((time) => {
          const currentTime = convertToCurrentGMT(`${bookingDate?.date} ${time.trim()}`)
          return currentTime?.slice(11)
        })
        ?.join(' - ')

      setRangeTime(convertRangeTimeOrderToCurrentGMT)

      const selectedDate = moment(startDate).format('DD-MM-YYYY')

      if (bookingDate?.date === selectedDate) {
        dispatch(
          selectDateAndTime({
            ...dateAndTime,
            time: rangeTimeBooking,
            date: bookingDate?.date,
            title: bookingDate?.title,
            value: bookingDate?.value,
          })
        )
      } else {
        dispatch(
          selectDateAndTime({
            ...dateAndTime,
            time: rangeTimeBooking,
            date: selectedDate,
            title: bookingDate?.title,
            value: bookingDate?.value,
          })
        )
      }
    } else if (timeIntervals?.length && timeIntervals[0]) {
      const today = moment()
      let selectedInterval = timeIntervals[0]
      const listDisabledTime = []

      for (let i = 0; i < timeIntervals.length; i++) {
        const interval = timeIntervals[i]
        const intervalDate = moment(interval.split(',')[0], 'DD-MM-YYYY')
        const intervalRangeTime = interval.split(',')[1]
        const intervalEndTime = moment(
          `${intervalDate.format('DD-MM-YYYY')} ${intervalRangeTime.split('-')[1].trim()}`,
          'DD-MM-YYYY HH:mm'
        )

        if (today.isAfter(intervalEndTime)) {
          listDisabledTime.push(intervalEndTime)
        }

        if (!today.isAfter(intervalEndTime)) {
          selectedInterval = interval
          break
        }
      }

      if (listDisabledTime.length === timeIntervals.length) {
        setStartDate(moment(today).add(1, 'days').toDate())
      }

      setRangeTime(selectedInterval)

      const convertRangeTimeToGMT0 = selectedInterval
        .split(',')[1]
        ?.split('-')
        ?.map((time) => {
          const timeGMT0 = convertToGMT0(`
        ${moment(startDate).format('DD-MM-YYYY')}
        ${time}
      `)
          return timeGMT0?.slice(11)
        })
        ?.join(' - ')

      dispatch(
        selectDateAndTime({
          ...dateAndTime,
          time: convertRangeTimeToGMT0,
          date: moment(startDate).format('DD-MM-YYYY'),
          title: moment(startDate).format('ddd'),
          value: valueDay[moment(startDate).format('ddd')],
        })
      )
    }

    return () => {
      dispatch(selectDateAndTime({}))
    }
  }, [selectedPickUp?.id, selectedDelivery?.id, JSON.stringify(timeIntervals)])

  useEffect(() => {
    const closeDropdown = (event) => {
      const clickedOutside = !document
        .getElementById(type === SELF_PICK_UP ? 'pickup-time' : 'delivery-time')
        .contains(event.target)

      if (clickedOutside) {
        setIsOpenTimePicker(false)
      }
    }

    if (isOpenTimePicker) {
      document.addEventListener('click', closeDropdown)
    }

    return () => {
      document.removeEventListener('click', closeDropdown)
    }
  }, [isOpenTimePicker])

  const onSelectStartDate = (date) => {
    const convertRangeTimeToArray = rangeTime.split('-')

    const convertRangeTimeOrderToCurrentGMT = convertRangeTimeToArray
      ?.map((time) => {
        const currentTime = convertToGMT0(`${moment(date).format('DD-MM-YYYY')} ${time.trim()}`)
        return currentTime?.slice(11)
      })
      ?.join(' - ')

    setStartDate(date)
    dispatch(
      selectDateAndTime({
        date: moment(date).format('DD-MM-YYYY'),
        title: moment(date).format('ddd'),
        value: valueDay[moment(date).format('ddd')],
        time: convertRangeTimeOrderToCurrentGMT,
        createdAtOrder: moment(date).unix(),
      })
    )

    setCartCheckout({
      ...cart,
      startDate: moment(date).format(),
    })
  }

  const onSelectTime = (rangeTimeSelected) => {
    const convertedRangeTimeSelected = rangeTimeSelected.split(',')[1]
    const rangeTimeGMT0 = convertedRangeTimeSelected
      .split('-')
      ?.map((time) => {
        const timeGMT0 = convertToGMT0(`${moment(startDate).format('DD-MM-YYYY')} ${time}`)
        return timeGMT0.slice(11)
      })
      .join(' - ')
    setRangeTime(rangeTimeSelected)
    const [, _endTime] = convertedRangeTimeSelected.split(' - ')
    setStartDate(
      moment(startDate)
        .set({
          hour: Number(_endTime.slice(0, 2)),
          minute: Number(_endTime.slice(3)),
        })
        .toDate()
    )
    dispatch(
      selectDateAndTime({
        date: moment(startDate).format('DD-MM-YYYY'),
        title: moment(startDate).format('ddd'),
        value: valueDay[moment(startDate).format('ddd')],
        time: rangeTimeGMT0,
        createdAtOrder: convertToTimestamp(moment(startDate).format('DD-MM-YYYY'), rangeTimeGMT0),
      })
    )
    setCartCheckout({
      ...cart,
      rangeTimeBooking: rangeTimeGMT0,
      startDate: moment(startDate)
        .set({
          hour: Number(_endTime.slice(0, 2)),
          minute: Number(_endTime.slice(3)),
        })
        .format(),
    })
  }

  return (
    <div className='row mb-3'>
      <div className='col-6 position-relative'>
        <p className='mb-0 fw-semibold'>
          {type === DELIVERY_OPTION ? 'Delivery' : 'Pick-up'} Date:
        </p>
        <DatePicker
          className='border cursor-pointer'
          selected={startDate}
          onChange={(date) => onSelectStartDate(date)}
          filterDate={(date) => filterDateClose(date)}
          dateFormat='dd/MM/yyyy'
          placeholderText='Select a date other than today or yesterday'
          minDate={moment().toDate()}
        />
        <span
          className='position-absolute'
          style={{
            left: 16,
            top: '45%',
          }}
        >
          <BiCalendar />
        </span>
      </div>
      <div className='col-6'>
        <p className='mb-0 fw-semibold'>
          {type === DELIVERY_OPTION ? 'Delivery' : 'Pick-up'} Time:
        </p>
        <CustomTimePicker
          isOpen={isOpenTimePicker}
          setIsOpen={setIsOpenTimePicker}
          timeIntervals={timeIntervals}
          selectedTime={rangeTime.includes(',') ? rangeTime.split(',')[1] : rangeTime}
          type={type}
          onChange={(rangeTimeSelected) => {
            onSelectTime(rangeTimeSelected)
          }}
        />
        <span
          className='position-absolute'
          style={{
            left: 16,
            top: '45%',
          }}
        >
          <GoClock />
        </span>
      </div>
    </div>
  )
}

export default Calendar
