/* eslint-disable no-prototype-builtins */
/* eslint-disable function-paren-newline */
import lazadaApi from '_api/lazada'
import productApi from '_api/product'
import uploadApi from '_api/upload'
import { PLATFORM_CO_KII, TURN_ON } from '_utils/constant'
import { generateIdWithLength } from '_utils/functions/generator'
import moment from 'moment'
import { selectShopAndSelectShopDetail } from './shop'

import {
  ADD_PRODUCT_SUCCESS,
  CLEAR_LAZADA_CATEGORY_SUGGESTIONS,
  CLEAR_SELECT_PRODUCT_LAZADA,
  COPY_PRODUCT,
  DELETE_PRODUCT_LAZADA,
  DELETE_PRODUCT_SUCCESS,
  EXPORT_PRODUCT_TO_LAZADA,
  FETCH_LIST_PRODUCT,
  FETCH_LIST_PRODUCT_LAZADA,
  FETCH_LIST_VARIATION_PRODUCT,
  GET_BRAND_BY_PAGES,
  GET_LAZADA_CATEGORY_SUGGESTIONS,
  HANDLE_ADD_VARIATION,
  HANDLE_UPDATE_VARIATION,
  PUSH_TO_LAZADA,
  PUSH_TO_LAZADA_FAILED,
  PUSH_TO_LAZADA_SUCCESS,
  SELECT_PRODUCT_LAZADA,
  SELECT_PRODUCT_TO_PUSH_LAZADA,
  SELECT_TAB,
  SWAP_DOWN_PRODUCT,
  SWAP_UP_PRODUCT,
  TOGGLE_ACTIVE_PRODUCT,
  TOGGLE_SUB_ITEM,
  TOGGLE_VARIATIONS,
  UPDATE_EFFECT,
  UPDATE_LIST_PRODUCT_LAZADA,
  UPDATE_NEW_LIST_PRODUCT,
  UPDATE_NEW_LIST_PRODUCT_LAZADA,
  UPDATE_NEW_LIST_VARIATION_WITH_ACTION_REMOVE,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_SINGLE_PRODUCT_LAZADA,
  UPLOAD_IMAGE_PRODUCT,
  UPLOAD_IMAGE_PRODUCT_LAZADA,
} from '../constants/productConstants'
import { UPDATE_MY_SHOPS } from '../constants/shopConstants'

const initialState = {
  listProduct: [],
  error: null,
  listProductLazada: [],
  tabSelected: null,
  productSelected: null,
  productLazadaSelected: null,
  listLazadaCategorySuggestions: [],
  productType: '',
  brandByPages: [],
  errorMessageCategory: '',
}

export const updateVariationProduct = (data) => async (dispatch, getState) => {
  try {
    const { product } = getState()
    const { listProductLazada } = product
    const newListProductLazada = [...listProductLazada]

    const updatedList = newListProductLazada.map((item) => {
      if (item.id === data.productId) {
        return {
          ...item,
          listVariationProduct: data.listVariationProduct,
          variations: data.variations,
        }
      }

      return item
    })

    await lazadaApi.modifyLazadaVariationProduct(data)

    dispatch({
      type: HANDLE_UPDATE_VARIATION,
      payload: {
        listProductLazada: updatedList,
      },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const clearProductLazadaSelected = () => (dispatch) => {
  dispatch({
    type: CLEAR_SELECT_PRODUCT_LAZADA,
    payload: { productLazadaSelected: null, productType: '' },
  })
}

export const selectAndToggleProductLazadaModal =
  (productLazada, productType) => (dispatch, getState) => {
    const { product } = getState()
    const { listProductLazada } = product

    const updatedList = listProductLazada.map((item) => {
      if (item.id === productLazada.id) {
        return {
          ...item,
          isOpenModalEdit: !item.isOpenModalEdit,
        }
      }
      return item
    })

    dispatch({
      type: SELECT_PRODUCT_LAZADA,
      payload: {
        productLazadaSelected: productLazada,
        productType,
        listProductLazada: updatedList,
      },
    })
  }

export const handleAddVariation = (postData, productSelected) => async (dispatch) => {
  try {
    const convertPostData = {
      ...postData,
      listVariationProduct: postData.listVariationProduct.map((item) => {
        const { createdDate, createdUserId, shopId, pid, codeProd, ...restItem } = item

        const matchVariant = productSelected.listVariationProduct?.find((mock) =>
          Object.entries(mock.saleProp).every(
            ([key, value]) => item.saleProp.hasOwnProperty(key) && item.saleProp[key] === value
          )
        )

        if (matchVariant) {
          restItem.id = matchVariant.id
          restItem.sku_id = matchVariant.sku_id
        }

        return restItem
      }),
    }

    const seenItems = []
    const uniqueArray = convertPostData.listVariationProduct.map((item) => {
      const newItem = { ...item }

      const itemIdSku = `${item.id}-${item.sku_id}`

      if (!seenItems.includes(itemIdSku)) {
        seenItems.push(itemIdSku)
      } else {
        delete newItem.id
        delete newItem.sku_id
      }

      return newItem
    })

    const newConvertData = {
      ...convertPostData,
      listVariationProduct: uniqueArray,
    }

    await lazadaApi.modifyLazadaVariationProduct(newConvertData)

    dispatch({
      type: HANDLE_ADD_VARIATION,
      payload: {},
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const updateSingleProductLazada = (data, shopId) => async (dispatch) => {
  try {
    const postData = {
      shopId,
      productId: data.id,
      name: data.name,
      description: data.description,
      quantity: data.quantity,
      price: data.price,
      photoUrl: data.photoUrl,
      height: Number(data.height) || data.height,
      length: Number(data.length) || data.length,
      width: Number(data.width) || data.width,
      weight: Number(data.weight) || data.weight,
      active: Number(data.active),
      categoryId: data.categoryId,
    }

    await lazadaApi.updateSingleProduct(postData)

    dispatch({
      type: UPDATE_SINGLE_PRODUCT_LAZADA,
      payload: {},
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const updateNewListProduct = (newList) => (dispatch) => {
  dispatch({
    type: UPDATE_NEW_LIST_PRODUCT,
    payload: { listProduct: newList },
  })
}

export const selectTab = (tab) => (dispatch) => {
  dispatch({
    type: SELECT_TAB,
    payload: { tabSelected: tab },
  })
  localStorage.setItem('selectedTabProduct', tab)
}

export const fetchListVariationProductLazada = () => async (dispatch, getState) => {
  const { product } = getState()
  const { listProductLazada } = product
  const listProductId = listProductLazada.map((prod) => ({
    id: prod.id,
    isPendingToPush: prod.isPendingToPush,
  }))

  try {
    const responses = await Promise.all(
      listProductId.map(async (prod) => {
        if (prod.isPendingToPush) return []
        const res = await lazadaApi.fetchListVariationProduct(prod.id)
        return res.msgResp
      })
    )

    const responseMap = {}

    responses.forEach((response, index) => {
      responseMap[`res${index + 1}`] = response
    })

    const updatedListProduct = listProductLazada.map((prod) => {
      const productId = prod.id

      const allVariations = Object.values(responseMap).flatMap((variations) => variations)

      const matchingVariations = allVariations.filter((variation) => variation.pid === productId)

      if (matchingVariations.length) {
        return {
          ...prod,
          listVariationProduct: matchingVariations,
        }
      }

      return prod
    })

    dispatch({
      type: FETCH_LIST_VARIATION_PRODUCT,
      payload: {
        listProductLazada: updatedListProduct,
      },
    })
    localStorage.setItem('listProductLazada', JSON.stringify(updatedListProduct))
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const fetchListVariationByProductId = (prodId) => async (dispatch, getState) => {
  try {
    const { product } = getState()
    const { listProductLazada } = product

    const { msgResp } = await lazadaApi.fetchListVariationProduct(prodId)

    const productIndex = listProductLazada.findIndex((p) => p.id === prodId)

    if (productIndex !== -1) {
      const updatedList = [...listProductLazada]
      updatedList[productIndex] = {
        ...updatedList[productIndex],
        listVariationProduct: msgResp,
      }

      dispatch({
        type: FETCH_LIST_VARIATION_PRODUCT,
        payload: { listProductLazada: updatedList },
      })
    }
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const fetchListProductLazada = (shopId) => async (dispatch, getState) => {
  const { product } = getState()
  const { listProductLazada } = product
  const currentListProductLazada = [...listProductLazada]
    .filter((item) => !item.item_id)
    .map((item) => ({
      ...item,
      isOpenVariations: false,
    }))

  try {
    const { msgResp } = await lazadaApi.fetchListProduct(shopId)
    const mappedResponse = msgResp.map((item) => ({
      ...item,
      isOpenVariations: false,
      availableOfStock: item.quantity,
    }))

    if (currentListProductLazada.length) {
      dispatch({
        type: FETCH_LIST_PRODUCT_LAZADA,
        payload: {
          listProductLazada: [...currentListProductLazada, ...mappedResponse],
        },
      })
    } else {
      dispatch({
        type: FETCH_LIST_PRODUCT_LAZADA,
        payload: {
          listProductLazada: mappedResponse,
        },
      })
    }
  } catch (e) {
    throw new Error(e.message || e.msgResp)
  }
}

export const fetchListProduct = (shopId) => (dispatch, getState) => {
  const { shop } = getState()
  const { myShops } = shop

  const shopSelected = myShops.find((item) => item.id === shopId)

  try {
    // const { msgResp } = await virtualShopApi.getAllShopsOpening()
    // const shopSelected = shopSelected.find((item) => item.id === shopId)
    const listProductConverted = shopSelected?.products?.map((item) => ({
      ...item,
      subItems: item?.subItems
        ?.map((subItem) => ({
          ...subItem,
          isOpenSubItems: false,
          id: generateIdWithLength(20),
          list: subItem?.list?.map((list) => ({
            ...list,
            id: generateIdWithLength(20),
          })),
        }))
        .sort((a, b) => {
          const dateA = moment.unix(a.createdAt)
          const dateB = moment.unix(b.createdAt)
          return dateB - dateA
        }),
    }))

    if (listProductConverted?.length) {
      dispatch({
        type: FETCH_LIST_PRODUCT,
        payload: { listProduct: listProductConverted },
      })
    }
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const updateProduct = (data, shopInfo) => async (dispatch, getState) => {
  try {
    const { product, shop } = getState()
    const { listProduct } = product
    const { shopDetail } = shop

    const convertDate = (date) => {
      if (date) {
        const format = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        const hours = `0${date.getHours()}`.slice(-2)
        const minutes = `0${date.getMinutes()}`.slice(-2)
        const newFormat = format.split('/')
        return `${newFormat[1]}-${newFormat[0]}-${newFormat[2]} ${hours}:${minutes}`
      }
    }

    const dataDateSelection = {
      date: convertDate(data?.dateSelection),
      maxQtyPerDay: Number(data?.maxOrderQtyByDate),
    }

    const convertDataForServer = {
      ...data,
      dateSelectInfo: shopInfo.dateSelection === TURN_ON ? dataDateSelection : null,
      subItems: data.subItems.map((subItem) => {
        const { isOpenSubItems, ...restSubItem } = subItem
        return restSubItem
      }),
    }

    const convertDataForState = {
      ...data,
      price: parseFloat(data?.price),
      inventory: Number(data?.inventory),
      dateSelectInfo: shopInfo.dateSelection === TURN_ON ? dataDateSelection : null,
    }

    const newListUpdate = [...listProduct]
    const findIndex = newListUpdate.findIndex((item) => item.id === data.id)
    newListUpdate[findIndex] = { ...convertDataForState }
    newListUpdate[findIndex].isCopied = false

    dispatch(selectShopAndSelectShopDetail({ ...shopDetail, products: newListUpdate }))
    dispatch(updateNewListProduct(newListUpdate))

    await productApi.updateShopProduct(data.id, shopDetail?.id, convertDataForServer)

    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: { listProduct: newListUpdate },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const deleteProduct = (shopDetail, pid, pIndex) => async (dispatch, getState) => {
  const { product, shop } = getState()
  const { listProduct } = product
  const { myShops } = shop

  try {
    await productApi.deleteShopProduct(shopDetail.id, pid)
    const updatedProducts = listProduct.filter((p) => p.id !== pid && p.index !== pIndex)
    const updatedShopDetail = {
      ...shopDetail,
      products: updatedProducts,
    }

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: {
        listProduct: updatedProducts,
      },
    })
    dispatch(selectShopAndSelectShopDetail(updatedShopDetail))

    // eslint-disable-next-line no-confusing-arrow
    const updatedMyShops = myShops.map((shopItem) =>
      shopItem.id === shopDetail.id ? updatedShopDetail : shopItem
    )

    dispatch({
      type: UPDATE_MY_SHOPS,
      payload: {
        myShops: updatedMyShops,
      },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const copyProduct = (shopDetail, pId, pIndex) => async (dispatch, getState) => {
  const { product } = getState()
  const { listProduct } = product
  const newListProduct = [...listProduct]

  const index = newListProduct.findIndex((p) => p.id === pId && p.index === pIndex)
  const newProduct = newListProduct[index]

  const convertData = {
    products: [
      {
        categoryId: null,
        items: [
          {
            photoUrls: newProduct.photoUrls,
            active: newProduct.active,
            name: newProduct.name,
            description: newProduct.description,
            dateSelectInfo: newProduct?.dateSelectInfo || null,
            price: newProduct.price,
            maxQuantity: newProduct.maxQuantity,
            minOrderQty: newProduct.minOrderQty,
            defaultQuantity: newProduct.defaultQuantity,
            limitPerOrder: newProduct.limitPerOrder,
            availableOfStock: newProduct.availableOfStock,
            inventory: newProduct.inventory,
            index: newProduct.index + 1,
            subItems: newProduct.subItems.length
              ? newProduct.subItems.map((subItem) => {
                  const { id, ...restSubItem } = subItem
                  const cleanedSubItem = { ...restSubItem }

                  if (cleanedSubItem.list) {
                    cleanedSubItem.list = cleanedSubItem.list.map((option) => {
                      const { id: _id, ...restOption } = option
                      return restOption
                    })
                  }
                  return cleanedSubItem
                })
              : [],
          },
        ],
      },
    ],
  }

  try {
    const res = await productApi.createShopProduct(shopDetail.id, convertData)

    if (res) {
      const copiedProduct = {
        ...res.msgResp[0].items[0],
        isCopied: true,
      }

      newListProduct.unshift(copiedProduct)

      dispatch({
        type: COPY_PRODUCT,
        payload: { listProduct: newListProduct },
      })

      dispatch(selectShopAndSelectShopDetail({ ...shopDetail, products: newListProduct }))
    }
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const addProduct = (shopDetail, data) => async (dispatch, getState) => {
  const { product } = getState()
  const { listProduct } = product
  const newListProduct = [...listProduct]

  try {
    const { msgResp } = await productApi.createShopProduct(shopDetail.id, data)

    const newCreatedProduct = {
      ...msgResp[0].items[0],
      isCopied: true,
    }

    newListProduct.unshift(newCreatedProduct)

    dispatch({
      type: ADD_PRODUCT_SUCCESS,
      payload: { listProduct: newListProduct },
    })

    dispatch(selectShopAndSelectShopDetail({ ...shopDetail, products: newListProduct }))
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const swapUpProduct = (_product) => (dispatch, getState) => {
  const { product } = getState()
  const { listProduct, listProductLazada } = product
  const newListProduct = [...listProduct]
  const newListProductLazada = [...listProductLazada]

  // product lazada
  if (_product.item_id) {
    const indexProduct = newListProductLazada.findIndex((item) => item.id === _product.id)

    if (indexProduct === 0) return

    const productToMove = newListProductLazada[indexProduct]

    newListProductLazada.splice(indexProduct, 1)
    newListProductLazada.splice(indexProduct - 1, 0, productToMove)

    dispatch({
      type: SWAP_UP_PRODUCT,
      payload: { listProductLazada: newListProductLazada },
    })

    return
  }

  const indexProduct = newListProduct.findIndex((p) => p.id === _product.id)

  if (indexProduct === 0) return

  const productToMove = newListProduct[indexProduct]

  newListProduct.splice(indexProduct, 1)
  newListProduct.splice(indexProduct - 1, 0, productToMove)

  dispatch({
    type: SWAP_UP_PRODUCT,
    payload: { listProduct: newListProduct },
  })
}

export const swapDownProduct = (_product) => (dispatch, getState) => {
  const { product } = getState()
  const { listProduct, listProductLazada } = product
  const newListProduct = [...listProduct]
  const newListProductLazada = [...listProductLazada]

  // product lazada
  if (_product.item_id) {
    const indexProduct = newListProductLazada.findIndex((item) => item.id === _product.id)

    if (indexProduct === newListProductLazada.length - 1) return

    const productToMove = { ...newListProductLazada[indexProduct] }

    newListProductLazada.splice(indexProduct, 1)
    newListProductLazada.splice(indexProduct + 1, 0, productToMove)

    dispatch({
      type: SWAP_DOWN_PRODUCT,
      payload: { listProductLazada: newListProductLazada },
    })

    return
  }

  const indexProduct = newListProduct.findIndex((item) => item.id === _product.id)

  if (indexProduct === newListProduct.length - 1) return

  const productToMove = { ...newListProduct[indexProduct] }

  newListProduct.splice(indexProduct, 1)
  newListProduct.splice(indexProduct + 1, 0, productToMove)

  dispatch({
    type: SWAP_DOWN_PRODUCT,
    payload: { listProduct: newListProduct },
  })
}

export const exportProductToLazada = (_product) => (dispatch, getState) => {
  try {
    const { product } = getState()
    const { listProductLazada, listProduct } = product
    const newListProductLazada = [...listProductLazada]
    const newListProduct = [...listProduct]
    const findIndex = newListProduct.findIndex((item) => item.id === _product.id)

    if (_product.lazadaProductId) throw new Error('Product already in list')

    const newProduct = {
      ..._product,
      variations: _product.subItems || [],
      isPendingToPush: true,
      isOpenModalEdit: true,
      availableOfStock: 0,
      purchaseCount: 0,
      quantity: _product.subItems.length > 0 ? 0 : 1000,
      description: 'Example Description',
      lazadaProductId: _product.id,
    }

    const { subItems, ...restProduct } = newProduct

    newListProductLazada.unshift(restProduct)

    if (findIndex !== -1) {
      newListProduct[findIndex].lazadaProductId = _product.id
    }

    dispatch({
      type: EXPORT_PRODUCT_TO_LAZADA,
      payload: {
        listProductLazada: newListProductLazada,
        listProduct: newListProduct,
      },
    })
  } catch (error) {
    throw new Error(error.message)
  }
}

export const pushToLazada = (data) => async (dispatch, getState) => {
  try {
    const { product, shop } = getState()
    const { selectedShop } = shop
    const { listProductLazada, listProduct } = product
    const newListProductLazada = [...listProductLazada]
    const newListProduct = [...listProduct]

    if (data.variations?.length && !data.listVariationProduct) {
      throw new Error('Please generate to list variant or remove variant')
    }

    const { msgResp } = await lazadaApi.createProductLazada(data)

    const convertedMsgResp = { ...msgResp, availableOfStock: msgResp.quantity }

    newListProductLazada.unshift(convertedMsgResp)

    const newList = newListProductLazada
      .filter((item) => item.item_id)
      .map((item) => ({
        ...item,
        isPendingToPush: false,
        isOpenModalEdit: false,
      }))

    const updateListProduct = newListProduct.map((item) => {
      if (item.name === msgResp.name) {
        return {
          ...item,
          lazadaProductId: msgResp.id,
          item_id: msgResp.item_id,
        }
      }

      return item
    })

    dispatch(selectShopAndSelectShopDetail({ ...selectedShop, products: updateListProduct }))

    dispatch({
      type: PUSH_TO_LAZADA,
      payload: { listProductLazada: newList, listProduct: updateListProduct },
    })

    return msgResp
  } catch (error) {
    throw new Error(error?.msgResp?.message || error?.msgResp || error.message)
  }
}

export const updateListProductLazada = (newList) => (dispatch) => {
  dispatch({
    type: UPDATE_LIST_PRODUCT_LAZADA,
    payload: { listProductLazada: newList },
  })
}

export const deleteProductLazada = (shop, lazProductId) => async (dispatch, getState) => {
  const { product } = getState()
  const { listProductLazada, listProduct } = product
  const newListProductLazada = [...listProductLazada]
  const newListProduct = [...listProduct]

  const indexProduct = newListProduct.findIndex((item) => item.lazadaProductId === lazProductId)
  const indexProductLazada = newListProductLazada.findIndex((item) => item.id === lazProductId)

  const updateProductLists = () => {
    if (newListProduct[indexProduct]?.lazadaProductId) {
      newListProduct[indexProduct].lazadaProductId = null
    }
    if (newListProduct[indexProduct]?.item_id) {
      newListProduct[indexProduct].item_id = null
    }

    dispatch({
      type: DELETE_PRODUCT_LAZADA,
      payload: {
        listProductLazada: newListProductLazada.filter((item) => item.id !== lazProductId),
        listProduct: newListProduct,
      },
    })
    dispatch(selectShopAndSelectShopDetail({ ...shop, products: newListProduct }))
  }

  if (indexProductLazada !== -1) {
    if (newListProductLazada[indexProductLazada].isPendingToPush) {
      updateProductLists()
    } else {
      try {
        await lazadaApi.removeProduct(shop?.id, lazProductId)
        updateProductLists()
      } catch (e) {
        throw new Error(e.message || e.msgResp)
      }
    }
  }
}

export const onUserImageUploaded =
  (event, id, setValue, tab, shopId) => async (dispatch, getState) => {
    const { product } = getState()
    const { listProduct, listProductLazada } = product
    const newListProduct = [...listProduct]
    const newListProductLazada = [...listProductLazada]
    const findIndex = newListProduct.findIndex((item) => item.id === id)
    const findIndexLazada = newListProductLazada.findIndex((item) => item.id === id)
    const { files } = event.target
    try {
      if (tab === PLATFORM_CO_KII) {
        const { msgResp } = await uploadApi.uploadPhoto(files)

        if (newListProduct?.length && findIndex !== -1) {
          newListProduct[findIndex].imgFileUrl = null
          newListProduct[findIndex].photoUrls = msgResp.url
        }

        setValue('photoUrls', msgResp.url)
      } else {
        const { msgResp } = await uploadApi.uploadPhotoLazada(shopId, files)
        if (listProductLazada?.length && findIndexLazada !== -1) {
          newListProductLazada[findIndexLazada].imgFileUrl = null
          newListProductLazada[findIndexLazada].photoUrls = msgResp.url
        }

        setValue('photoUrls', msgResp.url)
      }

      dispatch({
        type: UPDATE_PRODUCT,
        payload: {
          listProduct: newListProduct,
          listProductLazada: newListProductLazada,
        },
      })
    } catch (error) {
      throw new Error(error?.msgResp || error.message)
    }
  }

export const setOpenSubItem = (value, pId, pIndex) => (dispatch, getState) => {
  const { product } = getState()
  const { listProduct } = product
  const newListProduct = [...listProduct]
  const index = newListProduct.findIndex((item) => item.id === pId && item.index === pIndex)

  newListProduct[index].isOpenSubItems = value

  dispatch({
    type: TOGGLE_SUB_ITEM,
    payload: {
      listProduct: newListProduct,
    },
  })
}

export const toggleSubItem = (pId, pIndex) => (dispatch, getState) => {
  const { product } = getState()
  const { listProduct } = product
  const newListProduct = listProduct.map((item) => {
    if (item.id === pId && item.index === pIndex) {
      return {
        ...item,
        isOpenSubItems: !item.isOpenSubItems,
      }
    }
    return item
  })

  dispatch({
    type: TOGGLE_SUB_ITEM,
    payload: {
      listProduct: newListProduct,
    },
  })
}

export const setOpenVariationsForProduct = (checked, pid) => (dispatch, getState) => {
  const { product } = getState()
  const { listProductLazada } = product

  const newListProductLazada = [...listProductLazada]

  const updatedList = newListProductLazada.map((item) => {
    if (item.id === pid) {
      return {
        ...item,
        isOpenVariations: checked,
      }
    }

    return item
  })

  dispatch({
    type: TOGGLE_VARIATIONS,
    payload: { listProductLazada: updatedList },
  })
}

export const getLazadaCategorySuggestions = (shopId, pName) => async (dispatch) => {
  try {
    const postData = {
      shopId,
      productName: pName,
    }

    const { msgResp } = await lazadaApi.getLazadaCategorySuggestions(postData)

    dispatch({
      type: GET_LAZADA_CATEGORY_SUGGESTIONS,
      payload: { listLazadaCategorySuggestions: msgResp },
    })
  } catch (error) {
    const errorMessage = error?.msgResp || error.message || 'Something went wrong'
    dispatch({
      type: GET_LAZADA_CATEGORY_SUGGESTIONS,
      payload: { errorMessageCategory: errorMessage },
    })
  }
}

export const clearListCategorySuggestions = () => (dispatch) => {
  dispatch({
    type: CLEAR_LAZADA_CATEGORY_SUGGESTIONS,
    payload: { listLazadaCategorySuggestions: [] },
  })
}

export const getBrandByPages = (data) => async (dispatch) => {
  try {
    const { msgResp } = await lazadaApi.getBrandByPages(data)
    dispatch({
      type: GET_BRAND_BY_PAGES,
      payload: {
        brandByPages: msgResp?.module,
      },
    })
  } catch (error) {
    throw new Error(error?.msgResp || error.message)
  }
}

export const updateNewListVariantWithActionRemove =
  (prodSelected, newListVariation) => (dispatch, getState) => {
    const { product } = getState()
    const { listProductLazada } = product

    const updatedList = listProductLazada.map((item) => {
      if (item.id === prodSelected.id) {
        return {
          ...item,
          listVariationProduct: newListVariation,
        }
      }

      return item
    })

    dispatch({
      type: UPDATE_NEW_LIST_VARIATION_WITH_ACTION_REMOVE,
      payload: {
        listProductLazada: updatedList,
      },
    })
  }

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NEW_LIST_VARIATION_WITH_ACTION_REMOVE:
    case UPDATE_LIST_PRODUCT_LAZADA:
    case UPDATE_NEW_LIST_PRODUCT_LAZADA:
    case HANDLE_UPDATE_VARIATION:
    case GET_BRAND_BY_PAGES:
    case FETCH_LIST_VARIATION_PRODUCT:
    case PUSH_TO_LAZADA:
    case HANDLE_ADD_VARIATION:
    case UPDATE_SINGLE_PRODUCT_LAZADA:
    case SELECT_PRODUCT_TO_PUSH_LAZADA:
    case FETCH_LIST_PRODUCT:
    case FETCH_LIST_PRODUCT_LAZADA:
    case UPDATE_EFFECT:
    case UPDATE_NEW_LIST_PRODUCT:
    case TOGGLE_VARIATIONS:
    case EXPORT_PRODUCT_TO_LAZADA:
    case CLEAR_SELECT_PRODUCT_LAZADA:
    case CLEAR_LAZADA_CATEGORY_SUGGESTIONS:
    case GET_LAZADA_CATEGORY_SUGGESTIONS:
    case TOGGLE_ACTIVE_PRODUCT:
    case UPLOAD_IMAGE_PRODUCT:
    case TOGGLE_SUB_ITEM:
    case SELECT_TAB:
    case DELETE_PRODUCT_LAZADA:
    case SELECT_PRODUCT_LAZADA:
    case UPLOAD_IMAGE_PRODUCT_LAZADA:
    case PUSH_TO_LAZADA_SUCCESS:
    case PUSH_TO_LAZADA_FAILED:
    case ADD_PRODUCT_SUCCESS:
    case COPY_PRODUCT:
    case SWAP_UP_PRODUCT:
    case SWAP_DOWN_PRODUCT:
    case DELETE_PRODUCT_SUCCESS:
    case UPDATE_PRODUCT:
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default productReducer
