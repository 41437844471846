import React from 'react'
import { Link } from 'react-router-dom'

const styleTitleOverlay = { marginTop: 100, color: '#5b5b5b', fontWeight: 500, fontSize: 24 }

export default function OverlayPrompt({ shopSlug }) {
  return (
    <div className='position-absolute top-0 start-0 bottom-0 end-0' style={{ zIndex: 50 }}>
      <div className='position-absolute bg-white w-100 h-100' style={{ zIndex: 0.9 }}></div>
      <div className='position-absolute text-center w-100 h-100' style={{ zIndex: 30 }}>
        <p style={{ ...styleTitleOverlay, color: 'red' }}>You are using free plan</p>
        <small>
          Upgrade to premium plan to unlock more features and get more benefits for your shop.
          <Link
            to={`/admin-console/${shopSlug}/subscription`}
            className='alert-link text-orange text-decoration-underline ms-1'
          >
            Subscribe now
          </Link>
        </small>
      </div>
    </div>
  )
}
