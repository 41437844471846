import deliveryEventApi from '_api/deliveryEvent'
import orderEventApi from '_api/orderEvent'
import {
  clearListOrderDeliveryEventById,
  getListDeliveryAndDeliveryBooking,
  getListDeliveryEventByHost,
  updateNewListOrderDeliveryEventById,
  updateStatusDeliveryBooking,
} from '_redux/modules/deliveryEvent'
import {
  clearListDeliveryByHost,
  clearListPickupByHost,
  getListDeliveryByHost,
  getListPickupByHost,
} from '_redux/modules/fulfillment'
import {
  DELIVERY_BUYER_CANCELLED,
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_CANCEL_STATUS,
  DELIVERY_DELIVERED_STATUS,
  DELIVERY_EVENT_HOST_UNPAID,
  DELIVERY_PENDING_STATUS,
  DELIVERY_UNPAID_STATUS,
} from '_utils/constant'
import { addParamUrl, clearParamUrl, exportToCsv, normalizeName } from '_utils/function'
import { convertTimeStringWithDuration } from '_utils/functions/converter'
import { handleDataOrderEvent } from '_utils/functions/handler'
import { getUserInfo } from '_utils/localData'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import useQuery from './useQuery'

const groupDeliveryEvents = (deliveryEvents, groupSize) => {
  const groups = []
  for (let i = 0; i < deliveryEvents.length; i += groupSize) {
    groups.push(deliveryEvents.slice(i, i + groupSize))
  }
  return groups
}

export default function useDelivery() {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const query = useQuery()
  const history = useHistory()
  const location = useLocation()
  const userInfo = getUserInfo()
  const deliveryIdParam = query.get('deliveryId')

  const { selectedShop } = useSelector((state) => state.shop)
  const { eventAndOrderById, listCombinedPayments } = useSelector((state) => state.orderEvent)
  const { listDeliveryByHost, listPickupByHost } = useSelector((state) => state.fulfillment)
  const { listDeliveryEventByHost, currentDeliveryAndDeliveryBooking } = useSelector(
    (state) => state.deliveryEvent
  )

  const { shopEventDeliveryBookings = [] } = currentDeliveryAndDeliveryBooking

  const [dataModal, setDataModal] = useState({})
  const [modalPaymentScreen, setModalPaymentScreen] = useState(false)
  const [tabOrderDeliveryEvent, setTabOrderDeliveryEvent] = useState(null)
  const [selectedDeliveryEventName, setSelectedDeliveryEventName] = useState('Delivery Event')
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [listDeliveryAndPickupShop, setListDeliveryAndPickupShop] = useState([])
  const [checkedIncludePickup, setCheckedIncludePickup] = useState(false)
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: moment().startOf('date').toDate(),
      endDate: moment().add(30, 'days').endOf('date').toDate(),
      key: 'selection',
    },
  ])

  const startDate = moment(rangeDate[0].startDate).unix()
  const endDate = moment(rangeDate[0].endDate).unix()
  const groupedDeliveryEvents = groupDeliveryEvents(listDeliveryEventByHost, 5)

  useEffect(() => {
    if (selectedShop?.id) {
      const postData = {
        startDate,
        endDate,
        shopId: selectedShop.id,
      }

      const fetchData = async () => {
        try {
          await Promise.all([
            dispatch(getListDeliveryByHost(postData)),
            dispatch(
              getListDeliveryEventByHost({
                hostId: userInfo.id,
              })
            ),
          ])
        } catch (error) {
          addToast(error.message || error.msgResp, {
            appearance: 'error',
            autoDismiss: true,
          })
        } finally {
          setLoading(false)
        }
      }

      fetchData()
    }

    return () => {
      setLoading(true)
      dispatch(clearListDeliveryByHost())
    }
  }, [selectedShop?.id])

  useEffect(() => {
    if (checkedIncludePickup) {
      const fetchData = async () => {
        try {
          setLoading(true)
          const postData = {
            startDate,
            endDate,
            shopId: selectedShop?.id,
          }
          await dispatch(getListPickupByHost(postData))
        } catch (error) {
          addToast(error.message || error.msgResp, {
            appearance: 'error',
            autoDismiss: true,
          })
        } finally {
          setLoading(false)
        }
      }
      fetchData()
    } else {
      dispatch(clearListPickupByHost())
    }
  }, [checkedIncludePickup, startDate, endDate])

  useEffect(() => {
    if (selectedShop?.id) {
      const newListFulfillment = [...listDeliveryByHost, ...listPickupByHost]
        .sort((a, b) => b.createdAt - a.createdAt)
        .filter((item) => item.shopId === selectedShop?.id)
      setListDeliveryAndPickupShop(newListFulfillment)
    }
  }, [listDeliveryByHost.length, listPickupByHost.length, selectedShop?.id])

  useEffect(() => {
    if (query.get('date')) {
      const date = query.get('date')
      setRangeDate([
        {
          startDate: moment(date, 'DD-MM-YYYY').startOf('day').toDate(),
          endDate: moment(date, 'DD-MM-YYYY').endOf('day').toDate(),
          key: 'selection',
        },
      ])
    }
  }, [query.get('date')])

  useEffect(() => {
    if (deliveryIdParam) {
      setTabOrderDeliveryEvent(deliveryIdParam)

      if (listDeliveryEventByHost.length) {
        const data = listDeliveryEventByHost.find((item) => item.id === deliveryIdParam)

        setSelectedDeliveryEventName(
          `Delivery for (${convertTimeStringWithDuration(
            data.deliveryTime,
            data.deliveryDuration
          )})`
        )

        const searchParams = addParamUrl({
          startDate,
          endDate,
        })
        history.replace(`${location.pathname}?${searchParams}`)

        dispatch(getListDeliveryAndDeliveryBooking(deliveryIdParam))
      }
      setCurrentPage(1)
    } else {
      dispatch(clearListOrderDeliveryEventById())
    }
  }, [deliveryIdParam, JSON.stringify(listDeliveryEventByHost), startDate, endDate])

  const handleSelectDeliveryEvent = (deliveryEventId, deliveryEventName) => {
    setTabOrderDeliveryEvent(deliveryEventId)
    setSelectedDeliveryEventName(deliveryEventName)
    history.push(`?deliveryId=${deliveryEventId}`)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleSearch = (value) => {
    setSearch(value)
  }

  const getRangeFulfillmentDate = (dates) => {
    setRangeDate(dates)
  }

  const handleClear = () => {
    setRangeDate([
      {
        startDate: moment().startOf('date').toDate(),
        endDate: moment().add(30, 'days').endOf('date').toDate(),
        key: 'selection',
      },
    ])
    setSearch('')
    setTabOrderDeliveryEvent(null)
    setSelectedDeliveryEventName('Delivery Event')
    setCurrentPage(1)
    setCheckedIncludePickup(false)
    dispatch(clearListOrderDeliveryEventById())

    if (!deliveryIdParam) {
      clearParamUrl({ history, location }, ['date'])
    } else {
      clearParamUrl({ history, location })
    }
  }

  const handleExport = (data) => {
    if (data.length === 0) {
      addToast('No delivery to export', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    const rowsOfData = new Array(data.length) || []

    for (let index = 0; index < data.length; index++) {
      const delivery = data[index]

      let statusString = ''
      let bookerStatusString = ''

      const {
        status,
        bookerStatus,
        uName,
        uPhone,
        uEmail,
        orderRefs,
        deliveryFee,
        deliveryOptionName,
        uAddress,
        giftRecipientInfo,
        ref,
        address,
        dzPrice,
        dzName,
      } = delivery

      switch (status) {
        case DELIVERY_CANCEL_STATUS:
          statusString = 'Cancelled'
          break
        case DELIVERY_UNPAID_STATUS:
          statusString = 'Unpaid'
          break
        case DELIVERY_PENDING_STATUS:
          statusString = 'Pending'
          break
        case DELIVERY_DELIVERED_STATUS:
          statusString = 'Delivered'
          break
        default:
          break
      }

      switch (bookerStatus) {
        case DELIVERY_BUYER_CANCELLED:
          bookerStatusString = 'Cancelled'
          break
        case DELIVERY_BUYER_REFUND:
          bookerStatusString = 'Refund'
          break
        case DELIVERY_BUYER_UNPAID:
          bookerStatusString = 'Unpaid'
          break
        case DELIVERY_BUYER_PAID:
          bookerStatusString = 'Paid'
          break
        default:
          break
      }

      const deliveryFeeValue = deliveryFee ? `$${deliveryFee}` : '$0'

      rowsOfData[index] = [
        index,
        !deliveryIdParam ? orderRefs : ref,
        !deliveryIdParam ? deliveryOptionName : dzName,
        !deliveryIdParam ? deliveryFeeValue : dzPrice,
        giftRecipientInfo?.address || delivery?.pickupAddress || uAddress || address,
        giftRecipientInfo?.name || uName,
        giftRecipientInfo?.phone || uPhone,
        giftRecipientInfo?.email || uEmail,
        statusString,
        bookerStatusString,
      ]
    }

    const headerRow = [
      'Fulfillment no',
      'Ref',
      'Zone name',
      'Zone price',
      'Address',
      'Buyer name',
      'Buyer phone',
      'Buyer email',
      'Status',
      'Booker status',
    ]

    const rows = [headerRow, ...rowsOfData]

    const fileName = normalizeName(
      `${selectedShop?.shopName} shop export list fulfillment by ${moment().format('DD-MM-YYYY')}`
    )
    exportToCsv(`${fileName}.csv`, rows)
  }

  const filteredOrdersFulfillment = useMemo(() => {
    const PAGE_SIZE = 5
    let filteredList = []

    if (deliveryIdParam) {
      filteredList = shopEventDeliveryBookings
    } else {
      filteredList = listDeliveryAndPickupShop
    }

    if (search.length > 0) {
      filteredList = filteredList.filter((item) =>
        Object.values(item)?.some((value) =>
          String(value)?.toLowerCase()?.includes(search.toLowerCase())
        )
      )
    }

    if (rangeDate.length) {
      filteredList = filteredList.filter((item) => {
        const { deliveryTimestamp, pickupBookingTimestamp, createdAt } = item
        const deliveryTime = moment.unix(deliveryTimestamp)
        const pickupTime = moment.unix(pickupBookingTimestamp)
        const deliveryTimeDz = moment.unix(createdAt)

        return (
          deliveryTime.isBetween(rangeDate[0].startDate, rangeDate[0].endDate) ||
          pickupTime.isBetween(rangeDate[0].startDate, rangeDate[0].endDate) ||
          deliveryTimeDz.isBetween(rangeDate[0].startDate, rangeDate[0].endDate)
        )
      })
    }

    const firstPageIndex = (currentPage - 1) * PAGE_SIZE
    const lastPageIndex = firstPageIndex + PAGE_SIZE
    return filteredList.slice(firstPageIndex, lastPageIndex)
  }, [
    JSON.stringify(listDeliveryAndPickupShop),
    JSON.stringify(shopEventDeliveryBookings),
    JSON.stringify(rangeDate),
    search,
    currentPage,
    checkedIncludePickup,
    deliveryIdParam,
  ])

  const togglePaymentScreen = () => setModalPaymentScreen(!modalPaymentScreen)

  const updateStatusFulfillment = (ref, status, bookerStatus, isDeliveryZones) => {
    if (isDeliveryZones) {
      dispatch(updateStatusDeliveryBooking(ref, { status, bookerStatus }))
    } else {
      setListDeliveryAndPickupShop((prevList) => {
        const newListFulfillment = [...prevList]
        const findIndex = newListFulfillment.findIndex((item) => item.ref === ref)
        if (findIndex !== -1) {
          newListFulfillment[findIndex].status = status
          newListFulfillment[findIndex].bookerStatus = bookerStatus
        }
        return newListFulfillment
      })
    }
  }

  const swapUpFulfillment = (delivery) => {
    const newListFulfillment = [...filteredOrdersFulfillment]
    const index = newListFulfillment.findIndex((item) => item.id === delivery.id)
    if (index > 0) {
      newListFulfillment.splice(index, 1)
      newListFulfillment.splice(index - 1, 0, delivery)

      dispatch(updateNewListOrderDeliveryEventById(newListFulfillment))
      setListDeliveryAndPickupShop(newListFulfillment)
    }
  }

  const swapDownFulfillment = (delivery) => {
    const newListFulfillment = [...filteredOrdersFulfillment]
    const index = newListFulfillment.findIndex((item) => item.id === delivery.id)
    if (index < newListFulfillment.length - 1) {
      newListFulfillment.splice(index, 1)
      newListFulfillment.splice(index + 1, 0, delivery)

      dispatch(updateNewListOrderDeliveryEventById(newListFulfillment))
      setListDeliveryAndPickupShop(newListFulfillment)
    }
  }

  const openPaymentScreenDeliveryEvent = (e, delivery) => {
    e.preventDefault()

    const { combinedPaymentRef } = delivery
    let listCombinedOrder = []
    let listCombinedDelivery = []

    if (combinedPaymentRef) {
      const exitCombinedPayment = listCombinedPayments?.findIndex(
        (item) => item.combinedPaymentRef === combinedPaymentRef
      )

      if (exitCombinedPayment !== -1) {
        const { orderCombinedPayment, deliveryCombinedPayment } =
          listCombinedPayments[exitCombinedPayment]

        orderCombinedPayment.forEach((element) => {
          const { eid, ref: elementRef } = element
          const { orders, adminCost, discount, deliveryCost, productIdList } =
            eventAndOrderById.find((item) => item.id === eid) || {}
          const { listOrderEventHandled: otherListOrder } = handleDataOrderEvent(
            orders,
            adminCost,
            discount,
            deliveryCost,
            productIdList
          )

          const otherOrder = otherListOrder.find((item) => item.ref === elementRef)
          listCombinedOrder = [...listCombinedOrder, otherOrder]
        })
        listCombinedDelivery = [...deliveryCombinedPayment]
      }
    }

    setDataModal({ ...delivery, listCombinedOrder, listCombinedDelivery })
    togglePaymentScreen()
  }

  const handleInvalidPaymentDeliveryEvent = async (_ref, _status, _combinedPaymentRef) => {
    try {
      const r = confirm('Are you sure this is invalid payment?')
      if (r === true) {
        if (_combinedPaymentRef) {
          const _data = {
            combinedPaymentRef: _combinedPaymentRef,
            status: DELIVERY_EVENT_HOST_UNPAID,
            placerStatus: DELIVERY_BUYER_UNPAID,
          }
          dispatch(
            updateStatusDeliveryBooking(_data.combinedPaymentRef, {
              status: DELIVERY_EVENT_HOST_UNPAID,
              bookerStatus: DELIVERY_BUYER_UNPAID,
            })
          )
          await orderEventApi.updateInvoicesStatus(_data)
        } else {
          const _data = { status: _status, bookerStatus: DELIVERY_BUYER_UNPAID }
          dispatch(updateStatusDeliveryBooking(_ref, _data))
          await deliveryEventApi.updateDeliveryBookerStatus(_ref, _data)
        }
        togglePaymentScreen()
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
      togglePaymentScreen()
    }
  }

  return {
    filteredOrdersFulfillment,
    handleSelectDeliveryEvent,
    toggleModal,
    handleSearch,
    getRangeFulfillmentDate,
    handleClear,
    handleExport,
    updateStatusFulfillment,
    swapUpFulfillment,
    swapDownFulfillment,
    loading,
    isModalOpen,
    selectedDeliveryEventName,
    tabOrderDeliveryEvent,
    setTabOrderDeliveryEvent,
    setSelectedDeliveryEventName,
    setIsModalOpen,
    setLoading,
    setCurrentPage,
    setSearch,
    setRangeDate,
    setListDeliveryAndPickupShop,
    setCheckedIncludePickup,
    groupedDeliveryEvents,
    currentPage,
    search,
    rangeDate,
    listDeliveryAndPickupShop,
    checkedIncludePickup,
    deliveryIdParam,
    dataModal,
    modalPaymentScreen,
    togglePaymentScreen,
    openPaymentScreenDeliveryEvent,
    handleInvalidPaymentDeliveryEvent,
  }
}
