import LoadingPopup from '_components/LoadingPopup'
import ModalGeneralPayment from '_components/ModalGeneralPayment/index'
import { fetchListPackageByType, selectPackage } from '_redux/modules/package'
import { selectTab } from '_redux/modules/product'
import {
  PACKAGE_LAZADA,
  PLATFORM_CO_KII,
  PLATFORM_LAZADA,
  SUBSCRIPTION_STATUS_APPROVED,
} from '_utils/constant'
import { exportToCsv, flexibleSearch, normalizeName } from '_utils/function'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateNewListProduct } from '../../redux/modules/product'
import { getShopDetailByHost } from '../../redux/modules/shop'
import RenderButtonLazada from './components/Common/RenderButtonLazada'
import RenderListProduct from './components/Common/RenderListProduct'
import SearchProduct from './components/Common/SearchProduct'
import TabsECommerce from './components/Common/TabsECommerce'
import './style.scss'

function ManageProducts() {
  const dispatch = useDispatch()

  const [nextStep, setNextStep] = useState(1)
  const [searchString, setSearchString] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)

  // State For Lazada
  const [isPushingLaz, setIsPushingLaz] = useState(false)
  const [isRemovingLaz, setIsRemovingLaz] = useState(false)
  const [isUpdatingVariationLaz, setIsUpdatingVariationLaz] = useState(false)

  const { selectedShop } = useSelector((state) => state.shop)
  const { listProduct } = useSelector((state) => state.product)
  const { listPackageLazada } = useSelector((state) => state.package)
  const { listInvoiceLazada } = useSelector((state) => state.lazada)

  const isHasLazada =
    listInvoiceLazada?.find((item) => item.shopId === selectedShop?.id)?.status ===
      SUBSCRIPTION_STATUS_APPROVED || false

  const selectedTabProduct = localStorage.getItem('selectedTabProduct')

  // to handle upgrade Lazada features
  useEffect(() => {
    dispatch(fetchListPackageByType(PACKAGE_LAZADA))

    if (selectedShop?.id && selectedShop?.id !== '') {
      dispatch(getShopDetailByHost(selectedShop.id))
    }
  }, [])

  useEffect(() => {
    if (selectedTabProduct) {
      if (Number(selectedTabProduct) === PLATFORM_CO_KII) {
        dispatch(selectTab(PLATFORM_CO_KII))
      }

      if (Number(selectedTabProduct) === PLATFORM_LAZADA && isHasLazada) {
        dispatch(selectTab(PLATFORM_LAZADA))
      }
    } else {
      dispatch(selectTab(PLATFORM_CO_KII))
    }

    setSearchString('')
  }, [selectedTabProduct, isHasLazada])

  // this is effect to update new list product when selected shop change any product
  // useEffect(() => {
  //   if (selectedShop?.id && selectedShop?.id !== '') {
  //     dispatch(updateNewListProduct(selectedShop.products))
  //   }
  // }, [JSON.stringify(selectedShop)])

  const toggleModal = () => setIsOpenModal(!isOpenModal)

  const handleSearchProduct = (value) => {
    setSearchString(value)
  }

  const handleExport = (shopDetail, _listProduct) => {
    const generalInfo = [
      ['Shop Name', shopDetail?.shopName],
      ['Shop Link', shopDetail?.shopLink],
      ['Headline', shopDetail?.headline],
      ['Shop Owner', shopDetail?.createdUserName],
      ['Contact', shopDetail?.createdUserPhone],
      [''],
      [''],
    ]
    const rowsOfProduct = new Array(_listProduct.length) || []
    for (let index = 0; index < _listProduct.length; index++) {
      const product = _listProduct[index]
      const { name, price, availableOfStock, subItems } = product

      const subItemsString = subItems
        .map((subItem) => subItem.list.map((item) => `${item.name} ($${item.price})`).join(', '))
        .join(', ')

      rowsOfProduct[index] = [index + 1, name, `$${price}`, availableOfStock, subItemsString]
    }
    const rows = [
      ...generalInfo,
      ['Product No', 'Product name', 'Price', 'Available Stock', 'Sub Items & Price'],
      ...rowsOfProduct,
    ]
    const fileName = normalizeName(
      `${shopDetail?.shopName} shop export Product by ${moment().format('DD-MM-YYYY')}`
    )
    exportToCsv(`${fileName}.csv`, rows)
  }

  const onUpgradeLazada = () => {
    if (listPackageLazada.length) {
      dispatch(selectPackage(listPackageLazada[0]))
    }

    toggleModal()
  }

  const listProductFilter = useMemo(() => {
    if (searchString) {
      const newList = listProduct?.filter((product) => flexibleSearch(product.name, searchString))
      return newList
    }
    return listProduct
  }, [searchString, JSON.stringify(listProduct)])

  useEffect(() => {
    if (listProductFilter) {
      const someIsCopied = listProductFilter.some((product) => product.isCopied)

      if (someIsCopied) {
        setTimeout(() => {
          dispatch(
            updateNewListProduct(
              listProductFilter.map((product) => ({ ...product, isCopied: false }))
            )
          )
        }, 1000)
      }
    }
  }, [JSON.stringify(listProductFilter)])

  const isLoadingLaz = isPushingLaz || isRemovingLaz || isUpdatingVariationLaz

  return (
    <div className='manage-products px-3'>
      {isLoadingLaz && <LoadingPopup />}
      {/* <RenderButtonLazada onUpgradeLazada={onUpgradeLazada} /> */}
      <h3 className='title mb-3'>Products</h3>
      <div className='w-50'>
        <SearchProduct
          searchString={searchString}
          onSearch={handleSearchProduct}
          placeholder='Search name of product'
        />
      </div>

      <div className='d-flex align-items-center justify-content-between mb-3'>
        <TabsECommerce />

        <div className='d-flex align-items-center'>
          <RenderButtonLazada onUpgradeLazada={onUpgradeLazada} />

          <button
            onClick={() => handleExport(selectedShop, listProduct)}
            className='bg-transparent text-uppercase border-0
     ms-3 mt-0 shadow-none w-auto rounded btn btn-export'
          >
            Export
          </button>
        </div>
      </div>

      <RenderListProduct
        listProductFilter={listProductFilter}
        isPushingLaz={isPushingLaz}
        setIsPushingLaz={setIsPushingLaz}
        isRemovingLaz={isRemovingLaz}
        setIsRemovingLaz={setIsRemovingLaz}
        isUpdatingVariationLaz={isUpdatingVariationLaz}
        setIsUpdatingVariationLaz={setIsUpdatingVariationLaz}
        searchString={searchString}
      />

      {isOpenModal && (
        <ModalGeneralPayment
          isOpenModal={isOpenModal}
          toggleModal={toggleModal}
          nextStep={nextStep}
          setNextStep={setNextStep}
        />
      )}
    </div>
  )
}

export default ManageProducts
