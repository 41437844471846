/* eslint-disable no-confusing-arrow */
import eventApi from '_api/event'
import { setEventsByShop } from '_utils/localData'

const DELETE_LIST_SCHEDULED_EVENTS = 'DELETE_LIST_SCHEDULED_EVENTS'
const CLEAR_MY_EVENTS = 'CLEAR_MY_EVENTS'
const CLEAR_ALL_EVENTS = 'CLEAR_ALL_EVENTS'
const CLEAR_PAST_PURCHASED_MEMBER = 'CLEAR_PAST_PURCHASED_MEMBER'
const UPDATE_EVENT_IN_SHOP = 'UPDATE_EVENT_IN_SHOP'
const GET_EVENT_IN_SHOP = 'GET_EVENT_IN_SHOP'
const UPDATE_EVENTS_BY_SHOP_FILTERED = 'UPDATE_EVENTS_BY_SHOP_FILTERED'
const APPEND_NEW_EVENT_TO_SHOP = 'APPEND_NEW_EVENT_TO_SHOP'
const DELETE_EVENT = 'DELETE_EVENT'
const DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED'

const initialState = {
  allScheduleEvent: [],
  myEvents: [],
  myOrders: [],
  allEvents: [],
  pastPurchasedMember: [],
  eventsByShop: {},
  eventsByShopFiltered: [],
}

export const deleteEvent = (eventId, shopId) => async (dispatch, getState) => {
  try {
    const { event } = getState()
    const { eventsByShop } = event

    const events = eventsByShop[shopId] || []

    await eventApi.deleteEvent(eventId)

    const newEvents = events.filter((eventItem) => eventItem.id !== eventId)

    const newListEventByShop = {
      ...eventsByShop,
      [shopId]: newEvents,
    }

    dispatch({
      type: DELETE_EVENT,
      payload: { eventsByShop: newListEventByShop, eventsByShopFiltered: newEvents },
    })

    setEventsByShop(newListEventByShop)
  } catch (error) {
    dispatch({
      type: DELETE_EVENT_FAILED,
      payload: { error },
    })
    throw new Error(error.message || error.msgResp)
  }
}

export const clearPastPurchasedMember = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_PAST_PURCHASED_MEMBER,
      payload: { pastPurchasedMember: [] },
    })
  } catch (e) {}
}

export const updateEventInShop = (data) => async (dispatch, getState) => {
  const { event, shop } = getState()
  const { myShops } = shop
  const { eventsByShop } = event
  const { shopId, id } = data

  const shopIndex = myShops.findIndex((item) => item.id === shopId)

  if (shopIndex !== -1) {
    const events = eventsByShop[shopId] || []

    const eventIndex = events.findIndex((eventItem) => eventItem.id === id)

    if (eventIndex !== -1) {
      const updatedEvents = events.map((eventItem) => {
        if (event.id !== id) return eventItem
        return { ...eventItem, ...data }
      })

      const newMyShops = myShops.map((shopItem, index) => {
        if (index !== shopIndex) return shopItem

        return {
          ...shopItem,
          events: updatedEvents,
        }
      })

      const newListEventByShop = {
        ...eventsByShop,
        [shopId]: updatedEvents,
      }

      dispatch({
        type: UPDATE_EVENT_IN_SHOP,
        payload: {
          myShops: newMyShops,
          eventsByShop: newListEventByShop,
          eventsByShopFiltered: updatedEvents,
        },
      })

      setEventsByShop(newListEventByShop)
    }
  }
}

export const appendNewEventToShop = (data) => async (dispatch, getState) => {
  const { shop, event } = getState()
  const { myShops } = shop
  const { eventsByShop } = event
  const { shopId } = data

  const index = myShops.findIndex((item) => item.id === shopId)
  if (index !== -1) {
    const newEvent = { ...data }

    const newMyShops = myShops.map((shopItem, shopIndex) => {
      if (shopIndex !== index) return shopItem

      return {
        ...shopItem,
        totalEvents: shopItem.totalEvents + 1,
        events: Array.isArray(shopItem.events) ? [newEvent, ...shopItem.events] : [newEvent],
      }
    })

    const newListEventByShop = {
      ...eventsByShop,
      [shopId]: Array.isArray(eventsByShop[shopId])
        ? [newEvent, ...eventsByShop[shopId]]
        : [newEvent],
    }

    dispatch({
      type: APPEND_NEW_EVENT_TO_SHOP,
      payload: { myShops: newMyShops, eventsByShop: newListEventByShop },
    })

    setEventsByShop(newListEventByShop)
  }
}

export const getEventInShop = (shopId) => async (dispatch, getState) => {
  const { event } = getState()
  const { eventsByShop } = event

  try {
    const { msgResp } = await eventApi.getListEventsByShopId(shopId)
    const updatedEventsByShop = {
      ...eventsByShop,
      [shopId]: msgResp,
    }

    dispatch({
      type: GET_EVENT_IN_SHOP,
      payload: { eventsByShop: updatedEventsByShop, eventsByShopFiltered: msgResp },
    })

    setEventsByShop(updatedEventsByShop)
  } catch (error) {
    dispatch({
      type: GET_EVENT_IN_SHOP,
      payload: { eventsByShop: {}, eventsByShopFiltered: [] },
    })
  }
}

export const filterEventStatus = (status, shopId) => async (dispatch, getState) => {
  const { event } = getState()
  const { eventsByShop } = event

  if (!eventsByShop[shopId]) {
    dispatch({
      type: UPDATE_EVENTS_BY_SHOP_FILTERED,
      payload: { eventsByShopFiltered: [] },
    })
    return
  }

  const shopEvents = eventsByShop[shopId]
  let filteredEvents

  if (status === 'all') {
    filteredEvents = shopEvents
  } else {
    const numericStatus = Number(status)
    filteredEvents = shopEvents.filter((eventItem) => eventItem.status === numericStatus)
  }

  dispatch({
    type: UPDATE_EVENTS_BY_SHOP_FILTERED,
    payload: { eventsByShopFiltered: filteredEvents },
  })
}

export const clearAllEvents = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ALL_EVENTS,
    payload: { allEvents: [] },
  })
}

export const clearMyEvents = () => async (dispatch) => {
  dispatch({
    type: CLEAR_MY_EVENTS,
    payload: { myEvents: [] },
  })
}

export const clearScheduledEvent = (dispatch) => {
  dispatch({
    type: DELETE_LIST_SCHEDULED_EVENTS,
    payload: { allScheduleEvent: [] },
  })
}

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_EVENT:
    case DELETE_EVENT_FAILED:
    case UPDATE_EVENT_IN_SHOP:
    case UPDATE_EVENTS_BY_SHOP_FILTERED:
    case GET_EVENT_IN_SHOP:
    case CLEAR_PAST_PURCHASED_MEMBER:
    case APPEND_NEW_EVENT_TO_SHOP:
    case CLEAR_ALL_EVENTS:
    case CLEAR_MY_EVENTS:
    case DELETE_LIST_SCHEDULED_EVENTS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default eventReducer
